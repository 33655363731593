import React, { useState, useEffect, useRef } from 'react';
import { checkIsOverflowed } from "../../hooks/useOverflowed";
import { i18n } from '../../i18n';
import useWindowDimensions from '../../hooks/useWindowDimension';
import IngredientListItem, { checkIsVisible } from './IngredientListItem';
const PRODUCT_INGREDIENT_TYPE = 'product';

const createCerealMixTitle = (ingredientParts) => {
    const result = [];
    ingredientParts.forEach(it => result.push(i18n.get(`product.item.code.${it.productCode}`)));
    return result.join(', ');
};

const createIngredientsList = (ingredients) => {
    const result = [];
    for (let ingredient of ingredients) {
        if(ingredient.ingredientType === PRODUCT_INGREDIENT_TYPE) {
            for (let part of ingredient.ingredientParts) {
                result.push({...part, ingredientType: ingredient.ingredientType, title: i18n.get(`product.item.code.${part.productCode}`)});
            }
        } else {
            result.push({...ingredient, ingredientType: ingredient.ingredientType, title: createCerealMixTitle(ingredient.ingredientParts)});
        }
    }
    return result;
};

const findOverflowedCount = (ingredientsList, parentContainer) => {
    return ingredientsList.length - parentContainer.children.length + 1;
};

const MealPlanItemIngredientsList = ({ meal }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [lastVisibleIndex, setLastVisibleIndex] = useState(1);
    const ref = useRef();
    const width = useWindowDimensions();
    useEffect(() => {
        console.log(meal.ingredients);
        console.log(width);
        const newIsOverflowed = checkIsOverflowed(ref.current);
        if (newIsOverflowed !== isOverflowed) {
            setIsOverflowed(newIsOverflowed);
        }
    }, [width, meal.ingredients]);

    useEffect(() => {
        if(isOverflowed && ref.current && ref.current.children) {
            Array.from(ref.current.children).forEach((it, index) => {
                if(!checkIsVisible(it, ref.current)) {
                    console.log("-------------Index changed: " + index);
                    setLastVisibleIndex(index);
                }
            });
        }
    }, [isOverflowed]);
    console.log("-----------------------------------------------------------------render");
    return (
        <ul className="plan__ingredients" ref={ref}>
            {createIngredientsList(meal.ingredients).map((ing) => (
                <React.Fragment key={ing.id + lastVisibleIndex}>
                    <IngredientListItem ingredient={ing} />
                </React.Fragment>
            ))}
            {isOverflowed ? 
                <li className="block__content plan__ingredients-item">
                    +{findOverflowedCount(createIngredientsList(meal.ingredients), ref.current)}
                </li> 
                : 
                null}
        </ul> 
    );
};

export default MealPlanItemIngredientsList;
