import React from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import { i18n } from '../../i18n';
import { QuestionIcon } from '../../appearence/icons/question_icon';

const findPreferenceTitle = (preferenceId) => {
    if(preferencesStorage.getPreferenceById(preferenceId).include !== undefined) {
        return i18n.get(`preference.scheme.include.${preferencesStorage.getPreferenceById(preferenceId).include}`);
    } else {
        return '';
    }
};

const PreferencesListItemInclude = ({ preferenceId }) => {
    return (
        <div className={`block__text preference__field`}>
            <QuestionIcon nameOfClass={`preference__field-icon`}/>
            <label className="preference__field-label">
                <span className="block__content preference__field-title">{i18n.get('app.page.preference.addTitles.include')}</span>
                <span className='preference__field-content'>{findPreferenceTitle(preferenceId)}</span>
            </label>
        </div>
    );
};

export default PreferencesListItemInclude;
