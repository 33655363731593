import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { XIcon } from '../../appearence/icons/x_icon';
import { i18n } from '../../i18n';
import FiltersActiveListItem from './FiltersActiveListItem';

const FiltersActiveList = ({ itemStorage }) => {
    const [filterValueIsChanged, setFilterValueIsChanged] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const changeFilter = () => {
        setFilterValueIsChanged(prev => !prev);
    };

    const cleanFilters = () => {
        itemStorage.deleteFilterItem('', true);
        for (const [key] of Array.from(searchParams)) {
            searchParams.delete(key);
        }
        setSearchParams(searchParams);
    };

    useEffect(() => {
        itemStorage.registerFiltersChangingObserver(changeFilter);
        changeFilter();
        return () => {
            itemStorage.unRegisterFiltersChangingObserver(changeFilter);
        };
    }, []);
    return (
        <ul className='preference__filter-list'>
            {filterValueIsChanged !== null ? 
                itemStorage.getCurrentFilters().map(it => (
                    <React.Fragment key={it.title + it.code}>
                        <FiltersActiveListItem filter={it} itemStorage={itemStorage} />
                    </React.Fragment>
                ))
                :
                null}
            <li className="product__forms-item product__forms-item_excluded"
                onClick={cleanFilters}>
                <span className="block__bullet product__forms-text">{i18n.get('app.page.preference.clearAll')}</span>
                <XIcon nameOfClass="product__forms-item-icon" />
            </li>
        </ul>
    );
};

export default FiltersActiveList;
