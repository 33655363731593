import React from 'react';
import { i18n } from '../../i18n';

const Modal = ({content, handleCloseModal, size}) => (
    <>
        <div className="modal__wrapper modal__wrapper_active" onClick={() => handleCloseModal(null)}></div>  
        <article className={`modal ${size ? `modal_${size}` : ''}`}>
            {content}
            {!size ? 
                <p onClick={() => handleCloseModal(false)} className="main__subtitle modal__close navigation-link">
                    {i18n.get('app.page.modal.close')}
                </p>
                :
                null}
        </article>

    </>
);

export default Modal;
