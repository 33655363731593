import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PREFERENCE_TAB } from '../../constant';
import { PenIcon } from '../../appearence/icons/pen_icon';

const PreferencesListItemEditButton = ({ preferenceId }) => {
    const navigate = useNavigate();

    const navigateOnEditPage = () => {
        navigate(`/${PREFERENCE_TAB}/item/${preferenceId}`);
    };

    return (
        <span onClick={navigateOnEditPage}>
            <PenIcon nameOfClass="preference__list-icon" handleClick={() => {}}/>
        </span>
    );
};

export default PreferencesListItemEditButton;
