import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import Loading from '../Loading';

const PreferenceItemCreatePhrase = ({ isLoading, setIsLoading }) => {
    const [phraseValue, setPhraseValue] = useState('');

    const changePhraseValue = (e) => {
        if(e && e.target) {
            setPhraseValue(e.target.value);
            preferenceSaveService.changePreferenceItemPhrase(e.target.value);
        } else {
            setPhraseValue(preferenceSaveService.getPreference().phrase);
        }
    };

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(changePhraseValue);
        changePhraseValue();
        return () => preferenceSaveService.unRegisterItemUpdateObserver(changePhraseValue);
    }, []);

    const startTableGeneration = () => {
        if(phraseValue) {
            preferenceSaveService.generateTableByPhrase();
            setIsLoading({table: true, phrase: false});
        }
    };

    return (
        <label className="block__text preference__label preference__label-phrase">
            <span className="preference__label-content">{i18n.get('app.page.preference.preferenceAsPhrase')}</span>
            <input className={`block__content preference__input ${phraseValue ? 'preference__input_filled' : ''}`}
                placeholder={isLoading.phrase ? '' : i18n.get('app.page.preference.preferenceAsPhrasePlaceholder')}
                value={isLoading.phrase || !phraseValue ? '' : phraseValue}
                onChange={changePhraseValue}
                onBlur={startTableGeneration}
            />
            {isLoading.phrase ?
                <Loading />
                :
                null}
            <span className="preference__label-info">{i18n.get('app.page.preference.preferenceAsPhraseInfo')}</span>
        </label>
    );
};

export default PreferenceItemCreatePhrase;
