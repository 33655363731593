import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoIcon } from '../../appearence/icons/logo_icon';
import InitialPreferencesLogoImage from './InitialPreferencesLogoImage';
import { i18n } from '../../i18n';
import InitialPreferencesList from './InitialPreferencesList';
import InitialPreferencesButton from './InitialPreferencesButton';

const InitialPreferences = () => {
    const navigate = useNavigate();

    const skipInitialPreferences = () => {
        navigate('/plan');
    };

    return (
        <>
            <header className="preference__initial-header">
                <LogoIcon nameOfClass="preference__initial-logo_small" />
                <InitialPreferencesLogoImage />
            </header>
            <main className="preference__initial">
                <h1 className="block__title-h3 preference__title">{i18n.get('app.page.preference.preference')}</h1>
                <p className="main__subtitle preference__initial-subtitle">{i18n.get('app.page.preference.preferencesCount')}</p>
                <section className="preference__initial-block">
                    <h2 className="block__subtitle preference__initial-title">
                        <span className="preference__initial-text">{i18n.get('app.page.preference.mainPreference')}</span>
                    </h2>
                    <InitialPreferencesList />
                    <p className="main__subtitle preference__initial-subtitle">{i18n.get('app.page.preference.preferencesInfo')}</p>
                    <div className="preference__initial-buttons">
                        <button className='block__button button button_outline preference__initial-button_outline' 
                            onClick={skipInitialPreferences}>
                            <span className='button__content'>{i18n.get('app.page.preference.skip')}</span>
                        </button>
                        <InitialPreferencesButton />
                    </div>
                </section>
            </main>
        </>
    );
};

export default InitialPreferences;
