import React from 'react';
import { useParams } from 'react-router-dom';
import PreferencesListItemActivityTime from './PreferencesListItemActivityTime';
import PreferencesListItemInclude from './PreferencesListItemInclude';
import PreferencesListItemType from './PreferencesListItemType';
import PreferencesListItemWhat from './PreferencesListItemWhat';
import PreferencesListItemWhenAndHowOften from './PreferencesListItemWhenAndHowOften';
import PreferencesListItemEditButton from './PreferencesListItemEditButton';
import PreferencesListItemDeleteButton from './PreferencesListItemDeleteButton';
import { PREFERENCE_PHRASE_TAB } from '../../constant';
import PreferencesListItemPhrase from './PreferencesListItemPhrase';
import { preferencesStorage } from '../../services/preferencesStorage';

const checkPhraseIsExists = () => {
    return preferencesStorage.getItems().findIndex(it => it.phrase) >= 0;
};

const PreferencesListItem = ({ preferenceId }) => {
    const { tab } = useParams();
    return (
        <li className="preference__list-item">
            <PreferencesListItemActivityTime preferenceId={preferenceId} />
            <ul className="preference__list-grid">
                {tab === PREFERENCE_PHRASE_TAB && checkPhraseIsExists() && preferencesStorage.getPreferenceById(preferenceId).phrase ?
                    <PreferencesListItemPhrase preferenceId={preferenceId} />
                    :
                    <>
                        <PreferencesListItemInclude preferenceId={preferenceId} />
                        <PreferencesListItemType preferenceId={preferenceId} />
                        <PreferencesListItemWhat preferenceId={preferenceId} />
                        <PreferencesListItemWhenAndHowOften preferenceId={preferenceId} />
                    </>}
            </ul>
            <div className="preference__list-icons">
                <PreferencesListItemEditButton preferenceId={preferenceId} />
                <PreferencesListItemDeleteButton preferenceId={preferenceId} />
            </div>
        </li>
    );
};

export default PreferencesListItem;
