import React, { useState, useEffect } from 'react';
import PreferenceItemCreateWhatOptionListItem from './PreferenceItemCreateWhatOptionListItem';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';
import CreateCerealMixBlock from './CreateCerealMixBlock';
import { PlusIcon } from '../../appearence/icons/plus_icon';
import { i18n } from '../../i18n';
import { PREFERENCE_TYPE_CEREAL_MIX } from '../../constant';

const getCurrentService = () => {
    return preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX ?
        preferenceCerealMixSelectService.getPossibleItems()
        :
        preferenceSaveService.getPossibleCodeValues();
};

const PreferenceItemCreateWhatOptionList = ({ setIsLoading }) => {
    const [optionsListIsChanged, setOptionsListIsChanged] = useState(false);
    const [addNewCerealMixIsOpen, setAddNewCerealMixIsOpen] = useState(false);

    const changePreference = () => {
        setOptionsListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferenceCerealMixSelectService.registerItemsChangingObserver(changePreference);
        return () => preferenceCerealMixSelectService.registerItemsChangingObserver(changePreference);
    }, []);

    return (
        preferenceSaveService.getPossibleCodeValues().length ?
            <div className='select__choice-dropdown select__choice-dropdown_full-width'>
                {addNewCerealMixIsOpen && preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX ? 
                    <CreateCerealMixBlock closeCallback={setAddNewCerealMixIsOpen} />
                    :
                    <>
                        <ul className='scroll select__choice-content'>
                            {getCurrentService().map((it, index) => (
                                <React.Fragment key={it.name + index + optionsListIsChanged}>
                                    <PreferenceItemCreateWhatOptionListItem itemCode={it.id} itemName={it.name} setIsLoading={setIsLoading} />
                                </React.Fragment>
                            ))}
                        </ul>
                        {preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX ?
                            <div className="block__text preference__mix" onClick={() => setAddNewCerealMixIsOpen(true)}>
                                <span className="button__content">{i18n.get("app.page.preference.addCerealMix")}</span>
                                <PlusIcon nameOfClass="preference__mix-plus" />
                            </div>
                            :
                            null}
                    </>}
            </div>    
            :
            null
    );
};

export default PreferenceItemCreateWhatOptionList;
