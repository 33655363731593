import React, { useState, useEffect } from 'react';
import PossibleFiltersListItem from './PossibleFiltersListItem';

const PossibleFiltersList = ({ itemStorage }) => {
    const [preferenceType, setPreferenceType] = useState('');
    const changeFiltersList = () => {
        setPreferenceType(itemStorage.getCurrentFilterPreferenceType());
    };

    useEffect(() => {
        itemStorage.registerFiltersChangingObserver(changeFiltersList);
        changeFiltersList();
        return () => {
            itemStorage.unRegisterFiltersChangingObserver(changeFiltersList);
        };
    }, []);
    return (
        <ul className='scroll preference__filter-block'>
            {preferenceType ? 
                itemStorage.getPossibleFilters().map(it => (
                    <li key={it.filterName + 'possible'} className='scroll preference__filter-item'>
                        <h3 className="main__subtitle">{it.filterTitle}</h3>
                        <ul className="preference__filter-group">
                            {it.values.map(filterItem => (
                                <React.Fragment key={filterItem.title + filterItem.value}>
                                    <PossibleFiltersListItem filter={filterItem} itemStorage={itemStorage} />
                                </React.Fragment>
                            ))}
                        </ul>
                    </li>
                ))
                :
                null}
        </ul>
    );
};

export default PossibleFiltersList;
