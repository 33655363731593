import React from 'react';
import { i18n } from '../i18n';
import { MODAL_SMALL_SIZE, MODAL_SUPER_SMALL_SIZE } from './helper';

const Modal = ({ content, handleCloseModal, size }) => (
    <>
        <div className="modal__wrapper" onClick={() => handleCloseModal(null)}></div>
        <article className={`modal ${size === MODAL_SMALL_SIZE ? 'modal_small' : size === MODAL_SUPER_SMALL_SIZE ? 'modal_xs' : ''}`}>
            {content}
            {!size ?
                <p onClick={() => handleCloseModal(false)} className="main__subtitle modal__close navigation-link">
                    {i18n.get('app.page.modal.close')}
                </p>
                :
                null}
        </article>

    </>
);

export default Modal;
