import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../i18n';
import { PAGE_TAB_NAME_LIST, PAGE_TAB_NAME_NEW, PREFERENCE_TAB } from '../constant';
import { ErrorHandler } from './ErrorHandler';
import PreferenceCreateHandler from './preferences/PreferenceCreateHandler';
import PreferenceListContainer from './preferences/PreferenceListContainer';
import PreferenceEditHandler from './preferences/PreferenceEditHandler';
import PreferencesListFilterPopup from './preferences/PreferencesListFilterPopup';

const PreferencePage = () => {
    const [activeRoute, setActiveRoute] = useState('');
    const {tab, id} = useParams();

    useEffect(() => {
        setActiveRoute(`/${PREFERENCE_TAB}/${tab}/${id}/`);
    }, [tab, id]);

    try {
        if(!activeRoute) return null;
        return (
            <section className="workspace workspace__preference">
                {id === PAGE_TAB_NAME_LIST ?
                    <>
                        <h1 className="block__title-h2 preference__title">{i18n.get('app.page.preference.preference')}</h1>
                        <PreferenceListContainer />
                        <PreferencesListFilterPopup />
                    </>
                    :
                    id === PAGE_TAB_NAME_NEW ? <PreferenceCreateHandler /> : <PreferenceEditHandler />}
            </section>
        );
    } catch (err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;   
    }
};

export default PreferencePage;
