import React, { useState, useEffect, useCallback, useRef } from 'react';
import { i18n } from '../../i18n';
import { PlanIcon } from '../../appearence/icons/plan_icon';
import PreferenceItemCreateHowOftenInput, { findPreferenceHowOftenTitle } from './PreferenceItemCreateHowOftenInput';
import PreferenceItemCreateHowOftenList from './PreferenceItemCreateHowOftenList';
import { MOUSE_DOWN_EVENT } from '../../constant';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import Loading from '../Loading';

const PreferenceItemCreateHowOftenField = ({ isLoading, setIsLoading }) => {
    const wrapperRef = useRef(null);
    const [optionListIsOpen, setOptionListIsOpen] = useState(false);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOptionListIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    const openListWithVariants = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(true);
    };

    const closeOptionsList = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(prev => !prev);
    };

    return (
        <div className={`block__text preference__create-block ${findPreferenceHowOftenTitle() && isLoading.table === false ? 'preference__create-block_active' : ''}`}>
            <PlanIcon nameOfClass={`preference__create-icon ${findPreferenceHowOftenTitle() && isLoading.table === false ? 'preference__create-icon_active' : ''}`}/>
            {isLoading.table ?
                <Loading />
                :
                <label className="preference__create-label" onClick={openListWithVariants} ref={wrapperRef}>
                    <span className="block__content preference__label-title">{i18n.get('app.page.preference.addTitles.howOften')}</span>
                    <div className={`block__content select__choice select__choice_full-width ${findPreferenceHowOftenTitle() && isLoading.table === false ? 'select__choice_active' : ''} `}>
                        <PreferenceItemCreateHowOftenInput />
                        {optionListIsOpen ? <PreferenceItemCreateHowOftenList setIsLoading={setIsLoading} /> : null}
                        <ChevronIcon handleClick={closeOptionsList}
                            nameOfClass={`select__choice-icon ${optionListIsOpen ? 'select__choice-icon_active' : ''} select__choice-icon_full-width`} />
                    </div>
                </label>}
        </div>
    );
};

export default PreferenceItemCreateHowOftenField;
