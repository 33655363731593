import React, { useState, useEffect } from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import PreferencesListItem from './PreferencesListItem';

const PreferencesList = () => {
    const [preferencesListIsChanged, setPreferencesListIsChanged] = useState(false);

    const changePreferencesList = () => {
        setPreferencesListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(changePreferencesList);
        return () => preferencesStorage.unRegisterFiltersChangingObserver(changePreferencesList);
    }, []);

    return (
        <ul className="preference__list" key={preferencesListIsChanged + 'list'}>
            {preferencesStorage.getItems().map(it => (
                <React.Fragment key={it.id + preferencesListIsChanged}>
                    <PreferencesListItem preferenceId={it.id} />
                </React.Fragment>
            ))}
        </ul>
    );
};

export default PreferencesList;
