import React from "react";

export const InitialPreferencesIcon = () => (
    <svg className="preference__initial-logo" viewBox="0 0 967 176" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8838_1405)">
            <rect width="967" height="176" rx="16" fill="white" />
            <rect width="172" height="130" fill="white" />
            <path d="M27.6923 18.8811C27.5356 18.9934 27.4026 19.1355 27.3009 19.2993C27.1993 19.4631 27.1309 19.6454 27.0997 19.8356C27.0685 20.0259 27.0752 20.2204 27.1193 20.4081C27.1634 20.5958 27.2441 20.7729 27.3568 20.9294L32.6077 28.2276L26.0595 25.2809C25.3165 24.9491 24.4341 25.2456 24.0865 25.9741C23.9212 26.3315 23.9045 26.74 24.0402 27.1097C24.1758 27.4794 24.4527 27.7801 24.81 27.9458L31.405 30.9208L22.4602 31.8273C22.074 31.8676 21.7195 32.059 21.4739 32.3598C21.2283 32.6605 21.1116 33.0461 21.1492 33.4325C21.1896 33.8187 21.381 34.1732 21.6817 34.4188C21.9824 34.6644 22.368 34.7811 22.7544 34.7435L27.8011 34.2348C29.9165 34.021 32.0715 34.367 34.0065 35.2387L36.9942 36.5803C37.9848 37.0227 39.1649 36.5918 39.6218 35.5854L41.5586 31.2818C42.0009 30.2912 41.57 29.1111 40.5636 28.6542L37.576 27.3125C35.6303 26.443 33.9516 25.0704 32.7129 23.3362L29.7406 19.2166C29.2715 18.5541 28.3541 18.3969 27.6923 18.8811Z" fill="#BCD3CC" />
            <path d="M9.89838 13.1637C12.5646 11.2588 16.0019 4.49031 21.4629 7.0602C26.9239 9.63009 31.312 3.02225 33.6153 9.85816C35.9185 16.6973 42.022 19.5884 37.5247 21.1946C33.0274 22.8008 25.639 24.7282 26.2814 29.5467C26.9239 34.3653 26.2365 39.5276 20.7979 39.5147C15.3594 39.5019 10.0397 38.959 8.20226 32.9679C6.36479 26.9769 3.89127 29.3733 4.0037 23.0353C4.11613 16.6973 7.64973 14.7699 9.89838 13.1637Z" fill="#264948" />
            <path d="M64.9843 4C59.7126 4 55.2568 5.84831 51.5541 9.57626C47.8514 13.3042 46 17.7527 46 23.0157C46 28.2786 47.8514 32.7271 51.5541 36.4237C55.2568 40.1204 59.744 42 64.9843 42C70.2246 42 74.7118 40.1517 78.4145 36.4237C82.1173 32.7271 84 28.2473 84 23.0157C84 17.784 82.1486 13.2729 78.4145 9.57626C74.7118 5.87964 70.2246 4 64.9843 4ZM76.0925 11.9258C79.1676 14.9959 80.7052 18.6925 80.7052 23.0157C80.7052 27.3388 79.1676 31.0354 76.0925 34.0742C73.0173 37.1129 69.3146 38.648 64.9843 38.648C60.654 38.648 56.9513 37.1129 53.8761 34.0742C50.801 31.0354 49.2634 27.3388 49.2634 23.0157C49.2634 18.6925 50.801 14.9959 53.8761 11.9258C56.9513 8.85573 60.654 7.32069 64.9843 7.32069C69.3146 7.32069 73.0173 8.85573 76.0925 11.9258Z" fill="#BCD3CC" />
            <path d="M106.984 4C101.713 4 97.2568 5.84831 93.5541 9.57626C89.8514 13.3042 88 17.7527 88 23.0157C88 28.2786 89.8514 32.7271 93.5541 36.4237C97.2568 40.1204 101.744 42 106.984 42C112.225 42 116.712 40.1517 120.415 36.4237C124.117 32.7271 126 28.2473 126 23.0157C126 17.784 124.149 13.2729 120.415 9.57626C116.712 5.87964 112.225 4 106.984 4ZM118.092 11.9258C121.168 14.9959 122.705 18.6925 122.705 23.0157C122.705 27.3388 121.168 31.0354 118.092 34.0742C115.017 37.1129 111.315 38.648 106.984 38.648C102.654 38.648 98.9513 37.1129 95.8761 34.0742C92.801 31.0354 91.2634 27.3388 91.2634 23.0157C91.2634 18.6925 92.801 14.9959 95.8761 11.9258C98.9513 8.85573 102.654 7.32069 106.984 7.32069C111.315 7.32069 115.017 8.85573 118.092 11.9258Z" fill="#BCD3CC" />
            <path d="M148.984 4C143.713 4 139.257 5.84831 135.554 9.57626C131.851 13.3042 130 17.7527 130 23.0157C130 28.2786 131.851 32.7271 135.554 36.4237C139.257 40.1204 143.744 42 148.984 42C154.225 42 158.712 40.1517 162.415 36.4237C166.117 32.7271 168 28.2473 168 23.0157C168 17.784 166.149 13.2729 162.415 9.57626C158.712 5.87964 154.225 4 148.984 4ZM160.092 11.9258C163.168 14.9959 164.705 18.6925 164.705 23.0157C164.705 27.3388 163.168 31.0354 160.092 34.0742C157.017 37.1129 153.315 38.648 148.984 38.648C144.654 38.648 140.951 37.1129 137.876 34.0742C134.801 31.0354 133.263 27.3388 133.263 23.0157C133.263 18.6925 134.801 14.9959 137.876 11.9258C140.951 8.85573 144.654 7.32069 148.984 7.32069C153.315 7.32069 157.017 8.85573 160.092 11.9258Z" fill="#BCD3CC" />
            <path d="M22.9843 46C17.7126 46 13.2568 47.8483 9.55409 51.5763C5.85136 55.3042 4 59.7527 4 65.0157C4 70.2786 5.85136 74.7271 9.55409 78.4237C13.2568 82.1204 17.744 84 22.9843 84C28.2246 84 32.7118 82.1517 36.4145 78.4237C40.1173 74.7271 42 70.2473 42 65.0157C42 59.784 40.1486 55.2729 36.4145 51.5763C32.7118 47.8796 28.2246 46 22.9843 46ZM34.0925 53.9258C37.1676 56.9959 38.7052 60.6925 38.7052 65.0157C38.7052 69.3388 37.1676 73.0354 34.0925 76.0742C31.0173 79.1129 27.3146 80.648 22.9843 80.648C18.654 80.648 14.9513 79.1129 11.8761 76.0742C8.80099 73.0354 7.26342 69.3388 7.26342 65.0157C7.26342 60.6925 8.80099 56.9959 11.8761 53.9258C14.9513 50.8557 18.654 49.3207 22.9843 49.3207C27.3146 49.3207 31.0173 50.8557 34.0925 53.9258Z" fill="#BCD3CC" />
            <path d="M58.3954 50.9851C71.2555 47.2346 80.9818 50.1959 83.3218 60.1536C86.5662 73.955 77.6138 79.5433 68.2861 80.2334C58.9584 80.9235 52.7845 81.3378 48.5018 73.7816C44.2232 66.2253 44.2011 55.1256 58.3954 50.9851Z" fill="#FFBCB8" />
            <path d="M60.9886 55.7892C56.7627 56.1377 56.6735 55.5339 60.2505 54.378C63.3083 53.3912 63.5395 52.9047 63.4016 51.6695C63.2354 50.2134 64.6062 49.5923 64.8008 51.3106C64.9671 52.7667 65.5916 53.2049 67.8222 52.5321C70.2393 51.8006 69.8459 52.6114 67.9885 53.9881C66.7637 54.8955 67.0111 55.5408 68.5765 56.6345C71.4113 58.6219 70.6205 59.4086 67.8587 57.9181C65.0968 56.431 65.2023 57.5006 63.9735 59.2189C62.481 61.3029 61.3414 61.396 62.4648 58.6081C63.503 56.0273 63.2759 55.5994 60.9927 55.7892H60.9886Z" fill="#264948" />
            <path d="M106.984 46C101.713 46 97.2568 47.8483 93.5541 51.5763C89.8514 55.3042 88 59.7527 88 65.0157C88 70.2786 89.8514 74.7271 93.5541 78.4237C97.2568 82.1204 101.744 84 106.984 84C112.225 84 116.712 82.1517 120.415 78.4237C124.117 74.7271 126 70.2473 126 65.0157C126 59.784 124.149 55.2729 120.415 51.5763C116.712 47.8796 112.225 46 106.984 46ZM118.092 53.9258C121.168 56.9959 122.705 60.6925 122.705 65.0157C122.705 69.3388 121.168 73.0354 118.092 76.0742C115.017 79.1129 111.315 80.648 106.984 80.648C102.654 80.648 98.9513 79.1129 95.8761 76.0742C92.801 73.0354 91.2634 69.3388 91.2634 65.0157C91.2634 60.6925 92.801 56.9959 95.8761 53.9258C98.9513 50.8557 102.654 49.3207 106.984 49.3207C111.315 49.3207 115.017 50.8557 118.092 53.9258Z" fill="#BCD3CC" />
            <path d="M148.984 46C143.713 46 139.257 47.8483 135.554 51.5763C131.851 55.3042 130 59.7527 130 65.0157C130 70.2786 131.851 74.7271 135.554 78.4237C139.257 82.1204 143.744 84 148.984 84C154.225 84 158.712 82.1517 162.415 78.4237C166.117 74.7271 168 70.2473 168 65.0157C168 59.784 166.149 55.2729 162.415 51.5763C158.712 47.8796 154.225 46 148.984 46ZM160.092 53.9258C163.168 56.9959 164.705 60.6925 164.705 65.0157C164.705 69.3388 163.168 73.0354 160.092 76.0742C157.017 79.1129 153.315 80.648 148.984 80.648C144.654 80.648 140.951 79.1129 137.876 76.0742C134.801 73.0354 133.263 69.3388 133.263 65.0157C133.263 60.6925 134.801 56.9959 137.876 53.9258C140.951 50.8557 144.654 49.3207 148.984 49.3207C153.315 49.3207 157.017 50.8557 160.092 53.9258Z" fill="#BCD3CC" />
            <path d="M22.9843 88C17.7126 88 13.2568 89.8483 9.55409 93.5763C5.85136 97.3042 4 101.753 4 107.016C4 112.279 5.85136 116.727 9.55409 120.424C13.2568 124.12 17.744 126 22.9843 126C28.2246 126 32.7118 124.152 36.4145 120.424C40.1173 116.727 42 112.247 42 107.016C42 101.784 40.1486 97.2729 36.4145 93.5763C32.7118 89.8796 28.2246 88 22.9843 88ZM34.0925 95.9258C37.1676 98.9959 38.7052 102.692 38.7052 107.016C38.7052 111.339 37.1676 115.035 34.0925 118.074C31.0173 121.113 27.3146 122.648 22.9843 122.648C18.654 122.648 14.9513 121.113 11.8761 118.074C8.80099 115.035 7.26342 111.339 7.26342 107.016C7.26342 102.692 8.80099 98.9959 11.8761 95.9258C14.9513 92.8557 18.654 91.3207 22.9843 91.3207C27.3146 91.3207 31.0173 92.8557 34.0925 95.9258Z" fill="#BCD3CC" />
            <path d="M64.9843 88C59.7126 88 55.2568 89.8483 51.5541 93.5763C47.8514 97.3042 46 101.753 46 107.016C46 112.279 47.8514 116.727 51.5541 120.424C55.2568 124.12 59.744 126 64.9843 126C70.2246 126 74.7118 124.152 78.4145 120.424C82.1173 116.727 84 112.247 84 107.016C84 101.784 82.1486 97.2729 78.4145 93.5763C74.7118 89.8796 70.2246 88 64.9843 88ZM76.0925 95.9258C79.1676 98.9959 80.7052 102.692 80.7052 107.016C80.7052 111.339 79.1676 115.035 76.0925 118.074C73.0173 121.113 69.3146 122.648 64.9843 122.648C60.654 122.648 56.9513 121.113 53.8761 118.074C50.801 115.035 49.2634 111.339 49.2634 107.016C49.2634 102.692 50.801 98.9959 53.8761 95.9258C56.9513 92.8557 60.654 91.3207 64.9843 91.3207C69.3146 91.3207 73.0173 92.8557 76.0925 95.9258Z" fill="#BCD3CC" />
            <path d="M88.4741 110.717C91.9827 122.779 100.225 126.951 104.764 125.067C106.268 124.443 107.019 124.443 108.273 125.067C110.11 125.982 120.728 125.117 124.312 111.133C127.896 97.1482 116.568 93.4545 111.418 95.0143C106.268 96.5742 107.107 96.4556 100.504 94.7024C94.2382 93.0386 85.9179 101.923 88.4741 110.717Z" fill="#BCD3CC" />
            <path d="M110.459 106.5C110.459 106.5 109.944 110.876 110.974 111.648C112.003 112.42 113.547 111.905 113.033 109.846C112.518 107.787 110.459 106.5 110.459 106.5Z" fill="#264948" />
            <path d="M104.006 106.5C104.006 106.5 104.521 110.876 103.491 111.648C102.462 112.42 100.918 111.905 101.432 109.846C101.947 107.787 104.006 106.5 104.006 106.5Z" fill="#264948" />
            <path d="M107.207 96.4322C107.207 96.4322 106.612 96.9278 106.549 96.4322C106.504 96.068 106.551 93.1535 106.551 93.1535C106.551 92.3338 105.864 90.826 105.031 90.8745C102.995 90.9928 103.332 89.6577 103.69 89.0992C104.427 87.9494 108.073 89.7443 107.888 92.6723C107.702 95.6002 107.207 96.4322 107.207 96.4322Z" fill="#264948" />
            <path d="M147.984 88C142.713 88 138.257 89.8483 134.554 93.5763C130.851 97.3042 129 101.753 129 107.016C129 112.279 130.851 116.727 134.554 120.424C138.257 124.12 142.744 126 147.984 126C153.225 126 157.712 124.152 161.415 120.424C165.117 116.727 167 112.247 167 107.016C167 101.784 165.149 97.2729 161.415 93.5763C157.712 89.8796 153.225 88 147.984 88ZM159.092 95.9258C162.168 98.9959 163.705 102.692 163.705 107.016C163.705 111.339 162.168 115.035 159.092 118.074C156.017 121.113 152.315 122.648 147.984 122.648C143.654 122.648 139.951 121.113 136.876 118.074C133.801 115.035 132.263 111.339 132.263 107.016C132.263 102.692 133.801 98.9959 136.876 95.9258C139.951 92.8557 143.654 91.3207 147.984 91.3207C152.315 91.3207 156.017 92.8557 159.092 95.9258Z" fill="#BCD3CC" />
            <rect width="172" height="130.226" transform="translate(0 130)" fill="white" />
            <path d="M22.9843 134C17.7126 134 13.2568 135.848 9.55409 139.576C5.85136 143.304 4 147.753 4 153.016C4 158.279 5.85136 162.727 9.55409 166.424C13.2568 170.12 17.744 172 22.9843 172C28.2246 172 32.7118 170.152 36.4145 166.424C40.1173 162.727 42 158.247 42 153.016C42 147.784 40.1486 143.273 36.4145 139.576C32.7118 135.88 28.2246 134 22.9843 134ZM34.0925 141.926C37.1676 144.996 38.7052 148.692 38.7052 153.016C38.7052 157.339 37.1676 161.035 34.0925 164.074C31.0173 167.113 27.3146 168.648 22.9843 168.648C18.654 168.648 14.9513 167.113 11.8761 164.074C8.80099 161.035 7.26342 157.339 7.26342 153.016C7.26342 148.692 8.80099 144.996 11.8761 141.926C14.9513 138.856 18.654 137.321 22.9843 137.321C27.3146 137.321 31.0173 138.856 34.0925 141.926Z" fill="#BCD3CC" />
            <path d="M64.9843 134C59.7126 134 55.2568 135.848 51.5541 139.576C47.8514 143.304 46 147.753 46 153.016C46 158.279 47.8514 162.727 51.5541 166.424C55.2568 170.12 59.744 172 64.9843 172C70.2246 172 74.7118 170.152 78.4145 166.424C82.1173 162.727 84 158.247 84 153.016C84 147.784 82.1486 143.273 78.4145 139.576C74.7118 135.88 70.2246 134 64.9843 134ZM76.0925 141.926C79.1676 144.996 80.7052 148.692 80.7052 153.016C80.7052 157.339 79.1676 161.035 76.0925 164.074C73.0173 167.113 69.3146 168.648 64.9843 168.648C60.654 168.648 56.9513 167.113 53.8761 164.074C50.801 161.035 49.2634 157.339 49.2634 153.016C49.2634 148.692 50.801 144.996 53.8761 141.926C56.9513 138.856 60.654 137.321 64.9843 137.321C69.3146 137.321 73.0173 138.856 76.0925 141.926Z" fill="#BCD3CC" />
            <path d="M106.984 134C101.713 134 97.2568 135.848 93.5541 139.576C89.8514 143.304 88 147.753 88 153.016C88 158.279 89.8514 162.727 93.5541 166.424C97.2568 170.12 101.744 172 106.984 172C112.225 172 116.712 170.152 120.415 166.424C124.117 162.727 126 158.247 126 153.016C126 147.784 124.149 143.273 120.415 139.576C116.712 135.88 112.225 134 106.984 134ZM118.092 141.926C121.168 144.996 122.705 148.692 122.705 153.016C122.705 157.339 121.168 161.035 118.092 164.074C115.017 167.113 111.315 168.648 106.984 168.648C102.654 168.648 98.9513 167.113 95.8761 164.074C92.801 161.035 91.2634 157.339 91.2634 153.016C91.2634 148.692 92.801 144.996 95.8761 141.926C98.9513 138.856 102.654 137.321 106.984 137.321C111.315 137.321 115.017 138.856 118.092 141.926Z" fill="#BCD3CC" />
            <path d="M161.64 146.931C156.168 141.337 139.41 133.931 135.747 146.931C132.085 159.931 126.555 163.724 132.886 170.196C139.218 176.668 179.305 164.986 161.64 146.931Z" fill="#FFBCB8" />
            <path d="M159.085 142.732C159.298 139.446 156.655 137.486 155.701 136.89C155.543 136.791 155.332 136.888 155.316 137.068C155.271 137.542 155.241 138.452 155.441 139.723C155.497 140.078 155.311 140.174 155.029 139.937C153.857 138.952 151 136.895 148.743 138.079C148.609 138.149 148.567 138.314 148.658 138.432C149.014 138.896 150.118 140.082 153.089 141.825C153.635 142.145 153.562 142.345 152.926 142.266C151.224 142.054 148.252 141.906 146.272 143.049C146.092 143.153 146.116 143.409 146.313 143.479C147.912 144.05 153.331 145.912 155.469 145.771C156.527 145.701 155.264 149.09 154.774 150.316C154.7 150.5 154.877 150.686 155.075 150.632C156.326 150.293 159.599 149.205 159.619 147.109C159.621 146.896 159.883 146.785 160.043 146.934L165.559 152.05L165.559 152.05C165.56 152.046 166.605 147.735 162.179 145.627C161.107 145.117 164.673 145.127 165.846 145.144C166.032 145.147 166.157 144.96 166.074 144.8C165.09 142.895 160.527 142.941 159.343 142.986C159.195 142.992 159.075 142.873 159.085 142.732Z" fill="#264948" />
            <path d="M154.664 160.605C154.664 160.605 153.399 161.456 153.422 161.895C153.446 162.334 153.921 162.616 154.35 162.02C154.778 161.425 154.664 160.605 154.664 160.605Z" fill="#264948" />
            <path d="M149.523 164.433C149.523 164.433 148.258 165.284 148.281 165.723C148.304 166.162 148.779 166.444 149.208 165.848C149.637 165.253 149.523 164.433 149.523 164.433Z" fill="#264948" />
            <path d="M160.923 157.783C160.923 157.783 159.658 158.634 159.681 159.073C159.704 159.512 160.179 159.794 160.608 159.198C161.037 158.603 160.923 157.783 160.923 157.783Z" fill="#264948" />
            <path d="M135.273 162.533C135.273 162.533 134.008 163.384 134.031 163.823C134.054 164.262 134.529 164.544 134.958 163.948C135.387 163.353 135.273 162.533 135.273 162.533Z" fill="#264948" />
            <path d="M157.123 154.933C157.123 154.933 155.858 155.784 155.881 156.223C155.904 156.662 156.379 156.944 156.808 156.348C157.237 155.753 157.123 154.933 157.123 154.933Z" fill="#264948" />
            <path d="M153.323 152.083C153.323 152.083 152.058 152.934 152.081 153.373C152.104 153.812 152.579 154.094 153.008 153.498C153.437 152.903 153.323 152.083 153.323 152.083Z" fill="#264948" />
            <path d="M148.573 149.233C148.573 149.233 147.308 150.084 147.331 150.523C147.354 150.962 147.829 151.244 148.258 150.649C148.687 150.053 148.573 149.233 148.573 149.233Z" fill="#264948" />
            <path d="M150.473 157.783C150.473 157.783 149.208 158.634 149.231 159.073C149.254 159.512 149.729 159.794 150.158 159.198C150.587 158.603 150.473 157.783 150.473 157.783Z" fill="#264948" />
            <path d="M140.973 158.733C140.973 158.733 139.708 159.584 139.731 160.023C139.754 160.462 140.229 160.744 140.658 160.149C141.087 159.553 140.973 158.733 140.973 158.733Z" fill="#264948" />
            <path d="M145.723 161.583C145.723 161.583 144.458 162.434 144.481 162.873C144.504 163.312 144.979 163.594 145.408 162.998C145.837 162.403 145.723 161.583 145.723 161.583Z" fill="#264948" />
            <path d="M139.073 164.433C139.073 164.433 137.808 165.284 137.831 165.723C137.854 166.162 138.329 166.444 138.758 165.848C139.187 165.253 139.073 164.433 139.073 164.433Z" fill="#264948" />
            <path d="M142.873 166.333C142.873 166.333 141.608 167.184 141.631 167.623C141.654 168.062 142.129 168.344 142.558 167.748C142.987 167.153 142.873 166.333 142.873 166.333Z" fill="#264948" />
            <path d="M143.823 146.383C143.823 146.383 142.558 147.234 142.581 147.673C142.604 148.112 143.079 148.394 143.508 147.798C143.937 147.203 143.823 146.383 143.823 146.383Z" fill="#264948" />
            <path d="M140.973 152.083C140.973 152.083 139.708 152.934 139.731 153.373C139.754 153.812 140.229 154.094 140.658 153.498C141.087 152.903 140.973 152.083 140.973 152.083Z" fill="#264948" />
            <path d="M137.173 155.883C137.173 155.883 135.908 156.734 135.931 157.173C135.954 157.612 136.429 157.894 136.858 157.298C137.287 156.703 137.173 155.883 137.173 155.883Z" fill="#264948" />
            <path d="M145.723 154.933C145.723 154.933 144.458 155.784 144.481 156.223C144.504 156.662 144.979 156.944 145.408 156.348C145.837 155.753 145.723 154.933 145.723 154.933Z" fill="#264948" />
            <path d="M13.0412 184.33C10.7805 185.492 9.20665 186.942 8.62497 188.787C8.52639 189.104 8.58804 189.453 8.78761 189.72C10.2403 191.672 12.4727 192.847 15.0627 192.515C21.3762 191.704 22.1939 188.107 21.6467 183.873C21.1498 180.036 18.9241 177.089 14.519 177.081C10.13 177.074 6.57537 180.838 7.06602 185.255C7.07511 185.328 7.0928 185.472 7.0928 185.472C7.18139 186.117 7.9065 186.44 8.43982 186.08C11.2501 184.172 14.2238 183.724 13.0412 184.33Z" fill="#264948" />
            <path d="M12.8771 186.447C13.8079 189.398 15.2158 191.582 17.2907 192.652C17.6475 192.834 18.0742 192.831 18.431 192.644C21.0373 191.291 22.8756 188.844 22.9958 185.663C23.2867 177.91 19.1795 176.204 14.0445 176.01C9.39049 175.836 5.4501 177.918 4.56583 183.212C3.68544 188.487 7.44744 193.517 12.7879 193.816C12.8771 193.82 13.0517 193.827 13.0517 193.827C13.8351 193.851 14.3626 193.044 14.0407 192.33C12.3342 188.568 12.3923 184.903 12.8771 186.447Z" fill="#BCD3CC" />
            <rect width="168" height="130" transform="translate(172)" fill="white" />
            <path d="M195.692 18.8811C195.536 18.9934 195.403 19.1355 195.301 19.2993C195.199 19.4631 195.131 19.6454 195.1 19.8356C195.069 20.0259 195.075 20.2204 195.119 20.4081C195.163 20.5958 195.244 20.7729 195.357 20.9294L200.608 28.2276L194.059 25.2809C193.317 24.9491 192.434 25.2456 192.086 25.9741C191.921 26.3315 191.905 26.74 192.04 27.1097C192.176 27.4794 192.453 27.7801 192.81 27.9458L199.405 30.9208L190.46 31.8273C190.074 31.8676 189.719 32.059 189.474 32.3598C189.228 32.6605 189.112 33.0461 189.149 33.4325C189.19 33.8187 189.381 34.1732 189.682 34.4188C189.982 34.6644 190.368 34.7811 190.754 34.7435L195.801 34.2348C197.916 34.021 200.071 34.367 202.007 35.2387L204.994 36.5803C205.985 37.0227 207.165 36.5918 207.622 35.5854L209.559 31.2818C210.001 30.2912 209.57 29.1111 208.564 28.6542L205.576 27.3125C203.63 26.443 201.952 25.0704 200.713 23.3362L197.741 19.2166C197.272 18.5541 196.354 18.3969 195.692 18.8811Z" fill="#BCD3CC" />
            <path d="M177.898 13.1637C180.565 11.2588 184.002 4.49031 189.463 7.0602C194.924 9.63009 199.312 3.02225 201.615 9.85816C203.919 16.6973 210.022 19.5884 205.525 21.1946C201.027 22.8008 193.639 24.7282 194.281 29.5467C194.924 34.3653 194.236 39.5276 188.798 39.5147C183.359 39.5019 178.04 38.959 176.202 32.9679C174.365 26.9769 171.891 29.3733 172.004 23.0353C172.116 16.6973 175.65 14.7699 177.898 13.1637Z" fill="#264948" />
            <path d="M232.984 4C227.713 4 223.257 5.84831 219.554 9.57626C215.851 13.3042 214 17.7527 214 23.0157C214 28.2786 215.851 32.7271 219.554 36.4237C223.257 40.1204 227.744 42 232.984 42C238.225 42 242.712 40.1517 246.415 36.4237C250.117 32.7271 252 28.2473 252 23.0157C252 17.784 250.149 13.2729 246.415 9.57626C242.712 5.87964 238.225 4 232.984 4ZM244.092 11.9258C247.168 14.9959 248.705 18.6925 248.705 23.0157C248.705 27.3388 247.168 31.0354 244.092 34.0742C241.017 37.1129 237.315 38.648 232.984 38.648C228.654 38.648 224.951 37.1129 221.876 34.0742C218.801 31.0354 217.263 27.3388 217.263 23.0157C217.263 18.6925 218.801 14.9959 221.876 11.9258C224.951 8.85573 228.654 7.32069 232.984 7.32069C237.315 7.32069 241.017 8.85573 244.092 11.9258Z" fill="#BCD3CC" />
            <path d="M274.984 4C269.713 4 265.257 5.84831 261.554 9.57626C257.851 13.3042 256 17.7527 256 23.0157C256 28.2786 257.851 32.7271 261.554 36.4237C265.257 40.1204 269.744 42 274.984 42C280.225 42 284.712 40.1517 288.415 36.4237C292.117 32.7271 294 28.2473 294 23.0157C294 17.784 292.149 13.2729 288.415 9.57626C284.712 5.87964 280.225 4 274.984 4ZM286.092 11.9258C289.168 14.9959 290.705 18.6925 290.705 23.0157C290.705 27.3388 289.168 31.0354 286.092 34.0742C283.017 37.1129 279.315 38.648 274.984 38.648C270.654 38.648 266.951 37.1129 263.876 34.0742C260.801 31.0354 259.263 27.3388 259.263 23.0157C259.263 18.6925 260.801 14.9959 263.876 11.9258C266.951 8.85573 270.654 7.32069 274.984 7.32069C279.315 7.32069 283.017 8.85573 286.092 11.9258Z" fill="#BCD3CC" />
            <path d="M316.984 4C311.713 4 307.257 5.84831 303.554 9.57626C299.851 13.3042 298 17.7527 298 23.0157C298 28.2786 299.851 32.7271 303.554 36.4237C307.257 40.1204 311.744 42 316.984 42C322.225 42 326.712 40.1517 330.415 36.4237C334.117 32.7271 336 28.2473 336 23.0157C336 17.784 334.149 13.2729 330.415 9.57626C326.712 5.87964 322.225 4 316.984 4ZM328.092 11.9258C331.168 14.9959 332.705 18.6925 332.705 23.0157C332.705 27.3388 331.168 31.0354 328.092 34.0742C325.017 37.1129 321.315 38.648 316.984 38.648C312.654 38.648 308.951 37.1129 305.876 34.0742C302.801 31.0354 301.263 27.3388 301.263 23.0157C301.263 18.6925 302.801 14.9959 305.876 11.9258C308.951 8.85573 312.654 7.32069 316.984 7.32069C321.315 7.32069 325.017 8.85573 328.092 11.9258Z" fill="#BCD3CC" />
            <path d="M190.984 46C185.713 46 181.257 47.8483 177.554 51.5763C173.851 55.3042 172 59.7527 172 65.0157C172 70.2786 173.851 74.7271 177.554 78.4237C181.257 82.1204 185.744 84 190.984 84C196.225 84 200.712 82.1517 204.415 78.4237C208.117 74.7271 210 70.2473 210 65.0157C210 59.784 208.149 55.2729 204.415 51.5763C200.712 47.8796 196.225 46 190.984 46ZM202.092 53.9258C205.168 56.9959 206.705 60.6925 206.705 65.0157C206.705 69.3388 205.168 73.0354 202.092 76.0742C199.017 79.1129 195.315 80.648 190.984 80.648C186.654 80.648 182.951 79.1129 179.876 76.0742C176.801 73.0354 175.263 69.3388 175.263 65.0157C175.263 60.6925 176.801 56.9959 179.876 53.9258C182.951 50.8557 186.654 49.3207 190.984 49.3207C195.315 49.3207 199.017 50.8557 202.092 53.9258Z" fill="#BCD3CC" />
            <path d="M226.395 50.9851C239.256 47.2346 248.982 50.1959 251.322 60.1536C254.566 73.955 245.614 79.5433 236.286 80.2334C226.958 80.9235 220.784 81.3378 216.502 73.7816C212.223 66.2253 212.201 55.1256 226.395 50.9851Z" fill="#FFBCB8" />
            <path d="M228.989 55.7892C224.763 56.1377 224.673 55.5339 228.25 54.378C231.308 53.3912 231.54 52.9047 231.402 51.6695C231.235 50.2134 232.606 49.5923 232.801 51.3106C232.967 52.7667 233.592 53.2049 235.822 52.5321C238.239 51.8006 237.846 52.6114 235.988 53.9881C234.764 54.8955 235.011 55.5408 236.576 56.6345C239.411 58.6219 238.62 59.4086 235.859 57.9181C233.097 56.431 233.202 57.5006 231.973 59.2189C230.481 61.3029 229.341 61.396 230.465 58.6081C231.503 56.0273 231.276 55.5994 228.993 55.7892H228.989Z" fill="#264948" />
            <path d="M274.984 46C269.713 46 265.257 47.8483 261.554 51.5763C257.851 55.3042 256 59.7527 256 65.0157C256 70.2786 257.851 74.7271 261.554 78.4237C265.257 82.1204 269.744 84 274.984 84C280.225 84 284.712 82.1517 288.415 78.4237C292.117 74.7271 294 70.2473 294 65.0157C294 59.784 292.149 55.2729 288.415 51.5763C284.712 47.8796 280.225 46 274.984 46ZM286.092 53.9258C289.168 56.9959 290.705 60.6925 290.705 65.0157C290.705 69.3388 289.168 73.0354 286.092 76.0742C283.017 79.1129 279.315 80.648 274.984 80.648C270.654 80.648 266.951 79.1129 263.876 76.0742C260.801 73.0354 259.263 69.3388 259.263 65.0157C259.263 60.6925 260.801 56.9959 263.876 53.9258C266.951 50.8557 270.654 49.3207 274.984 49.3207C279.315 49.3207 283.017 50.8557 286.092 53.9258Z" fill="#BCD3CC" />
            <path d="M316.984 46C311.713 46 307.257 47.8483 303.554 51.5763C299.851 55.3042 298 59.7527 298 65.0157C298 70.2786 299.851 74.7271 303.554 78.4237C307.257 82.1204 311.744 84 316.984 84C322.225 84 326.712 82.1517 330.415 78.4237C334.117 74.7271 336 70.2473 336 65.0157C336 59.784 334.149 55.2729 330.415 51.5763C326.712 47.8796 322.225 46 316.984 46ZM328.092 53.9258C331.168 56.9959 332.705 60.6925 332.705 65.0157C332.705 69.3388 331.168 73.0354 328.092 76.0742C325.017 79.1129 321.315 80.648 316.984 80.648C312.654 80.648 308.951 79.1129 305.876 76.0742C302.801 73.0354 301.263 69.3388 301.263 65.0157C301.263 60.6925 302.801 56.9959 305.876 53.9258C308.951 50.8557 312.654 49.3207 316.984 49.3207C321.315 49.3207 325.017 50.8557 328.092 53.9258Z" fill="#BCD3CC" />
            <path d="M190.984 88C185.713 88 181.257 89.8483 177.554 93.5763C173.851 97.3042 172 101.753 172 107.016C172 112.279 173.851 116.727 177.554 120.424C181.257 124.12 185.744 126 190.984 126C196.225 126 200.712 124.152 204.415 120.424C208.117 116.727 210 112.247 210 107.016C210 101.784 208.149 97.2729 204.415 93.5763C200.712 89.8796 196.225 88 190.984 88ZM202.092 95.9258C205.168 98.9959 206.705 102.692 206.705 107.016C206.705 111.339 205.168 115.035 202.092 118.074C199.017 121.113 195.315 122.648 190.984 122.648C186.654 122.648 182.951 121.113 179.876 118.074C176.801 115.035 175.263 111.339 175.263 107.016C175.263 102.692 176.801 98.9959 179.876 95.9258C182.951 92.8557 186.654 91.3207 190.984 91.3207C195.315 91.3207 199.017 92.8557 202.092 95.9258Z" fill="#BCD3CC" />
            <path d="M232.984 88C227.713 88 223.257 89.8483 219.554 93.5763C215.851 97.3042 214 101.753 214 107.016C214 112.279 215.851 116.727 219.554 120.424C223.257 124.12 227.744 126 232.984 126C238.225 126 242.712 124.152 246.415 120.424C250.117 116.727 252 112.247 252 107.016C252 101.784 250.149 97.2729 246.415 93.5763C242.712 89.8796 238.225 88 232.984 88ZM244.092 95.9258C247.168 98.9959 248.705 102.692 248.705 107.016C248.705 111.339 247.168 115.035 244.092 118.074C241.017 121.113 237.315 122.648 232.984 122.648C228.654 122.648 224.951 121.113 221.876 118.074C218.801 115.035 217.263 111.339 217.263 107.016C217.263 102.692 218.801 98.9959 221.876 95.9258C224.951 92.8557 228.654 91.3207 232.984 91.3207C237.315 91.3207 241.017 92.8557 244.092 95.9258Z" fill="#BCD3CC" />
            <path d="M256.474 110.717C259.983 122.779 268.225 126.951 272.764 125.067C274.268 124.443 275.019 124.443 276.273 125.067C278.11 125.982 288.728 125.117 292.312 111.133C295.896 97.1482 284.568 93.4545 279.418 95.0143C274.268 96.5742 275.107 96.4556 268.504 94.7024C262.238 93.0386 253.918 101.923 256.474 110.717Z" fill="#BCD3CC" />
            <path d="M278.459 106.5C278.459 106.5 277.944 110.876 278.974 111.648C280.003 112.42 281.547 111.905 281.033 109.846C280.518 107.787 278.459 106.5 278.459 106.5Z" fill="#264948" />
            <path d="M272.006 106.5C272.006 106.5 272.521 110.876 271.491 111.648C270.462 112.42 268.918 111.905 269.432 109.846C269.947 107.787 272.006 106.5 272.006 106.5Z" fill="#264948" />
            <path d="M275.207 96.4322C275.207 96.4322 274.612 96.9278 274.549 96.4322C274.504 96.068 274.551 93.1535 274.551 93.1535C274.551 92.3338 273.864 90.826 273.031 90.8745C270.995 90.9928 271.332 89.6577 271.69 89.0992C272.427 87.9494 276.073 89.7443 275.888 92.6723C275.702 95.6002 275.207 96.4322 275.207 96.4322Z" fill="#264948" />
            <path d="M315.984 88C310.713 88 306.257 89.8483 302.554 93.5763C298.851 97.3042 297 101.753 297 107.016C297 112.279 298.851 116.727 302.554 120.424C306.257 124.12 310.744 126 315.984 126C321.225 126 325.712 124.152 329.415 120.424C333.117 116.727 335 112.247 335 107.016C335 101.784 333.149 97.2729 329.415 93.5763C325.712 89.8796 321.225 88 315.984 88ZM327.092 95.9258C330.168 98.9959 331.705 102.692 331.705 107.016C331.705 111.339 330.168 115.035 327.092 118.074C324.017 121.113 320.315 122.648 315.984 122.648C311.654 122.648 307.951 121.113 304.876 118.074C301.801 115.035 300.263 111.339 300.263 107.016C300.263 102.692 301.801 98.9959 304.876 95.9258C307.951 92.8557 311.654 91.3207 315.984 91.3207C320.315 91.3207 324.017 92.8557 327.092 95.9258Z" fill="#BCD3CC" />
            <rect width="168" height="130.226" transform="translate(172 130)" fill="white" />
            <path d="M190.984 134C185.713 134 181.257 135.848 177.554 139.576C173.851 143.304 172 147.753 172 153.016C172 158.279 173.851 162.727 177.554 166.424C181.257 170.12 185.744 172 190.984 172C196.225 172 200.712 170.152 204.415 166.424C208.117 162.727 210 158.247 210 153.016C210 147.784 208.149 143.273 204.415 139.576C200.712 135.88 196.225 134 190.984 134ZM202.092 141.926C205.168 144.996 206.705 148.692 206.705 153.016C206.705 157.339 205.168 161.035 202.092 164.074C199.017 167.113 195.315 168.648 190.984 168.648C186.654 168.648 182.951 167.113 179.876 164.074C176.801 161.035 175.263 157.339 175.263 153.016C175.263 148.692 176.801 144.996 179.876 141.926C182.951 138.856 186.654 137.321 190.984 137.321C195.315 137.321 199.017 138.856 202.092 141.926Z" fill="#BCD3CC" />
            <path d="M232.984 134C227.713 134 223.257 135.848 219.554 139.576C215.851 143.304 214 147.753 214 153.016C214 158.279 215.851 162.727 219.554 166.424C223.257 170.12 227.744 172 232.984 172C238.225 172 242.712 170.152 246.415 166.424C250.117 162.727 252 158.247 252 153.016C252 147.784 250.149 143.273 246.415 139.576C242.712 135.88 238.225 134 232.984 134ZM244.092 141.926C247.168 144.996 248.705 148.692 248.705 153.016C248.705 157.339 247.168 161.035 244.092 164.074C241.017 167.113 237.315 168.648 232.984 168.648C228.654 168.648 224.951 167.113 221.876 164.074C218.801 161.035 217.263 157.339 217.263 153.016C217.263 148.692 218.801 144.996 221.876 141.926C224.951 138.856 228.654 137.321 232.984 137.321C237.315 137.321 241.017 138.856 244.092 141.926Z" fill="#BCD3CC" />
            <path d="M274.984 134C269.713 134 265.257 135.848 261.554 139.576C257.851 143.304 256 147.753 256 153.016C256 158.279 257.851 162.727 261.554 166.424C265.257 170.12 269.744 172 274.984 172C280.225 172 284.712 170.152 288.415 166.424C292.117 162.727 294 158.247 294 153.016C294 147.784 292.149 143.273 288.415 139.576C284.712 135.88 280.225 134 274.984 134ZM286.092 141.926C289.168 144.996 290.705 148.692 290.705 153.016C290.705 157.339 289.168 161.035 286.092 164.074C283.017 167.113 279.315 168.648 274.984 168.648C270.654 168.648 266.951 167.113 263.876 164.074C260.801 161.035 259.263 157.339 259.263 153.016C259.263 148.692 260.801 144.996 263.876 141.926C266.951 138.856 270.654 137.321 274.984 137.321C279.315 137.321 283.017 138.856 286.092 141.926Z" fill="#BCD3CC" />
            <path d="M329.64 146.931C324.168 141.337 307.41 133.931 303.747 146.931C300.085 159.931 294.555 163.724 300.886 170.196C307.218 176.668 347.305 164.986 329.64 146.931Z" fill="#FFBCB8" />
            <path d="M327.085 142.732C327.298 139.446 324.655 137.486 323.701 136.89C323.543 136.791 323.332 136.888 323.316 137.068C323.271 137.542 323.241 138.452 323.441 139.723C323.497 140.078 323.311 140.174 323.029 139.937C321.857 138.952 319 136.895 316.743 138.079C316.609 138.149 316.567 138.314 316.658 138.432C317.014 138.896 318.118 140.082 321.089 141.825C321.635 142.145 321.562 142.345 320.926 142.266C319.224 142.054 316.252 141.906 314.272 143.049C314.092 143.153 314.116 143.409 314.313 143.479C315.912 144.05 321.331 145.912 323.469 145.771C324.527 145.701 323.264 149.09 322.774 150.316C322.7 150.5 322.877 150.686 323.075 150.632C324.326 150.293 327.599 149.205 327.619 147.109C327.621 146.896 327.883 146.785 328.043 146.934L333.559 152.05L333.559 152.05C333.56 152.046 334.605 147.735 330.179 145.627C329.107 145.117 332.673 145.127 333.846 145.144C334.032 145.147 334.157 144.96 334.074 144.8C333.09 142.895 328.527 142.941 327.343 142.986C327.195 142.992 327.075 142.873 327.085 142.732Z" fill="#264948" />
            <path d="M322.664 160.605C322.664 160.605 321.399 161.456 321.422 161.895C321.446 162.334 321.921 162.616 322.35 162.02C322.778 161.425 322.664 160.605 322.664 160.605Z" fill="#264948" />
            <path d="M317.523 164.433C317.523 164.433 316.258 165.284 316.281 165.723C316.304 166.162 316.779 166.444 317.208 165.848C317.637 165.253 317.523 164.433 317.523 164.433Z" fill="#264948" />
            <path d="M328.923 157.783C328.923 157.783 327.658 158.634 327.681 159.073C327.704 159.512 328.179 159.794 328.608 159.198C329.037 158.603 328.923 157.783 328.923 157.783Z" fill="#264948" />
            <path d="M303.273 162.533C303.273 162.533 302.008 163.384 302.031 163.823C302.054 164.262 302.529 164.544 302.958 163.948C303.387 163.353 303.273 162.533 303.273 162.533Z" fill="#264948" />
            <path d="M325.123 154.933C325.123 154.933 323.858 155.784 323.881 156.223C323.904 156.662 324.379 156.944 324.808 156.348C325.237 155.753 325.123 154.933 325.123 154.933Z" fill="#264948" />
            <path d="M321.323 152.083C321.323 152.083 320.058 152.934 320.081 153.373C320.104 153.812 320.579 154.094 321.008 153.498C321.437 152.903 321.323 152.083 321.323 152.083Z" fill="#264948" />
            <path d="M316.573 149.233C316.573 149.233 315.308 150.084 315.331 150.523C315.354 150.962 315.829 151.244 316.258 150.649C316.687 150.053 316.573 149.233 316.573 149.233Z" fill="#264948" />
            <path d="M318.473 157.783C318.473 157.783 317.208 158.634 317.231 159.073C317.254 159.512 317.729 159.794 318.158 159.198C318.587 158.603 318.473 157.783 318.473 157.783Z" fill="#264948" />
            <path d="M308.973 158.733C308.973 158.733 307.708 159.584 307.731 160.023C307.754 160.462 308.229 160.744 308.658 160.149C309.087 159.553 308.973 158.733 308.973 158.733Z" fill="#264948" />
            <path d="M313.723 161.583C313.723 161.583 312.458 162.434 312.481 162.873C312.504 163.312 312.979 163.594 313.408 162.998C313.837 162.403 313.723 161.583 313.723 161.583Z" fill="#264948" />
            <path d="M307.073 164.433C307.073 164.433 305.808 165.284 305.831 165.723C305.854 166.162 306.329 166.444 306.758 165.848C307.187 165.253 307.073 164.433 307.073 164.433Z" fill="#264948" />
            <path d="M310.873 166.333C310.873 166.333 309.608 167.184 309.631 167.623C309.654 168.062 310.129 168.344 310.558 167.748C310.987 167.153 310.873 166.333 310.873 166.333Z" fill="#264948" />
            <path d="M311.823 146.383C311.823 146.383 310.558 147.234 310.581 147.673C310.604 148.112 311.079 148.394 311.508 147.798C311.937 147.203 311.823 146.383 311.823 146.383Z" fill="#264948" />
            <path d="M308.973 152.083C308.973 152.083 307.708 152.934 307.731 153.373C307.754 153.812 308.229 154.094 308.658 153.498C309.087 152.903 308.973 152.083 308.973 152.083Z" fill="#264948" />
            <path d="M305.173 155.883C305.173 155.883 303.908 156.734 303.931 157.173C303.954 157.612 304.429 157.894 304.858 157.298C305.287 156.703 305.173 155.883 305.173 155.883Z" fill="#264948" />
            <path d="M313.723 154.933C313.723 154.933 312.458 155.784 312.481 156.223C312.504 156.662 312.979 156.944 313.408 156.348C313.837 155.753 313.723 154.933 313.723 154.933Z" fill="#264948" />
            <path d="M181.041 184.33C178.781 185.492 177.207 186.942 176.625 188.787C176.526 189.104 176.588 189.453 176.788 189.72C178.24 191.672 180.473 192.847 183.063 192.515C189.376 191.704 190.194 188.107 189.647 183.873C189.15 180.036 186.924 177.089 182.519 177.081C178.13 177.074 174.575 180.838 175.066 185.255C175.075 185.328 175.093 185.472 175.093 185.472C175.181 186.117 175.907 186.44 176.44 186.08C179.25 184.172 182.224 183.724 181.041 184.33Z" fill="#264948" />
            <path d="M180.877 186.447C181.808 189.398 183.216 191.582 185.291 192.652C185.648 192.834 186.074 192.831 186.431 192.644C189.037 191.291 190.876 188.844 190.996 185.663C191.287 177.91 187.18 176.204 182.045 176.01C177.391 175.836 173.45 177.918 172.566 183.212C171.685 188.487 175.447 193.517 180.788 193.816C180.877 193.82 181.052 193.827 181.052 193.827C181.835 193.851 182.363 193.044 182.041 192.33C180.334 188.568 180.392 184.903 180.877 186.447Z" fill="#BCD3CC" />
            <rect width="168" height="130" transform="translate(340)" fill="white" />
            <path d="M363.692 18.8811C363.536 18.9934 363.403 19.1355 363.301 19.2993C363.199 19.4631 363.131 19.6454 363.1 19.8356C363.069 20.0259 363.075 20.2204 363.119 20.4081C363.163 20.5958 363.244 20.7729 363.357 20.9294L368.608 28.2276L362.059 25.2809C361.317 24.9491 360.434 25.2456 360.086 25.9741C359.921 26.3315 359.905 26.74 360.04 27.1097C360.176 27.4794 360.453 27.7801 360.81 27.9458L367.405 30.9208L358.46 31.8273C358.074 31.8676 357.719 32.059 357.474 32.3598C357.228 32.6605 357.112 33.0461 357.149 33.4325C357.19 33.8187 357.381 34.1732 357.682 34.4188C357.982 34.6644 358.368 34.7811 358.754 34.7435L363.801 34.2348C365.916 34.021 368.071 34.367 370.007 35.2387L372.994 36.5803C373.985 37.0227 375.165 36.5918 375.622 35.5854L377.559 31.2818C378.001 30.2912 377.57 29.1111 376.564 28.6542L373.576 27.3125C371.63 26.443 369.952 25.0704 368.713 23.3362L365.741 19.2166C365.272 18.5541 364.354 18.3969 363.692 18.8811Z" fill="#BCD3CC" />
            <path d="M345.898 13.1637C348.565 11.2588 352.002 4.49031 357.463 7.0602C362.924 9.63009 367.312 3.02225 369.615 9.85816C371.919 16.6973 378.022 19.5884 373.525 21.1946C369.027 22.8008 361.639 24.7282 362.281 29.5467C362.924 34.3653 362.236 39.5276 356.798 39.5147C351.359 39.5019 346.04 38.959 344.202 32.9679C342.365 26.9769 339.891 29.3733 340.004 23.0353C340.116 16.6973 343.65 14.7699 345.898 13.1637Z" fill="#264948" />
            <path d="M400.984 4C395.713 4 391.257 5.84831 387.554 9.57626C383.851 13.3042 382 17.7527 382 23.0157C382 28.2786 383.851 32.7271 387.554 36.4237C391.257 40.1204 395.744 42 400.984 42C406.225 42 410.712 40.1517 414.415 36.4237C418.117 32.7271 420 28.2473 420 23.0157C420 17.784 418.149 13.2729 414.415 9.57626C410.712 5.87964 406.225 4 400.984 4ZM412.092 11.9258C415.168 14.9959 416.705 18.6925 416.705 23.0157C416.705 27.3388 415.168 31.0354 412.092 34.0742C409.017 37.1129 405.315 38.648 400.984 38.648C396.654 38.648 392.951 37.1129 389.876 34.0742C386.801 31.0354 385.263 27.3388 385.263 23.0157C385.263 18.6925 386.801 14.9959 389.876 11.9258C392.951 8.85573 396.654 7.32069 400.984 7.32069C405.315 7.32069 409.017 8.85573 412.092 11.9258Z" fill="#BCD3CC" />
            <path d="M442.984 4C437.713 4 433.257 5.84831 429.554 9.57626C425.851 13.3042 424 17.7527 424 23.0157C424 28.2786 425.851 32.7271 429.554 36.4237C433.257 40.1204 437.744 42 442.984 42C448.225 42 452.712 40.1517 456.415 36.4237C460.117 32.7271 462 28.2473 462 23.0157C462 17.784 460.149 13.2729 456.415 9.57626C452.712 5.87964 448.225 4 442.984 4ZM454.092 11.9258C457.168 14.9959 458.705 18.6925 458.705 23.0157C458.705 27.3388 457.168 31.0354 454.092 34.0742C451.017 37.1129 447.315 38.648 442.984 38.648C438.654 38.648 434.951 37.1129 431.876 34.0742C428.801 31.0354 427.263 27.3388 427.263 23.0157C427.263 18.6925 428.801 14.9959 431.876 11.9258C434.951 8.85573 438.654 7.32069 442.984 7.32069C447.315 7.32069 451.017 8.85573 454.092 11.9258Z" fill="#BCD3CC" />
            <path d="M484.984 4C479.713 4 475.257 5.84831 471.554 9.57626C467.851 13.3042 466 17.7527 466 23.0157C466 28.2786 467.851 32.7271 471.554 36.4237C475.257 40.1204 479.744 42 484.984 42C490.225 42 494.712 40.1517 498.415 36.4237C502.117 32.7271 504 28.2473 504 23.0157C504 17.784 502.149 13.2729 498.415 9.57626C494.712 5.87964 490.225 4 484.984 4ZM496.092 11.9258C499.168 14.9959 500.705 18.6925 500.705 23.0157C500.705 27.3388 499.168 31.0354 496.092 34.0742C493.017 37.1129 489.315 38.648 484.984 38.648C480.654 38.648 476.951 37.1129 473.876 34.0742C470.801 31.0354 469.263 27.3388 469.263 23.0157C469.263 18.6925 470.801 14.9959 473.876 11.9258C476.951 8.85573 480.654 7.32069 484.984 7.32069C489.315 7.32069 493.017 8.85573 496.092 11.9258Z" fill="#BCD3CC" />
            <path d="M358.984 46C353.713 46 349.257 47.8483 345.554 51.5763C341.851 55.3042 340 59.7527 340 65.0157C340 70.2786 341.851 74.7271 345.554 78.4237C349.257 82.1204 353.744 84 358.984 84C364.225 84 368.712 82.1517 372.415 78.4237C376.117 74.7271 378 70.2473 378 65.0157C378 59.784 376.149 55.2729 372.415 51.5763C368.712 47.8796 364.225 46 358.984 46ZM370.092 53.9258C373.168 56.9959 374.705 60.6925 374.705 65.0157C374.705 69.3388 373.168 73.0354 370.092 76.0742C367.017 79.1129 363.315 80.648 358.984 80.648C354.654 80.648 350.951 79.1129 347.876 76.0742C344.801 73.0354 343.263 69.3388 343.263 65.0157C343.263 60.6925 344.801 56.9959 347.876 53.9258C350.951 50.8557 354.654 49.3207 358.984 49.3207C363.315 49.3207 367.017 50.8557 370.092 53.9258Z" fill="#BCD3CC" />
            <path d="M394.395 50.9851C407.256 47.2346 416.982 50.1959 419.322 60.1536C422.566 73.955 413.614 79.5433 404.286 80.2334C394.958 80.9235 388.784 81.3378 384.502 73.7816C380.223 66.2253 380.201 55.1256 394.395 50.9851Z" fill="#FFBCB8" />
            <path d="M396.989 55.7892C392.763 56.1377 392.673 55.5339 396.25 54.378C399.308 53.3912 399.54 52.9047 399.402 51.6695C399.235 50.2134 400.606 49.5923 400.801 51.3106C400.967 52.7667 401.592 53.2049 403.822 52.5321C406.239 51.8006 405.846 52.6114 403.988 53.9881C402.764 54.8955 403.011 55.5408 404.576 56.6345C407.411 58.6219 406.62 59.4086 403.859 57.9181C401.097 56.431 401.202 57.5006 399.973 59.2189C398.481 61.3029 397.341 61.396 398.465 58.6081C399.503 56.0273 399.276 55.5994 396.993 55.7892H396.989Z" fill="#264948" />
            <path d="M442.984 46C437.713 46 433.257 47.8483 429.554 51.5763C425.851 55.3042 424 59.7527 424 65.0157C424 70.2786 425.851 74.7271 429.554 78.4237C433.257 82.1204 437.744 84 442.984 84C448.225 84 452.712 82.1517 456.415 78.4237C460.117 74.7271 462 70.2473 462 65.0157C462 59.784 460.149 55.2729 456.415 51.5763C452.712 47.8796 448.225 46 442.984 46ZM454.092 53.9258C457.168 56.9959 458.705 60.6925 458.705 65.0157C458.705 69.3388 457.168 73.0354 454.092 76.0742C451.017 79.1129 447.315 80.648 442.984 80.648C438.654 80.648 434.951 79.1129 431.876 76.0742C428.801 73.0354 427.263 69.3388 427.263 65.0157C427.263 60.6925 428.801 56.9959 431.876 53.9258C434.951 50.8557 438.654 49.3207 442.984 49.3207C447.315 49.3207 451.017 50.8557 454.092 53.9258Z" fill="#BCD3CC" />
            <path d="M484.984 46C479.713 46 475.257 47.8483 471.554 51.5763C467.851 55.3042 466 59.7527 466 65.0157C466 70.2786 467.851 74.7271 471.554 78.4237C475.257 82.1204 479.744 84 484.984 84C490.225 84 494.712 82.1517 498.415 78.4237C502.117 74.7271 504 70.2473 504 65.0157C504 59.784 502.149 55.2729 498.415 51.5763C494.712 47.8796 490.225 46 484.984 46ZM496.092 53.9258C499.168 56.9959 500.705 60.6925 500.705 65.0157C500.705 69.3388 499.168 73.0354 496.092 76.0742C493.017 79.1129 489.315 80.648 484.984 80.648C480.654 80.648 476.951 79.1129 473.876 76.0742C470.801 73.0354 469.263 69.3388 469.263 65.0157C469.263 60.6925 470.801 56.9959 473.876 53.9258C476.951 50.8557 480.654 49.3207 484.984 49.3207C489.315 49.3207 493.017 50.8557 496.092 53.9258Z" fill="#BCD3CC" />
            <path d="M358.984 88C353.713 88 349.257 89.8483 345.554 93.5763C341.851 97.3042 340 101.753 340 107.016C340 112.279 341.851 116.727 345.554 120.424C349.257 124.12 353.744 126 358.984 126C364.225 126 368.712 124.152 372.415 120.424C376.117 116.727 378 112.247 378 107.016C378 101.784 376.149 97.2729 372.415 93.5763C368.712 89.8796 364.225 88 358.984 88ZM370.092 95.9258C373.168 98.9959 374.705 102.692 374.705 107.016C374.705 111.339 373.168 115.035 370.092 118.074C367.017 121.113 363.315 122.648 358.984 122.648C354.654 122.648 350.951 121.113 347.876 118.074C344.801 115.035 343.263 111.339 343.263 107.016C343.263 102.692 344.801 98.9959 347.876 95.9258C350.951 92.8557 354.654 91.3207 358.984 91.3207C363.315 91.3207 367.017 92.8557 370.092 95.9258Z" fill="#BCD3CC" />
            <path d="M400.984 88C395.713 88 391.257 89.8483 387.554 93.5763C383.851 97.3042 382 101.753 382 107.016C382 112.279 383.851 116.727 387.554 120.424C391.257 124.12 395.744 126 400.984 126C406.225 126 410.712 124.152 414.415 120.424C418.117 116.727 420 112.247 420 107.016C420 101.784 418.149 97.2729 414.415 93.5763C410.712 89.8796 406.225 88 400.984 88ZM412.092 95.9258C415.168 98.9959 416.705 102.692 416.705 107.016C416.705 111.339 415.168 115.035 412.092 118.074C409.017 121.113 405.315 122.648 400.984 122.648C396.654 122.648 392.951 121.113 389.876 118.074C386.801 115.035 385.263 111.339 385.263 107.016C385.263 102.692 386.801 98.9959 389.876 95.9258C392.951 92.8557 396.654 91.3207 400.984 91.3207C405.315 91.3207 409.017 92.8557 412.092 95.9258Z" fill="#BCD3CC" />
            <path d="M424.474 110.717C427.983 122.779 436.225 126.951 440.764 125.067C442.268 124.443 443.019 124.443 444.273 125.067C446.11 125.982 456.728 125.117 460.312 111.133C463.896 97.1482 452.568 93.4545 447.418 95.0143C442.268 96.5742 443.107 96.4556 436.504 94.7024C430.238 93.0386 421.918 101.923 424.474 110.717Z" fill="#BCD3CC" />
            <path d="M446.459 106.5C446.459 106.5 445.944 110.876 446.974 111.648C448.003 112.42 449.547 111.905 449.033 109.846C448.518 107.787 446.459 106.5 446.459 106.5Z" fill="#264948" />
            <path d="M440.006 106.5C440.006 106.5 440.521 110.876 439.491 111.648C438.462 112.42 436.918 111.905 437.432 109.846C437.947 107.787 440.006 106.5 440.006 106.5Z" fill="#264948" />
            <path d="M443.207 96.4322C443.207 96.4322 442.612 96.9278 442.549 96.4322C442.504 96.068 442.551 93.1535 442.551 93.1535C442.551 92.3338 441.864 90.826 441.031 90.8745C438.995 90.9928 439.332 89.6577 439.69 89.0992C440.427 87.9494 444.073 89.7443 443.888 92.6723C443.702 95.6002 443.207 96.4322 443.207 96.4322Z" fill="#264948" />
            <path d="M483.984 88C478.713 88 474.257 89.8483 470.554 93.5763C466.851 97.3042 465 101.753 465 107.016C465 112.279 466.851 116.727 470.554 120.424C474.257 124.12 478.744 126 483.984 126C489.225 126 493.712 124.152 497.415 120.424C501.117 116.727 503 112.247 503 107.016C503 101.784 501.149 97.2729 497.415 93.5763C493.712 89.8796 489.225 88 483.984 88ZM495.092 95.9258C498.168 98.9959 499.705 102.692 499.705 107.016C499.705 111.339 498.168 115.035 495.092 118.074C492.017 121.113 488.315 122.648 483.984 122.648C479.654 122.648 475.951 121.113 472.876 118.074C469.801 115.035 468.263 111.339 468.263 107.016C468.263 102.692 469.801 98.9959 472.876 95.9258C475.951 92.8557 479.654 91.3207 483.984 91.3207C488.315 91.3207 492.017 92.8557 495.092 95.9258Z" fill="#BCD3CC" />
            <rect width="168" height="130.226" transform="translate(340 130)" fill="white" />
            <path d="M358.984 134C353.713 134 349.257 135.848 345.554 139.576C341.851 143.304 340 147.753 340 153.016C340 158.279 341.851 162.727 345.554 166.424C349.257 170.12 353.744 172 358.984 172C364.225 172 368.712 170.152 372.415 166.424C376.117 162.727 378 158.247 378 153.016C378 147.784 376.149 143.273 372.415 139.576C368.712 135.88 364.225 134 358.984 134ZM370.092 141.926C373.168 144.996 374.705 148.692 374.705 153.016C374.705 157.339 373.168 161.035 370.092 164.074C367.017 167.113 363.315 168.648 358.984 168.648C354.654 168.648 350.951 167.113 347.876 164.074C344.801 161.035 343.263 157.339 343.263 153.016C343.263 148.692 344.801 144.996 347.876 141.926C350.951 138.856 354.654 137.321 358.984 137.321C363.315 137.321 367.017 138.856 370.092 141.926Z" fill="#BCD3CC" />
            <path d="M400.984 134C395.713 134 391.257 135.848 387.554 139.576C383.851 143.304 382 147.753 382 153.016C382 158.279 383.851 162.727 387.554 166.424C391.257 170.12 395.744 172 400.984 172C406.225 172 410.712 170.152 414.415 166.424C418.117 162.727 420 158.247 420 153.016C420 147.784 418.149 143.273 414.415 139.576C410.712 135.88 406.225 134 400.984 134ZM412.092 141.926C415.168 144.996 416.705 148.692 416.705 153.016C416.705 157.339 415.168 161.035 412.092 164.074C409.017 167.113 405.315 168.648 400.984 168.648C396.654 168.648 392.951 167.113 389.876 164.074C386.801 161.035 385.263 157.339 385.263 153.016C385.263 148.692 386.801 144.996 389.876 141.926C392.951 138.856 396.654 137.321 400.984 137.321C405.315 137.321 409.017 138.856 412.092 141.926Z" fill="#BCD3CC" />
            <path d="M442.984 134C437.713 134 433.257 135.848 429.554 139.576C425.851 143.304 424 147.753 424 153.016C424 158.279 425.851 162.727 429.554 166.424C433.257 170.12 437.744 172 442.984 172C448.225 172 452.712 170.152 456.415 166.424C460.117 162.727 462 158.247 462 153.016C462 147.784 460.149 143.273 456.415 139.576C452.712 135.88 448.225 134 442.984 134ZM454.092 141.926C457.168 144.996 458.705 148.692 458.705 153.016C458.705 157.339 457.168 161.035 454.092 164.074C451.017 167.113 447.315 168.648 442.984 168.648C438.654 168.648 434.951 167.113 431.876 164.074C428.801 161.035 427.263 157.339 427.263 153.016C427.263 148.692 428.801 144.996 431.876 141.926C434.951 138.856 438.654 137.321 442.984 137.321C447.315 137.321 451.017 138.856 454.092 141.926Z" fill="#BCD3CC" />
            <path d="M497.64 146.931C492.168 141.337 475.41 133.931 471.747 146.931C468.085 159.931 462.555 163.724 468.886 170.196C475.218 176.668 515.305 164.986 497.64 146.931Z" fill="#FFBCB8" />
            <path d="M495.085 142.732C495.298 139.446 492.655 137.486 491.701 136.89C491.543 136.791 491.332 136.888 491.316 137.068C491.271 137.542 491.241 138.452 491.441 139.723C491.497 140.078 491.311 140.174 491.029 139.937C489.857 138.952 487 136.895 484.743 138.079C484.609 138.149 484.567 138.314 484.658 138.432C485.014 138.896 486.118 140.082 489.089 141.825C489.635 142.145 489.562 142.345 488.926 142.266C487.224 142.054 484.252 141.906 482.272 143.049C482.092 143.153 482.116 143.409 482.313 143.479C483.912 144.05 489.331 145.912 491.469 145.771C492.527 145.701 491.264 149.09 490.774 150.316C490.7 150.5 490.877 150.686 491.075 150.632C492.326 150.293 495.599 149.205 495.619 147.109C495.621 146.896 495.883 146.785 496.043 146.934L501.559 152.05L501.559 152.05C501.56 152.046 502.605 147.735 498.179 145.627C497.107 145.117 500.673 145.127 501.846 145.144C502.032 145.147 502.157 144.96 502.074 144.8C501.09 142.895 496.527 142.941 495.343 142.986C495.195 142.992 495.075 142.873 495.085 142.732Z" fill="#264948" />
            <path d="M490.664 160.605C490.664 160.605 489.399 161.456 489.422 161.895C489.446 162.334 489.921 162.616 490.35 162.02C490.778 161.425 490.664 160.605 490.664 160.605Z" fill="#264948" />
            <path d="M485.523 164.433C485.523 164.433 484.258 165.284 484.281 165.723C484.304 166.162 484.779 166.444 485.208 165.848C485.637 165.253 485.523 164.433 485.523 164.433Z" fill="#264948" />
            <path d="M496.923 157.783C496.923 157.783 495.658 158.634 495.681 159.073C495.704 159.512 496.179 159.794 496.608 159.198C497.037 158.603 496.923 157.783 496.923 157.783Z" fill="#264948" />
            <path d="M471.273 162.533C471.273 162.533 470.008 163.384 470.031 163.823C470.054 164.262 470.529 164.544 470.958 163.948C471.387 163.353 471.273 162.533 471.273 162.533Z" fill="#264948" />
            <path d="M493.123 154.933C493.123 154.933 491.858 155.784 491.881 156.223C491.904 156.662 492.379 156.944 492.808 156.348C493.237 155.753 493.123 154.933 493.123 154.933Z" fill="#264948" />
            <path d="M489.323 152.083C489.323 152.083 488.058 152.934 488.081 153.373C488.104 153.812 488.579 154.094 489.008 153.498C489.437 152.903 489.323 152.083 489.323 152.083Z" fill="#264948" />
            <path d="M484.573 149.233C484.573 149.233 483.308 150.084 483.331 150.523C483.354 150.962 483.829 151.244 484.258 150.649C484.687 150.053 484.573 149.233 484.573 149.233Z" fill="#264948" />
            <path d="M486.473 157.783C486.473 157.783 485.208 158.634 485.231 159.073C485.254 159.512 485.729 159.794 486.158 159.198C486.587 158.603 486.473 157.783 486.473 157.783Z" fill="#264948" />
            <path d="M476.973 158.733C476.973 158.733 475.708 159.584 475.731 160.023C475.754 160.462 476.229 160.744 476.658 160.149C477.087 159.553 476.973 158.733 476.973 158.733Z" fill="#264948" />
            <path d="M481.723 161.583C481.723 161.583 480.458 162.434 480.481 162.873C480.504 163.312 480.979 163.594 481.408 162.998C481.837 162.403 481.723 161.583 481.723 161.583Z" fill="#264948" />
            <path d="M475.073 164.433C475.073 164.433 473.808 165.284 473.831 165.723C473.854 166.162 474.329 166.444 474.758 165.848C475.187 165.253 475.073 164.433 475.073 164.433Z" fill="#264948" />
            <path d="M478.873 166.333C478.873 166.333 477.608 167.184 477.631 167.623C477.654 168.062 478.129 168.344 478.558 167.748C478.987 167.153 478.873 166.333 478.873 166.333Z" fill="#264948" />
            <path d="M479.823 146.383C479.823 146.383 478.558 147.234 478.581 147.673C478.604 148.112 479.079 148.394 479.508 147.798C479.937 147.203 479.823 146.383 479.823 146.383Z" fill="#264948" />
            <path d="M476.973 152.083C476.973 152.083 475.708 152.934 475.731 153.373C475.754 153.812 476.229 154.094 476.658 153.498C477.087 152.903 476.973 152.083 476.973 152.083Z" fill="#264948" />
            <path d="M473.173 155.883C473.173 155.883 471.908 156.734 471.931 157.173C471.954 157.612 472.429 157.894 472.858 157.298C473.287 156.703 473.173 155.883 473.173 155.883Z" fill="#264948" />
            <path d="M481.723 154.933C481.723 154.933 480.458 155.784 480.481 156.223C480.504 156.662 480.979 156.944 481.408 156.348C481.837 155.753 481.723 154.933 481.723 154.933Z" fill="#264948" />
            <path d="M349.041 184.33C346.781 185.492 345.207 186.942 344.625 188.787C344.526 189.104 344.588 189.453 344.788 189.72C346.24 191.672 348.473 192.847 351.063 192.515C357.376 191.704 358.194 188.107 357.647 183.873C357.15 180.036 354.924 177.089 350.519 177.081C346.13 177.074 342.575 180.838 343.066 185.255C343.075 185.328 343.093 185.472 343.093 185.472C343.181 186.117 343.907 186.44 344.44 186.08C347.25 184.172 350.224 183.724 349.041 184.33Z" fill="#264948" />
            <path d="M348.877 186.447C349.808 189.398 351.216 191.582 353.291 192.652C353.648 192.834 354.074 192.831 354.431 192.644C357.037 191.291 358.876 188.844 358.996 185.663C359.287 177.91 355.18 176.204 350.045 176.01C345.391 175.836 341.45 177.918 340.566 183.212C339.685 188.487 343.447 193.517 348.788 193.816C348.877 193.82 349.052 193.827 349.052 193.827C349.835 193.851 350.363 193.044 350.041 192.33C348.334 188.568 348.392 184.903 348.877 186.447Z" fill="#BCD3CC" />
            <rect width="168" height="130" transform="translate(508)" fill="white" />
            <path d="M531.692 18.8811C531.536 18.9934 531.403 19.1355 531.301 19.2993C531.199 19.4631 531.131 19.6454 531.1 19.8356C531.069 20.0259 531.075 20.2204 531.119 20.4081C531.163 20.5958 531.244 20.7729 531.357 20.9294L536.608 28.2276L530.059 25.2809C529.317 24.9491 528.434 25.2456 528.086 25.9741C527.921 26.3315 527.905 26.74 528.04 27.1097C528.176 27.4794 528.453 27.7801 528.81 27.9458L535.405 30.9208L526.46 31.8273C526.074 31.8676 525.719 32.059 525.474 32.3598C525.228 32.6605 525.112 33.0461 525.149 33.4325C525.19 33.8187 525.381 34.1732 525.682 34.4188C525.982 34.6644 526.368 34.7811 526.754 34.7435L531.801 34.2348C533.916 34.021 536.071 34.367 538.007 35.2387L540.994 36.5803C541.985 37.0227 543.165 36.5918 543.622 35.5854L545.559 31.2818C546.001 30.2912 545.57 29.1111 544.564 28.6542L541.576 27.3125C539.63 26.443 537.952 25.0704 536.713 23.3362L533.741 19.2166C533.272 18.5541 532.354 18.3969 531.692 18.8811Z" fill="#BCD3CC" />
            <path d="M513.898 13.1637C516.565 11.2588 520.002 4.49031 525.463 7.0602C530.924 9.63009 535.312 3.02225 537.615 9.85816C539.919 16.6973 546.022 19.5884 541.525 21.1946C537.027 22.8008 529.639 24.7282 530.281 29.5467C530.924 34.3653 530.236 39.5276 524.798 39.5147C519.359 39.5019 514.04 38.959 512.202 32.9679C510.365 26.9769 507.891 29.3733 508.004 23.0353C508.116 16.6973 511.65 14.7699 513.898 13.1637Z" fill="#264948" />
            <path d="M568.984 4C563.713 4 559.257 5.84831 555.554 9.57626C551.851 13.3042 550 17.7527 550 23.0157C550 28.2786 551.851 32.7271 555.554 36.4237C559.257 40.1204 563.744 42 568.984 42C574.225 42 578.712 40.1517 582.415 36.4237C586.117 32.7271 588 28.2473 588 23.0157C588 17.784 586.149 13.2729 582.415 9.57626C578.712 5.87964 574.225 4 568.984 4ZM580.092 11.9258C583.168 14.9959 584.705 18.6925 584.705 23.0157C584.705 27.3388 583.168 31.0354 580.092 34.0742C577.017 37.1129 573.315 38.648 568.984 38.648C564.654 38.648 560.951 37.1129 557.876 34.0742C554.801 31.0354 553.263 27.3388 553.263 23.0157C553.263 18.6925 554.801 14.9959 557.876 11.9258C560.951 8.85573 564.654 7.32069 568.984 7.32069C573.315 7.32069 577.017 8.85573 580.092 11.9258Z" fill="#BCD3CC" />
            <path d="M610.984 4C605.713 4 601.257 5.84831 597.554 9.57626C593.851 13.3042 592 17.7527 592 23.0157C592 28.2786 593.851 32.7271 597.554 36.4237C601.257 40.1204 605.744 42 610.984 42C616.225 42 620.712 40.1517 624.415 36.4237C628.117 32.7271 630 28.2473 630 23.0157C630 17.784 628.149 13.2729 624.415 9.57626C620.712 5.87964 616.225 4 610.984 4ZM622.092 11.9258C625.168 14.9959 626.705 18.6925 626.705 23.0157C626.705 27.3388 625.168 31.0354 622.092 34.0742C619.017 37.1129 615.315 38.648 610.984 38.648C606.654 38.648 602.951 37.1129 599.876 34.0742C596.801 31.0354 595.263 27.3388 595.263 23.0157C595.263 18.6925 596.801 14.9959 599.876 11.9258C602.951 8.85573 606.654 7.32069 610.984 7.32069C615.315 7.32069 619.017 8.85573 622.092 11.9258Z" fill="#BCD3CC" />
            <path d="M652.984 4C647.713 4 643.257 5.84831 639.554 9.57626C635.851 13.3042 634 17.7527 634 23.0157C634 28.2786 635.851 32.7271 639.554 36.4237C643.257 40.1204 647.744 42 652.984 42C658.225 42 662.712 40.1517 666.415 36.4237C670.117 32.7271 672 28.2473 672 23.0157C672 17.784 670.149 13.2729 666.415 9.57626C662.712 5.87964 658.225 4 652.984 4ZM664.092 11.9258C667.168 14.9959 668.705 18.6925 668.705 23.0157C668.705 27.3388 667.168 31.0354 664.092 34.0742C661.017 37.1129 657.315 38.648 652.984 38.648C648.654 38.648 644.951 37.1129 641.876 34.0742C638.801 31.0354 637.263 27.3388 637.263 23.0157C637.263 18.6925 638.801 14.9959 641.876 11.9258C644.951 8.85573 648.654 7.32069 652.984 7.32069C657.315 7.32069 661.017 8.85573 664.092 11.9258Z" fill="#BCD3CC" />
            <path d="M526.984 46C521.713 46 517.257 47.8483 513.554 51.5763C509.851 55.3042 508 59.7527 508 65.0157C508 70.2786 509.851 74.7271 513.554 78.4237C517.257 82.1204 521.744 84 526.984 84C532.225 84 536.712 82.1517 540.415 78.4237C544.117 74.7271 546 70.2473 546 65.0157C546 59.784 544.149 55.2729 540.415 51.5763C536.712 47.8796 532.225 46 526.984 46ZM538.092 53.9258C541.168 56.9959 542.705 60.6925 542.705 65.0157C542.705 69.3388 541.168 73.0354 538.092 76.0742C535.017 79.1129 531.315 80.648 526.984 80.648C522.654 80.648 518.951 79.1129 515.876 76.0742C512.801 73.0354 511.263 69.3388 511.263 65.0157C511.263 60.6925 512.801 56.9959 515.876 53.9258C518.951 50.8557 522.654 49.3207 526.984 49.3207C531.315 49.3207 535.017 50.8557 538.092 53.9258Z" fill="#BCD3CC" />
            <path d="M562.395 50.9851C575.256 47.2346 584.982 50.1959 587.322 60.1536C590.566 73.955 581.614 79.5433 572.286 80.2334C562.958 80.9235 556.784 81.3378 552.502 73.7816C548.223 66.2253 548.201 55.1256 562.395 50.9851Z" fill="#FFBCB8" />
            <path d="M564.989 55.7892C560.763 56.1377 560.673 55.5339 564.25 54.378C567.308 53.3912 567.54 52.9047 567.402 51.6695C567.235 50.2134 568.606 49.5923 568.801 51.3106C568.967 52.7667 569.592 53.2049 571.822 52.5321C574.239 51.8006 573.846 52.6114 571.988 53.9881C570.764 54.8955 571.011 55.5408 572.576 56.6345C575.411 58.6219 574.62 59.4086 571.859 57.9181C569.097 56.431 569.202 57.5006 567.973 59.2189C566.481 61.3029 565.341 61.396 566.465 58.6081C567.503 56.0273 567.276 55.5994 564.993 55.7892H564.989Z" fill="#264948" />
            <path d="M610.984 46C605.713 46 601.257 47.8483 597.554 51.5763C593.851 55.3042 592 59.7527 592 65.0157C592 70.2786 593.851 74.7271 597.554 78.4237C601.257 82.1204 605.744 84 610.984 84C616.225 84 620.712 82.1517 624.415 78.4237C628.117 74.7271 630 70.2473 630 65.0157C630 59.784 628.149 55.2729 624.415 51.5763C620.712 47.8796 616.225 46 610.984 46ZM622.092 53.9258C625.168 56.9959 626.705 60.6925 626.705 65.0157C626.705 69.3388 625.168 73.0354 622.092 76.0742C619.017 79.1129 615.315 80.648 610.984 80.648C606.654 80.648 602.951 79.1129 599.876 76.0742C596.801 73.0354 595.263 69.3388 595.263 65.0157C595.263 60.6925 596.801 56.9959 599.876 53.9258C602.951 50.8557 606.654 49.3207 610.984 49.3207C615.315 49.3207 619.017 50.8557 622.092 53.9258Z" fill="#BCD3CC" />
            <path d="M652.984 46C647.713 46 643.257 47.8483 639.554 51.5763C635.851 55.3042 634 59.7527 634 65.0157C634 70.2786 635.851 74.7271 639.554 78.4237C643.257 82.1204 647.744 84 652.984 84C658.225 84 662.712 82.1517 666.415 78.4237C670.117 74.7271 672 70.2473 672 65.0157C672 59.784 670.149 55.2729 666.415 51.5763C662.712 47.8796 658.225 46 652.984 46ZM664.092 53.9258C667.168 56.9959 668.705 60.6925 668.705 65.0157C668.705 69.3388 667.168 73.0354 664.092 76.0742C661.017 79.1129 657.315 80.648 652.984 80.648C648.654 80.648 644.951 79.1129 641.876 76.0742C638.801 73.0354 637.263 69.3388 637.263 65.0157C637.263 60.6925 638.801 56.9959 641.876 53.9258C644.951 50.8557 648.654 49.3207 652.984 49.3207C657.315 49.3207 661.017 50.8557 664.092 53.9258Z" fill="#BCD3CC" />
            <path d="M526.984 88C521.713 88 517.257 89.8483 513.554 93.5763C509.851 97.3042 508 101.753 508 107.016C508 112.279 509.851 116.727 513.554 120.424C517.257 124.12 521.744 126 526.984 126C532.225 126 536.712 124.152 540.415 120.424C544.117 116.727 546 112.247 546 107.016C546 101.784 544.149 97.2729 540.415 93.5763C536.712 89.8796 532.225 88 526.984 88ZM538.092 95.9258C541.168 98.9959 542.705 102.692 542.705 107.016C542.705 111.339 541.168 115.035 538.092 118.074C535.017 121.113 531.315 122.648 526.984 122.648C522.654 122.648 518.951 121.113 515.876 118.074C512.801 115.035 511.263 111.339 511.263 107.016C511.263 102.692 512.801 98.9959 515.876 95.9258C518.951 92.8557 522.654 91.3207 526.984 91.3207C531.315 91.3207 535.017 92.8557 538.092 95.9258Z" fill="#BCD3CC" />
            <path d="M568.984 88C563.713 88 559.257 89.8483 555.554 93.5763C551.851 97.3042 550 101.753 550 107.016C550 112.279 551.851 116.727 555.554 120.424C559.257 124.12 563.744 126 568.984 126C574.225 126 578.712 124.152 582.415 120.424C586.117 116.727 588 112.247 588 107.016C588 101.784 586.149 97.2729 582.415 93.5763C578.712 89.8796 574.225 88 568.984 88ZM580.092 95.9258C583.168 98.9959 584.705 102.692 584.705 107.016C584.705 111.339 583.168 115.035 580.092 118.074C577.017 121.113 573.315 122.648 568.984 122.648C564.654 122.648 560.951 121.113 557.876 118.074C554.801 115.035 553.263 111.339 553.263 107.016C553.263 102.692 554.801 98.9959 557.876 95.9258C560.951 92.8557 564.654 91.3207 568.984 91.3207C573.315 91.3207 577.017 92.8557 580.092 95.9258Z" fill="#BCD3CC" />
            <path d="M592.474 110.717C595.983 122.779 604.225 126.951 608.764 125.067C610.268 124.443 611.019 124.443 612.273 125.067C614.11 125.982 624.728 125.117 628.312 111.133C631.896 97.1482 620.568 93.4545 615.418 95.0143C610.268 96.5742 611.107 96.4556 604.504 94.7024C598.238 93.0386 589.918 101.923 592.474 110.717Z" fill="#BCD3CC" />
            <path d="M614.459 106.5C614.459 106.5 613.944 110.876 614.974 111.648C616.003 112.42 617.547 111.905 617.033 109.846C616.518 107.787 614.459 106.5 614.459 106.5Z" fill="#264948" />
            <path d="M608.006 106.5C608.006 106.5 608.521 110.876 607.491 111.648C606.462 112.42 604.918 111.905 605.432 109.846C605.947 107.787 608.006 106.5 608.006 106.5Z" fill="#264948" />
            <path d="M611.207 96.4322C611.207 96.4322 610.612 96.9278 610.549 96.4322C610.504 96.068 610.551 93.1535 610.551 93.1535C610.551 92.3338 609.864 90.826 609.031 90.8745C606.995 90.9928 607.332 89.6577 607.69 89.0992C608.427 87.9494 612.073 89.7443 611.888 92.6723C611.702 95.6002 611.207 96.4322 611.207 96.4322Z" fill="#264948" />
            <path d="M651.984 88C646.713 88 642.257 89.8483 638.554 93.5763C634.851 97.3042 633 101.753 633 107.016C633 112.279 634.851 116.727 638.554 120.424C642.257 124.12 646.744 126 651.984 126C657.225 126 661.712 124.152 665.415 120.424C669.117 116.727 671 112.247 671 107.016C671 101.784 669.149 97.2729 665.415 93.5763C661.712 89.8796 657.225 88 651.984 88ZM663.092 95.9258C666.168 98.9959 667.705 102.692 667.705 107.016C667.705 111.339 666.168 115.035 663.092 118.074C660.017 121.113 656.315 122.648 651.984 122.648C647.654 122.648 643.951 121.113 640.876 118.074C637.801 115.035 636.263 111.339 636.263 107.016C636.263 102.692 637.801 98.9959 640.876 95.9258C643.951 92.8557 647.654 91.3207 651.984 91.3207C656.315 91.3207 660.017 92.8557 663.092 95.9258Z" fill="#BCD3CC" />
            <rect width="168" height="130.226" transform="translate(508 130)" fill="white" />
            <path d="M526.984 134C521.713 134 517.257 135.848 513.554 139.576C509.851 143.304 508 147.753 508 153.016C508 158.279 509.851 162.727 513.554 166.424C517.257 170.12 521.744 172 526.984 172C532.225 172 536.712 170.152 540.415 166.424C544.117 162.727 546 158.247 546 153.016C546 147.784 544.149 143.273 540.415 139.576C536.712 135.88 532.225 134 526.984 134ZM538.092 141.926C541.168 144.996 542.705 148.692 542.705 153.016C542.705 157.339 541.168 161.035 538.092 164.074C535.017 167.113 531.315 168.648 526.984 168.648C522.654 168.648 518.951 167.113 515.876 164.074C512.801 161.035 511.263 157.339 511.263 153.016C511.263 148.692 512.801 144.996 515.876 141.926C518.951 138.856 522.654 137.321 526.984 137.321C531.315 137.321 535.017 138.856 538.092 141.926Z" fill="#BCD3CC" />
            <path d="M568.984 134C563.713 134 559.257 135.848 555.554 139.576C551.851 143.304 550 147.753 550 153.016C550 158.279 551.851 162.727 555.554 166.424C559.257 170.12 563.744 172 568.984 172C574.225 172 578.712 170.152 582.415 166.424C586.117 162.727 588 158.247 588 153.016C588 147.784 586.149 143.273 582.415 139.576C578.712 135.88 574.225 134 568.984 134ZM580.092 141.926C583.168 144.996 584.705 148.692 584.705 153.016C584.705 157.339 583.168 161.035 580.092 164.074C577.017 167.113 573.315 168.648 568.984 168.648C564.654 168.648 560.951 167.113 557.876 164.074C554.801 161.035 553.263 157.339 553.263 153.016C553.263 148.692 554.801 144.996 557.876 141.926C560.951 138.856 564.654 137.321 568.984 137.321C573.315 137.321 577.017 138.856 580.092 141.926Z" fill="#BCD3CC" />
            <path d="M610.984 134C605.713 134 601.257 135.848 597.554 139.576C593.851 143.304 592 147.753 592 153.016C592 158.279 593.851 162.727 597.554 166.424C601.257 170.12 605.744 172 610.984 172C616.225 172 620.712 170.152 624.415 166.424C628.117 162.727 630 158.247 630 153.016C630 147.784 628.149 143.273 624.415 139.576C620.712 135.88 616.225 134 610.984 134ZM622.092 141.926C625.168 144.996 626.705 148.692 626.705 153.016C626.705 157.339 625.168 161.035 622.092 164.074C619.017 167.113 615.315 168.648 610.984 168.648C606.654 168.648 602.951 167.113 599.876 164.074C596.801 161.035 595.263 157.339 595.263 153.016C595.263 148.692 596.801 144.996 599.876 141.926C602.951 138.856 606.654 137.321 610.984 137.321C615.315 137.321 619.017 138.856 622.092 141.926Z" fill="#BCD3CC" />
            <path d="M665.64 146.931C660.168 141.337 643.41 133.931 639.747 146.931C636.085 159.931 630.555 163.724 636.886 170.196C643.218 176.668 683.305 164.986 665.64 146.931Z" fill="#FFBCB8" />
            <path d="M663.085 142.732C663.298 139.446 660.655 137.486 659.701 136.89C659.543 136.791 659.332 136.888 659.316 137.068C659.271 137.542 659.241 138.452 659.441 139.723C659.497 140.078 659.311 140.174 659.029 139.937C657.857 138.952 655 136.895 652.743 138.079C652.609 138.149 652.567 138.314 652.658 138.432C653.014 138.896 654.118 140.082 657.089 141.825C657.635 142.145 657.562 142.345 656.926 142.266C655.224 142.054 652.252 141.906 650.272 143.049C650.092 143.153 650.116 143.409 650.313 143.479C651.912 144.05 657.331 145.912 659.469 145.771C660.527 145.701 659.264 149.09 658.774 150.316C658.7 150.5 658.877 150.686 659.075 150.632C660.326 150.293 663.599 149.205 663.619 147.109C663.621 146.896 663.883 146.785 664.043 146.934L669.559 152.05L669.559 152.05C669.56 152.046 670.605 147.735 666.179 145.627C665.107 145.117 668.673 145.127 669.846 145.144C670.032 145.147 670.157 144.96 670.074 144.8C669.09 142.895 664.527 142.941 663.343 142.986C663.195 142.992 663.075 142.873 663.085 142.732Z" fill="#264948" />
            <path d="M658.664 160.605C658.664 160.605 657.399 161.456 657.422 161.895C657.446 162.334 657.921 162.616 658.35 162.02C658.778 161.425 658.664 160.605 658.664 160.605Z" fill="#264948" />
            <path d="M653.523 164.433C653.523 164.433 652.258 165.284 652.281 165.723C652.304 166.162 652.779 166.444 653.208 165.848C653.637 165.253 653.523 164.433 653.523 164.433Z" fill="#264948" />
            <path d="M664.923 157.783C664.923 157.783 663.658 158.634 663.681 159.073C663.704 159.512 664.179 159.794 664.608 159.198C665.037 158.603 664.923 157.783 664.923 157.783Z" fill="#264948" />
            <path d="M639.273 162.533C639.273 162.533 638.008 163.384 638.031 163.823C638.054 164.262 638.529 164.544 638.958 163.948C639.387 163.353 639.273 162.533 639.273 162.533Z" fill="#264948" />
            <path d="M661.123 154.933C661.123 154.933 659.858 155.784 659.881 156.223C659.904 156.662 660.379 156.944 660.808 156.348C661.237 155.753 661.123 154.933 661.123 154.933Z" fill="#264948" />
            <path d="M657.323 152.083C657.323 152.083 656.058 152.934 656.081 153.373C656.104 153.812 656.579 154.094 657.008 153.498C657.437 152.903 657.323 152.083 657.323 152.083Z" fill="#264948" />
            <path d="M652.573 149.233C652.573 149.233 651.308 150.084 651.331 150.523C651.354 150.962 651.829 151.244 652.258 150.649C652.687 150.053 652.573 149.233 652.573 149.233Z" fill="#264948" />
            <path d="M654.473 157.783C654.473 157.783 653.208 158.634 653.231 159.073C653.254 159.512 653.729 159.794 654.158 159.198C654.587 158.603 654.473 157.783 654.473 157.783Z" fill="#264948" />
            <path d="M644.973 158.733C644.973 158.733 643.708 159.584 643.731 160.023C643.754 160.462 644.229 160.744 644.658 160.149C645.087 159.553 644.973 158.733 644.973 158.733Z" fill="#264948" />
            <path d="M649.723 161.583C649.723 161.583 648.458 162.434 648.481 162.873C648.504 163.312 648.979 163.594 649.408 162.998C649.837 162.403 649.723 161.583 649.723 161.583Z" fill="#264948" />
            <path d="M643.073 164.433C643.073 164.433 641.808 165.284 641.831 165.723C641.854 166.162 642.329 166.444 642.758 165.848C643.187 165.253 643.073 164.433 643.073 164.433Z" fill="#264948" />
            <path d="M646.873 166.333C646.873 166.333 645.608 167.184 645.631 167.623C645.654 168.062 646.129 168.344 646.558 167.748C646.987 167.153 646.873 166.333 646.873 166.333Z" fill="#264948" />
            <path d="M647.823 146.383C647.823 146.383 646.558 147.234 646.581 147.673C646.604 148.112 647.079 148.394 647.508 147.798C647.937 147.203 647.823 146.383 647.823 146.383Z" fill="#264948" />
            <path d="M644.973 152.083C644.973 152.083 643.708 152.934 643.731 153.373C643.754 153.812 644.229 154.094 644.658 153.498C645.087 152.903 644.973 152.083 644.973 152.083Z" fill="#264948" />
            <path d="M641.173 155.883C641.173 155.883 639.908 156.734 639.931 157.173C639.954 157.612 640.429 157.894 640.858 157.298C641.287 156.703 641.173 155.883 641.173 155.883Z" fill="#264948" />
            <path d="M649.723 154.933C649.723 154.933 648.458 155.784 648.481 156.223C648.504 156.662 648.979 156.944 649.408 156.348C649.837 155.753 649.723 154.933 649.723 154.933Z" fill="#264948" />
            <path d="M517.041 184.33C514.781 185.492 513.207 186.942 512.625 188.787C512.526 189.104 512.588 189.453 512.788 189.72C514.24 191.672 516.473 192.847 519.063 192.515C525.376 191.704 526.194 188.107 525.647 183.873C525.15 180.036 522.924 177.089 518.519 177.081C514.13 177.074 510.575 180.838 511.066 185.255C511.075 185.328 511.093 185.472 511.093 185.472C511.181 186.117 511.907 186.44 512.44 186.08C515.25 184.172 518.224 183.724 517.041 184.33Z" fill="#264948" />
            <path d="M516.877 186.447C517.808 189.398 519.216 191.582 521.291 192.652C521.648 192.834 522.074 192.831 522.431 192.644C525.037 191.291 526.876 188.844 526.996 185.663C527.287 177.91 523.18 176.204 518.045 176.01C513.391 175.836 509.45 177.918 508.566 183.212C507.685 188.487 511.447 193.517 516.788 193.816C516.877 193.82 517.052 193.827 517.052 193.827C517.835 193.851 518.363 193.044 518.041 192.33C516.334 188.568 516.392 184.903 516.877 186.447Z" fill="#BCD3CC" />
            <rect width="168" height="130" transform="translate(676)" fill="white" />
            <path d="M699.692 18.8811C699.536 18.9934 699.403 19.1355 699.301 19.2993C699.199 19.4631 699.131 19.6454 699.1 19.8356C699.069 20.0259 699.075 20.2204 699.119 20.4081C699.163 20.5958 699.244 20.7729 699.357 20.9294L704.608 28.2276L698.059 25.2809C697.317 24.9491 696.434 25.2456 696.086 25.9741C695.921 26.3315 695.905 26.74 696.04 27.1097C696.176 27.4794 696.453 27.7801 696.81 27.9458L703.405 30.9208L694.46 31.8273C694.074 31.8676 693.719 32.059 693.474 32.3598C693.228 32.6605 693.112 33.0461 693.149 33.4325C693.19 33.8187 693.381 34.1732 693.682 34.4188C693.982 34.6644 694.368 34.7811 694.754 34.7435L699.801 34.2348C701.916 34.021 704.071 34.367 706.007 35.2387L708.994 36.5803C709.985 37.0227 711.165 36.5918 711.622 35.5854L713.559 31.2818C714.001 30.2912 713.57 29.1111 712.564 28.6542L709.576 27.3125C707.63 26.443 705.952 25.0704 704.713 23.3362L701.741 19.2166C701.272 18.5541 700.354 18.3969 699.692 18.8811Z" fill="#BCD3CC" />
            <path d="M681.898 13.1637C684.565 11.2588 688.002 4.49031 693.463 7.0602C698.924 9.63009 703.312 3.02225 705.615 9.85816C707.919 16.6973 714.022 19.5884 709.525 21.1946C705.027 22.8008 697.639 24.7282 698.281 29.5467C698.924 34.3653 698.236 39.5276 692.798 39.5147C687.359 39.5019 682.04 38.959 680.202 32.9679C678.365 26.9769 675.891 29.3733 676.004 23.0353C676.116 16.6973 679.65 14.7699 681.898 13.1637Z" fill="#264948" />
            <path d="M736.984 4C731.713 4 727.257 5.84831 723.554 9.57626C719.851 13.3042 718 17.7527 718 23.0157C718 28.2786 719.851 32.7271 723.554 36.4237C727.257 40.1204 731.744 42 736.984 42C742.225 42 746.712 40.1517 750.415 36.4237C754.117 32.7271 756 28.2473 756 23.0157C756 17.784 754.149 13.2729 750.415 9.57626C746.712 5.87964 742.225 4 736.984 4ZM748.092 11.9258C751.168 14.9959 752.705 18.6925 752.705 23.0157C752.705 27.3388 751.168 31.0354 748.092 34.0742C745.017 37.1129 741.315 38.648 736.984 38.648C732.654 38.648 728.951 37.1129 725.876 34.0742C722.801 31.0354 721.263 27.3388 721.263 23.0157C721.263 18.6925 722.801 14.9959 725.876 11.9258C728.951 8.85573 732.654 7.32069 736.984 7.32069C741.315 7.32069 745.017 8.85573 748.092 11.9258Z" fill="#BCD3CC" />
            <path d="M778.984 4C773.713 4 769.257 5.84831 765.554 9.57626C761.851 13.3042 760 17.7527 760 23.0157C760 28.2786 761.851 32.7271 765.554 36.4237C769.257 40.1204 773.744 42 778.984 42C784.225 42 788.712 40.1517 792.415 36.4237C796.117 32.7271 798 28.2473 798 23.0157C798 17.784 796.149 13.2729 792.415 9.57626C788.712 5.87964 784.225 4 778.984 4ZM790.092 11.9258C793.168 14.9959 794.705 18.6925 794.705 23.0157C794.705 27.3388 793.168 31.0354 790.092 34.0742C787.017 37.1129 783.315 38.648 778.984 38.648C774.654 38.648 770.951 37.1129 767.876 34.0742C764.801 31.0354 763.263 27.3388 763.263 23.0157C763.263 18.6925 764.801 14.9959 767.876 11.9258C770.951 8.85573 774.654 7.32069 778.984 7.32069C783.315 7.32069 787.017 8.85573 790.092 11.9258Z" fill="#BCD3CC" />
            <path d="M820.984 4C815.713 4 811.257 5.84831 807.554 9.57626C803.851 13.3042 802 17.7527 802 23.0157C802 28.2786 803.851 32.7271 807.554 36.4237C811.257 40.1204 815.744 42 820.984 42C826.225 42 830.712 40.1517 834.415 36.4237C838.117 32.7271 840 28.2473 840 23.0157C840 17.784 838.149 13.2729 834.415 9.57626C830.712 5.87964 826.225 4 820.984 4ZM832.092 11.9258C835.168 14.9959 836.705 18.6925 836.705 23.0157C836.705 27.3388 835.168 31.0354 832.092 34.0742C829.017 37.1129 825.315 38.648 820.984 38.648C816.654 38.648 812.951 37.1129 809.876 34.0742C806.801 31.0354 805.263 27.3388 805.263 23.0157C805.263 18.6925 806.801 14.9959 809.876 11.9258C812.951 8.85573 816.654 7.32069 820.984 7.32069C825.315 7.32069 829.017 8.85573 832.092 11.9258Z" fill="#BCD3CC" />
            <path d="M694.984 46C689.713 46 685.257 47.8483 681.554 51.5763C677.851 55.3042 676 59.7527 676 65.0157C676 70.2786 677.851 74.7271 681.554 78.4237C685.257 82.1204 689.744 84 694.984 84C700.225 84 704.712 82.1517 708.415 78.4237C712.117 74.7271 714 70.2473 714 65.0157C714 59.784 712.149 55.2729 708.415 51.5763C704.712 47.8796 700.225 46 694.984 46ZM706.092 53.9258C709.168 56.9959 710.705 60.6925 710.705 65.0157C710.705 69.3388 709.168 73.0354 706.092 76.0742C703.017 79.1129 699.315 80.648 694.984 80.648C690.654 80.648 686.951 79.1129 683.876 76.0742C680.801 73.0354 679.263 69.3388 679.263 65.0157C679.263 60.6925 680.801 56.9959 683.876 53.9258C686.951 50.8557 690.654 49.3207 694.984 49.3207C699.315 49.3207 703.017 50.8557 706.092 53.9258Z" fill="#BCD3CC" />
            <path d="M730.395 50.9851C743.256 47.2346 752.982 50.1959 755.322 60.1536C758.566 73.955 749.614 79.5433 740.286 80.2334C730.958 80.9235 724.784 81.3378 720.502 73.7816C716.223 66.2253 716.201 55.1256 730.395 50.9851Z" fill="#FFBCB8" />
            <path d="M732.989 55.7892C728.763 56.1377 728.673 55.5339 732.25 54.378C735.308 53.3912 735.54 52.9047 735.402 51.6695C735.235 50.2134 736.606 49.5923 736.801 51.3106C736.967 52.7667 737.592 53.2049 739.822 52.5321C742.239 51.8006 741.846 52.6114 739.988 53.9881C738.764 54.8955 739.011 55.5408 740.576 56.6345C743.411 58.6219 742.62 59.4086 739.859 57.9181C737.097 56.431 737.202 57.5006 735.973 59.2189C734.481 61.3029 733.341 61.396 734.465 58.6081C735.503 56.0273 735.276 55.5994 732.993 55.7892H732.989Z" fill="#264948" />
            <path d="M778.984 46C773.713 46 769.257 47.8483 765.554 51.5763C761.851 55.3042 760 59.7527 760 65.0157C760 70.2786 761.851 74.7271 765.554 78.4237C769.257 82.1204 773.744 84 778.984 84C784.225 84 788.712 82.1517 792.415 78.4237C796.117 74.7271 798 70.2473 798 65.0157C798 59.784 796.149 55.2729 792.415 51.5763C788.712 47.8796 784.225 46 778.984 46ZM790.092 53.9258C793.168 56.9959 794.705 60.6925 794.705 65.0157C794.705 69.3388 793.168 73.0354 790.092 76.0742C787.017 79.1129 783.315 80.648 778.984 80.648C774.654 80.648 770.951 79.1129 767.876 76.0742C764.801 73.0354 763.263 69.3388 763.263 65.0157C763.263 60.6925 764.801 56.9959 767.876 53.9258C770.951 50.8557 774.654 49.3207 778.984 49.3207C783.315 49.3207 787.017 50.8557 790.092 53.9258Z" fill="#BCD3CC" />
            <path d="M820.984 46C815.713 46 811.257 47.8483 807.554 51.5763C803.851 55.3042 802 59.7527 802 65.0157C802 70.2786 803.851 74.7271 807.554 78.4237C811.257 82.1204 815.744 84 820.984 84C826.225 84 830.712 82.1517 834.415 78.4237C838.117 74.7271 840 70.2473 840 65.0157C840 59.784 838.149 55.2729 834.415 51.5763C830.712 47.8796 826.225 46 820.984 46ZM832.092 53.9258C835.168 56.9959 836.705 60.6925 836.705 65.0157C836.705 69.3388 835.168 73.0354 832.092 76.0742C829.017 79.1129 825.315 80.648 820.984 80.648C816.654 80.648 812.951 79.1129 809.876 76.0742C806.801 73.0354 805.263 69.3388 805.263 65.0157C805.263 60.6925 806.801 56.9959 809.876 53.9258C812.951 50.8557 816.654 49.3207 820.984 49.3207C825.315 49.3207 829.017 50.8557 832.092 53.9258Z" fill="#BCD3CC" />
            <path d="M694.984 88C689.713 88 685.257 89.8483 681.554 93.5763C677.851 97.3042 676 101.753 676 107.016C676 112.279 677.851 116.727 681.554 120.424C685.257 124.12 689.744 126 694.984 126C700.225 126 704.712 124.152 708.415 120.424C712.117 116.727 714 112.247 714 107.016C714 101.784 712.149 97.2729 708.415 93.5763C704.712 89.8796 700.225 88 694.984 88ZM706.092 95.9258C709.168 98.9959 710.705 102.692 710.705 107.016C710.705 111.339 709.168 115.035 706.092 118.074C703.017 121.113 699.315 122.648 694.984 122.648C690.654 122.648 686.951 121.113 683.876 118.074C680.801 115.035 679.263 111.339 679.263 107.016C679.263 102.692 680.801 98.9959 683.876 95.9258C686.951 92.8557 690.654 91.3207 694.984 91.3207C699.315 91.3207 703.017 92.8557 706.092 95.9258Z" fill="#BCD3CC" />
            <path d="M736.984 88C731.713 88 727.257 89.8483 723.554 93.5763C719.851 97.3042 718 101.753 718 107.016C718 112.279 719.851 116.727 723.554 120.424C727.257 124.12 731.744 126 736.984 126C742.225 126 746.712 124.152 750.415 120.424C754.117 116.727 756 112.247 756 107.016C756 101.784 754.149 97.2729 750.415 93.5763C746.712 89.8796 742.225 88 736.984 88ZM748.092 95.9258C751.168 98.9959 752.705 102.692 752.705 107.016C752.705 111.339 751.168 115.035 748.092 118.074C745.017 121.113 741.315 122.648 736.984 122.648C732.654 122.648 728.951 121.113 725.876 118.074C722.801 115.035 721.263 111.339 721.263 107.016C721.263 102.692 722.801 98.9959 725.876 95.9258C728.951 92.8557 732.654 91.3207 736.984 91.3207C741.315 91.3207 745.017 92.8557 748.092 95.9258Z" fill="#BCD3CC" />
            <path d="M760.474 110.717C763.983 122.779 772.225 126.951 776.764 125.067C778.268 124.443 779.019 124.443 780.273 125.067C782.11 125.982 792.728 125.117 796.312 111.133C799.896 97.1482 788.568 93.4545 783.418 95.0143C778.268 96.5742 779.107 96.4556 772.504 94.7024C766.238 93.0386 757.918 101.923 760.474 110.717Z" fill="#BCD3CC" />
            <path d="M782.459 106.5C782.459 106.5 781.944 110.876 782.974 111.648C784.003 112.42 785.547 111.905 785.033 109.846C784.518 107.787 782.459 106.5 782.459 106.5Z" fill="#264948" />
            <path d="M776.006 106.5C776.006 106.5 776.521 110.876 775.491 111.648C774.462 112.42 772.918 111.905 773.432 109.846C773.947 107.787 776.006 106.5 776.006 106.5Z" fill="#264948" />
            <path d="M779.207 96.4322C779.207 96.4322 778.612 96.9278 778.549 96.4322C778.504 96.068 778.551 93.1535 778.551 93.1535C778.551 92.3338 777.864 90.826 777.031 90.8745C774.995 90.9928 775.332 89.6577 775.69 89.0992C776.427 87.9494 780.073 89.7443 779.888 92.6723C779.702 95.6002 779.207 96.4322 779.207 96.4322Z" fill="#264948" />
            <path d="M819.984 88C814.713 88 810.257 89.8483 806.554 93.5763C802.851 97.3042 801 101.753 801 107.016C801 112.279 802.851 116.727 806.554 120.424C810.257 124.12 814.744 126 819.984 126C825.225 126 829.712 124.152 833.415 120.424C837.117 116.727 839 112.247 839 107.016C839 101.784 837.149 97.2729 833.415 93.5763C829.712 89.8796 825.225 88 819.984 88ZM831.092 95.9258C834.168 98.9959 835.705 102.692 835.705 107.016C835.705 111.339 834.168 115.035 831.092 118.074C828.017 121.113 824.315 122.648 819.984 122.648C815.654 122.648 811.951 121.113 808.876 118.074C805.801 115.035 804.263 111.339 804.263 107.016C804.263 102.692 805.801 98.9959 808.876 95.9258C811.951 92.8557 815.654 91.3207 819.984 91.3207C824.315 91.3207 828.017 92.8557 831.092 95.9258Z" fill="#BCD3CC" />
            <rect width="168" height="130.226" transform="translate(676 130)" fill="white" />
            <path d="M694.984 134C689.713 134 685.257 135.848 681.554 139.576C677.851 143.304 676 147.753 676 153.016C676 158.279 677.851 162.727 681.554 166.424C685.257 170.12 689.744 172 694.984 172C700.225 172 704.712 170.152 708.415 166.424C712.117 162.727 714 158.247 714 153.016C714 147.784 712.149 143.273 708.415 139.576C704.712 135.88 700.225 134 694.984 134ZM706.092 141.926C709.168 144.996 710.705 148.692 710.705 153.016C710.705 157.339 709.168 161.035 706.092 164.074C703.017 167.113 699.315 168.648 694.984 168.648C690.654 168.648 686.951 167.113 683.876 164.074C680.801 161.035 679.263 157.339 679.263 153.016C679.263 148.692 680.801 144.996 683.876 141.926C686.951 138.856 690.654 137.321 694.984 137.321C699.315 137.321 703.017 138.856 706.092 141.926Z" fill="#BCD3CC" />
            <path d="M736.984 134C731.713 134 727.257 135.848 723.554 139.576C719.851 143.304 718 147.753 718 153.016C718 158.279 719.851 162.727 723.554 166.424C727.257 170.12 731.744 172 736.984 172C742.225 172 746.712 170.152 750.415 166.424C754.117 162.727 756 158.247 756 153.016C756 147.784 754.149 143.273 750.415 139.576C746.712 135.88 742.225 134 736.984 134ZM748.092 141.926C751.168 144.996 752.705 148.692 752.705 153.016C752.705 157.339 751.168 161.035 748.092 164.074C745.017 167.113 741.315 168.648 736.984 168.648C732.654 168.648 728.951 167.113 725.876 164.074C722.801 161.035 721.263 157.339 721.263 153.016C721.263 148.692 722.801 144.996 725.876 141.926C728.951 138.856 732.654 137.321 736.984 137.321C741.315 137.321 745.017 138.856 748.092 141.926Z" fill="#BCD3CC" />
            <path d="M778.984 134C773.713 134 769.257 135.848 765.554 139.576C761.851 143.304 760 147.753 760 153.016C760 158.279 761.851 162.727 765.554 166.424C769.257 170.12 773.744 172 778.984 172C784.225 172 788.712 170.152 792.415 166.424C796.117 162.727 798 158.247 798 153.016C798 147.784 796.149 143.273 792.415 139.576C788.712 135.88 784.225 134 778.984 134ZM790.092 141.926C793.168 144.996 794.705 148.692 794.705 153.016C794.705 157.339 793.168 161.035 790.092 164.074C787.017 167.113 783.315 168.648 778.984 168.648C774.654 168.648 770.951 167.113 767.876 164.074C764.801 161.035 763.263 157.339 763.263 153.016C763.263 148.692 764.801 144.996 767.876 141.926C770.951 138.856 774.654 137.321 778.984 137.321C783.315 137.321 787.017 138.856 790.092 141.926Z" fill="#BCD3CC" />
            <path d="M833.64 146.931C828.168 141.337 811.41 133.931 807.747 146.931C804.085 159.931 798.555 163.724 804.886 170.196C811.218 176.668 851.305 164.986 833.64 146.931Z" fill="#FFBCB8" />
            <path d="M831.085 142.732C831.298 139.446 828.655 137.486 827.701 136.89C827.543 136.791 827.332 136.888 827.316 137.068C827.271 137.542 827.241 138.452 827.441 139.723C827.497 140.078 827.311 140.174 827.029 139.937C825.857 138.952 823 136.895 820.743 138.079C820.609 138.149 820.567 138.314 820.658 138.432C821.014 138.896 822.118 140.082 825.089 141.825C825.635 142.145 825.562 142.345 824.926 142.266C823.224 142.054 820.252 141.906 818.272 143.049C818.092 143.153 818.116 143.409 818.313 143.479C819.912 144.05 825.331 145.912 827.469 145.771C828.527 145.701 827.264 149.09 826.774 150.316C826.7 150.5 826.877 150.686 827.075 150.632C828.326 150.293 831.599 149.205 831.619 147.109C831.621 146.896 831.883 146.785 832.043 146.934L837.559 152.05L837.559 152.05C837.56 152.046 838.605 147.735 834.179 145.627C833.107 145.117 836.673 145.127 837.846 145.144C838.032 145.147 838.157 144.96 838.074 144.8C837.09 142.895 832.527 142.941 831.343 142.986C831.195 142.992 831.075 142.873 831.085 142.732Z" fill="#264948" />
            <path d="M826.664 160.605C826.664 160.605 825.399 161.456 825.422 161.895C825.446 162.334 825.921 162.616 826.35 162.02C826.778 161.425 826.664 160.605 826.664 160.605Z" fill="#264948" />
            <path d="M821.523 164.433C821.523 164.433 820.258 165.284 820.281 165.723C820.304 166.162 820.779 166.444 821.208 165.848C821.637 165.253 821.523 164.433 821.523 164.433Z" fill="#264948" />
            <path d="M832.923 157.783C832.923 157.783 831.658 158.634 831.681 159.073C831.704 159.512 832.179 159.794 832.608 159.198C833.037 158.603 832.923 157.783 832.923 157.783Z" fill="#264948" />
            <path d="M807.273 162.533C807.273 162.533 806.008 163.384 806.031 163.823C806.054 164.262 806.529 164.544 806.958 163.948C807.387 163.353 807.273 162.533 807.273 162.533Z" fill="#264948" />
            <path d="M829.123 154.933C829.123 154.933 827.858 155.784 827.881 156.223C827.904 156.662 828.379 156.944 828.808 156.348C829.237 155.753 829.123 154.933 829.123 154.933Z" fill="#264948" />
            <path d="M825.323 152.083C825.323 152.083 824.058 152.934 824.081 153.373C824.104 153.812 824.579 154.094 825.008 153.498C825.437 152.903 825.323 152.083 825.323 152.083Z" fill="#264948" />
            <path d="M820.573 149.233C820.573 149.233 819.308 150.084 819.331 150.523C819.354 150.962 819.829 151.244 820.258 150.649C820.687 150.053 820.573 149.233 820.573 149.233Z" fill="#264948" />
            <path d="M822.473 157.783C822.473 157.783 821.208 158.634 821.231 159.073C821.254 159.512 821.729 159.794 822.158 159.198C822.587 158.603 822.473 157.783 822.473 157.783Z" fill="#264948" />
            <path d="M812.973 158.733C812.973 158.733 811.708 159.584 811.731 160.023C811.754 160.462 812.229 160.744 812.658 160.149C813.087 159.553 812.973 158.733 812.973 158.733Z" fill="#264948" />
            <path d="M817.723 161.583C817.723 161.583 816.458 162.434 816.481 162.873C816.504 163.312 816.979 163.594 817.408 162.998C817.837 162.403 817.723 161.583 817.723 161.583Z" fill="#264948" />
            <path d="M811.073 164.433C811.073 164.433 809.808 165.284 809.831 165.723C809.854 166.162 810.329 166.444 810.758 165.848C811.187 165.253 811.073 164.433 811.073 164.433Z" fill="#264948" />
            <path d="M814.873 166.333C814.873 166.333 813.608 167.184 813.631 167.623C813.654 168.062 814.129 168.344 814.558 167.748C814.987 167.153 814.873 166.333 814.873 166.333Z" fill="#264948" />
            <path d="M815.823 146.383C815.823 146.383 814.558 147.234 814.581 147.673C814.604 148.112 815.079 148.394 815.508 147.798C815.937 147.203 815.823 146.383 815.823 146.383Z" fill="#264948" />
            <path d="M812.973 152.083C812.973 152.083 811.708 152.934 811.731 153.373C811.754 153.812 812.229 154.094 812.658 153.498C813.087 152.903 812.973 152.083 812.973 152.083Z" fill="#264948" />
            <path d="M809.173 155.883C809.173 155.883 807.908 156.734 807.931 157.173C807.954 157.612 808.429 157.894 808.858 157.298C809.287 156.703 809.173 155.883 809.173 155.883Z" fill="#264948" />
            <path d="M817.723 154.933C817.723 154.933 816.458 155.784 816.481 156.223C816.504 156.662 816.979 156.944 817.408 156.348C817.837 155.753 817.723 154.933 817.723 154.933Z" fill="#264948" />
            <path d="M685.041 184.33C682.781 185.492 681.207 186.942 680.625 188.787C680.526 189.104 680.588 189.453 680.788 189.72C682.24 191.672 684.473 192.847 687.063 192.515C693.376 191.704 694.194 188.107 693.647 183.873C693.15 180.036 690.924 177.089 686.519 177.081C682.13 177.074 678.575 180.838 679.066 185.255C679.075 185.328 679.093 185.472 679.093 185.472C679.181 186.117 679.907 186.44 680.44 186.08C683.25 184.172 686.224 183.724 685.041 184.33Z" fill="#264948" />
            <path d="M684.877 186.447C685.808 189.398 687.216 191.582 689.291 192.652C689.648 192.834 690.074 192.831 690.431 192.644C693.037 191.291 694.876 188.844 694.996 185.663C695.287 177.91 691.18 176.204 686.045 176.01C681.391 175.836 677.45 177.918 676.566 183.212C675.685 188.487 679.447 193.517 684.788 193.816C684.877 193.82 685.052 193.827 685.052 193.827C685.835 193.851 686.363 193.044 686.041 192.33C684.334 188.568 684.392 184.903 684.877 186.447Z" fill="#BCD3CC" />
            <rect width="168" height="130" transform="translate(844)" fill="white" />
            <path d="M867.692 18.8811C867.536 18.9934 867.403 19.1355 867.301 19.2993C867.199 19.4631 867.131 19.6454 867.1 19.8356C867.069 20.0259 867.075 20.2204 867.119 20.4081C867.163 20.5958 867.244 20.7729 867.357 20.9294L872.608 28.2276L866.06 25.2809C865.317 24.9491 864.434 25.2456 864.086 25.9741C863.921 26.3315 863.905 26.74 864.04 27.1097C864.176 27.4794 864.453 27.7801 864.81 27.9458L871.405 30.9208L862.46 31.8273C862.074 31.8676 861.719 32.059 861.474 32.3598C861.228 32.6605 861.112 33.0461 861.149 33.4325C861.19 33.8187 861.381 34.1732 861.682 34.4188C861.982 34.6644 862.368 34.7811 862.754 34.7435L867.801 34.2348C869.916 34.021 872.071 34.367 874.007 35.2387L876.994 36.5803C877.985 37.0227 879.165 36.5918 879.622 35.5854L881.559 31.2818C882.001 30.2912 881.57 29.1111 880.564 28.6542L877.576 27.3125C875.63 26.443 873.952 25.0704 872.713 23.3362L869.741 19.2166C869.272 18.5541 868.354 18.3969 867.692 18.8811Z" fill="#BCD3CC" />
            <path d="M849.898 13.1637C852.565 11.2588 856.002 4.49031 861.463 7.0602C866.924 9.63009 871.312 3.02225 873.615 9.85816C875.919 16.6973 882.022 19.5884 877.525 21.1946C873.027 22.8008 865.639 24.7282 866.281 29.5467C866.924 34.3653 866.236 39.5276 860.798 39.5147C855.359 39.5019 850.04 38.959 848.202 32.9679C846.365 26.9769 843.891 29.3733 844.004 23.0353C844.116 16.6973 847.65 14.7699 849.898 13.1637Z" fill="#264948" />
            <path d="M904.984 4C899.713 4 895.257 5.84831 891.554 9.57626C887.851 13.3042 886 17.7527 886 23.0157C886 28.2786 887.851 32.7271 891.554 36.4237C895.257 40.1204 899.744 42 904.984 42C910.225 42 914.712 40.1517 918.415 36.4237C922.117 32.7271 924 28.2473 924 23.0157C924 17.784 922.149 13.2729 918.415 9.57626C914.712 5.87964 910.225 4 904.984 4ZM916.092 11.9258C919.168 14.9959 920.705 18.6925 920.705 23.0157C920.705 27.3388 919.168 31.0354 916.092 34.0742C913.017 37.1129 909.315 38.648 904.984 38.648C900.654 38.648 896.951 37.1129 893.876 34.0742C890.801 31.0354 889.263 27.3388 889.263 23.0157C889.263 18.6925 890.801 14.9959 893.876 11.9258C896.951 8.85573 900.654 7.32069 904.984 7.32069C909.315 7.32069 913.017 8.85573 916.092 11.9258Z" fill="#BCD3CC" />
            <path d="M946.984 4C941.713 4 937.257 5.84831 933.554 9.57626C929.851 13.3042 928 17.7527 928 23.0157C928 28.2786 929.851 32.7271 933.554 36.4237C937.257 40.1204 941.744 42 946.984 42C952.225 42 956.712 40.1517 960.415 36.4237C964.117 32.7271 966 28.2473 966 23.0157C966 17.784 964.149 13.2729 960.415 9.57626C956.712 5.87964 952.225 4 946.984 4ZM958.092 11.9258C961.168 14.9959 962.705 18.6925 962.705 23.0157C962.705 27.3388 961.168 31.0354 958.092 34.0742C955.017 37.1129 951.315 38.648 946.984 38.648C942.654 38.648 938.951 37.1129 935.876 34.0742C932.801 31.0354 931.263 27.3388 931.263 23.0157C931.263 18.6925 932.801 14.9959 935.876 11.9258C938.951 8.85573 942.654 7.32069 946.984 7.32069C951.315 7.32069 955.017 8.85573 958.092 11.9258Z" fill="#BCD3CC" />
            <path d="M862.984 46C857.713 46 853.257 47.8483 849.554 51.5763C845.851 55.3042 844 59.7527 844 65.0157C844 70.2786 845.851 74.7271 849.554 78.4237C853.257 82.1204 857.744 84 862.984 84C868.225 84 872.712 82.1517 876.415 78.4237C880.117 74.7271 882 70.2473 882 65.0157C882 59.784 880.149 55.2729 876.415 51.5763C872.712 47.8796 868.225 46 862.984 46ZM874.092 53.9258C877.168 56.9959 878.705 60.6925 878.705 65.0157C878.705 69.3388 877.168 73.0354 874.092 76.0742C871.017 79.1129 867.315 80.648 862.984 80.648C858.654 80.648 854.951 79.1129 851.876 76.0742C848.801 73.0354 847.263 69.3388 847.263 65.0157C847.263 60.6925 848.801 56.9959 851.876 53.9258C854.951 50.8557 858.654 49.3207 862.984 49.3207C867.315 49.3207 871.017 50.8557 874.092 53.9258Z" fill="#BCD3CC" />
            <path d="M898.395 50.9851C911.256 47.2346 920.982 50.1959 923.322 60.1536C926.566 73.955 917.614 79.5433 908.286 80.2334C898.958 80.9235 892.784 81.3378 888.502 73.7816C884.223 66.2253 884.201 55.1256 898.395 50.9851Z" fill="#FFBCB8" />
            <path d="M900.989 55.7892C896.763 56.1377 896.673 55.5339 900.25 54.378C903.308 53.3912 903.54 52.9047 903.402 51.6695C903.235 50.2134 904.606 49.5923 904.801 51.3106C904.967 52.7667 905.592 53.2049 907.822 52.5321C910.239 51.8006 909.846 52.6114 907.988 53.9881C906.764 54.8955 907.011 55.5408 908.576 56.6345C911.411 58.6219 910.62 59.4086 907.859 57.9181C905.097 56.431 905.202 57.5006 903.973 59.2189C902.481 61.3029 901.341 61.396 902.465 58.6081C903.503 56.0273 903.276 55.5994 900.993 55.7892H900.989Z" fill="#264948" />
            <path d="M946.984 46C941.713 46 937.257 47.8483 933.554 51.5763C929.851 55.3042 928 59.7527 928 65.0157C928 70.2786 929.851 74.7271 933.554 78.4237C937.257 82.1204 941.744 84 946.984 84C952.225 84 956.712 82.1517 960.415 78.4237C964.117 74.7271 966 70.2473 966 65.0157C966 59.784 964.149 55.2729 960.415 51.5763C956.712 47.8796 952.225 46 946.984 46ZM958.092 53.9258C961.168 56.9959 962.705 60.6925 962.705 65.0157C962.705 69.3388 961.168 73.0354 958.092 76.0742C955.017 79.1129 951.315 80.648 946.984 80.648C942.654 80.648 938.951 79.1129 935.876 76.0742C932.801 73.0354 931.263 69.3388 931.263 65.0157C931.263 60.6925 932.801 56.9959 935.876 53.9258C938.951 50.8557 942.654 49.3207 946.984 49.3207C951.315 49.3207 955.017 50.8557 958.092 53.9258Z" fill="#BCD3CC" />
            <path d="M862.984 88C857.713 88 853.257 89.8483 849.554 93.5763C845.851 97.3042 844 101.753 844 107.016C844 112.279 845.851 116.727 849.554 120.424C853.257 124.12 857.744 126 862.984 126C868.225 126 872.712 124.152 876.415 120.424C880.117 116.727 882 112.247 882 107.016C882 101.784 880.149 97.2729 876.415 93.5763C872.712 89.8796 868.225 88 862.984 88ZM874.092 95.9258C877.168 98.9959 878.705 102.692 878.705 107.016C878.705 111.339 877.168 115.035 874.092 118.074C871.017 121.113 867.315 122.648 862.984 122.648C858.654 122.648 854.951 121.113 851.876 118.074C848.801 115.035 847.263 111.339 847.263 107.016C847.263 102.692 848.801 98.9959 851.876 95.9258C854.951 92.8557 858.654 91.3207 862.984 91.3207C867.315 91.3207 871.017 92.8557 874.092 95.9258Z" fill="#BCD3CC" />
            <path d="M904.984 88C899.713 88 895.257 89.8483 891.554 93.5763C887.851 97.3042 886 101.753 886 107.016C886 112.279 887.851 116.727 891.554 120.424C895.257 124.12 899.744 126 904.984 126C910.225 126 914.712 124.152 918.415 120.424C922.117 116.727 924 112.247 924 107.016C924 101.784 922.149 97.2729 918.415 93.5763C914.712 89.8796 910.225 88 904.984 88ZM916.092 95.9258C919.168 98.9959 920.705 102.692 920.705 107.016C920.705 111.339 919.168 115.035 916.092 118.074C913.017 121.113 909.315 122.648 904.984 122.648C900.654 122.648 896.951 121.113 893.876 118.074C890.801 115.035 889.263 111.339 889.263 107.016C889.263 102.692 890.801 98.9959 893.876 95.9258C896.951 92.8557 900.654 91.3207 904.984 91.3207C909.315 91.3207 913.017 92.8557 916.092 95.9258Z" fill="#BCD3CC" />
            <path d="M928.474 110.717C931.983 122.779 940.225 126.951 944.764 125.067C946.268 124.443 947.019 124.443 948.273 125.067C950.11 125.982 960.728 125.117 964.312 111.133C967.896 97.1482 956.568 93.4545 951.418 95.0143C946.268 96.5742 947.107 96.4556 940.504 94.7024C934.238 93.0386 925.918 101.923 928.474 110.717Z" fill="#BCD3CC" />
            <path d="M950.459 106.5C950.459 106.5 949.944 110.876 950.974 111.648C952.003 112.42 953.547 111.905 953.033 109.846C952.518 107.787 950.459 106.5 950.459 106.5Z" fill="#264948" />
            <path d="M944.006 106.5C944.006 106.5 944.521 110.876 943.491 111.648C942.462 112.42 940.918 111.905 941.432 109.846C941.947 107.787 944.006 106.5 944.006 106.5Z" fill="#264948" />
            <path d="M947.207 96.4322C947.207 96.4322 946.612 96.9278 946.549 96.4322C946.504 96.068 946.551 93.1535 946.551 93.1535C946.551 92.3338 945.864 90.826 945.031 90.8745C942.995 90.9928 943.332 89.6577 943.69 89.0992C944.427 87.9494 948.073 89.7443 947.888 92.6723C947.702 95.6002 947.207 96.4322 947.207 96.4322Z" fill="#264948" />
            <rect width="168" height="130.226" transform="translate(844 130)" fill="white" />
            <path d="M862.984 134C857.713 134 853.257 135.848 849.554 139.576C845.851 143.304 844 147.753 844 153.016C844 158.279 845.851 162.727 849.554 166.424C853.257 170.12 857.744 172 862.984 172C868.225 172 872.712 170.152 876.415 166.424C880.117 162.727 882 158.247 882 153.016C882 147.784 880.149 143.273 876.415 139.576C872.712 135.88 868.225 134 862.984 134ZM874.092 141.926C877.168 144.996 878.705 148.692 878.705 153.016C878.705 157.339 877.168 161.035 874.092 164.074C871.017 167.113 867.315 168.648 862.984 168.648C858.654 168.648 854.951 167.113 851.876 164.074C848.801 161.035 847.263 157.339 847.263 153.016C847.263 148.692 848.801 144.996 851.876 141.926C854.951 138.856 858.654 137.321 862.984 137.321C867.315 137.321 871.017 138.856 874.092 141.926Z" fill="#BCD3CC" />
            <path d="M904.984 134C899.713 134 895.257 135.848 891.554 139.576C887.851 143.304 886 147.753 886 153.016C886 158.279 887.851 162.727 891.554 166.424C895.257 170.12 899.744 172 904.984 172C910.225 172 914.712 170.152 918.415 166.424C922.117 162.727 924 158.247 924 153.016C924 147.784 922.149 143.273 918.415 139.576C914.712 135.88 910.225 134 904.984 134ZM916.092 141.926C919.168 144.996 920.705 148.692 920.705 153.016C920.705 157.339 919.168 161.035 916.092 164.074C913.017 167.113 909.315 168.648 904.984 168.648C900.654 168.648 896.951 167.113 893.876 164.074C890.801 161.035 889.263 157.339 889.263 153.016C889.263 148.692 890.801 144.996 893.876 141.926C896.951 138.856 900.654 137.321 904.984 137.321C909.315 137.321 913.017 138.856 916.092 141.926Z" fill="#BCD3CC" />
            <path d="M946.984 134C941.713 134 937.257 135.848 933.554 139.576C929.851 143.304 928 147.753 928 153.016C928 158.279 929.851 162.727 933.554 166.424C937.257 170.12 941.744 172 946.984 172C952.225 172 956.712 170.152 960.415 166.424C964.117 162.727 966 158.247 966 153.016C966 147.784 964.149 143.273 960.415 139.576C956.712 135.88 952.225 134 946.984 134ZM958.092 141.926C961.168 144.996 962.705 148.692 962.705 153.016C962.705 157.339 961.168 161.035 958.092 164.074C955.017 167.113 951.315 168.648 946.984 168.648C942.654 168.648 938.951 167.113 935.876 164.074C932.801 161.035 931.263 157.339 931.263 153.016C931.263 148.692 932.801 144.996 935.876 141.926C938.951 138.856 942.654 137.321 946.984 137.321C951.315 137.321 955.017 138.856 958.092 141.926Z" fill="#BCD3CC" />
            <path d="M853.041 184.33C850.781 185.492 849.207 186.942 848.625 188.787C848.526 189.104 848.588 189.453 848.788 189.72C850.24 191.672 852.473 192.847 855.063 192.515C861.376 191.704 862.194 188.107 861.647 183.873C861.15 180.036 858.924 177.089 854.519 177.081C850.13 177.074 846.575 180.838 847.066 185.255C847.075 185.328 847.093 185.472 847.093 185.472C847.181 186.117 847.907 186.44 848.44 186.08C851.25 184.172 854.224 183.724 853.041 184.33Z" fill="#264948" />
            <path d="M852.877 186.447C853.808 189.398 855.216 191.582 857.291 192.652C857.648 192.834 858.074 192.831 858.431 192.644C861.037 191.291 862.876 188.844 862.996 185.663C863.287 177.91 859.18 176.204 854.045 176.01C849.391 175.836 845.45 177.918 844.566 183.212C843.685 188.487 847.447 193.517 852.788 193.816C852.877 193.82 853.052 193.827 853.052 193.827C853.835 193.851 854.363 193.044 854.041 192.33C852.334 188.568 852.392 184.903 852.877 186.447Z" fill="#BCD3CC" />
        </g>
        <defs>
            <clipPath id="clip0_8838_1405">
                <rect width="967" height="176" rx="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
