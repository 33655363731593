import React from 'react';
import { checkAllFieldsIsFilled, preferenceSaveService } from '../../services/preferenceSaveService';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';
import { PREFERENCE_TYPE_CEREAL_MIX } from '../../constant';

const PreferenceItemCreateWhatOptionListItem = ({ itemCode, isProduct, itemName, setIsLoading }) => {
    const addValueInSelectedList = () => {
        if(preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX) {
            preferenceSaveService.fillPreferenceItemCerealMix(itemCode, isProduct);
            preferenceCerealMixSelectService.addItem(itemCode);
        } else {
            preferenceSaveService.fillPreferenceItemSelectField('preferenceItemCodes', [{"itemCode": itemCode}]);
        }
        if(checkAllFieldsIsFilled(preferenceSaveService.getPreference())) {
            preferenceSaveService.savePreference(() => {
                setIsLoading({table: false, phrase: true});
                preferenceSaveService.generatePhraseByTable(); 
            });
        }
    };

    return (
        <li key={itemCode} className="block__content select__choice-option" onClick={addValueInSelectedList}>
            {itemName}
        </li>
    );
};

export default PreferenceItemCreateWhatOptionListItem;
