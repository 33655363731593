import React from 'react';

const Loading = () => (
    <div className='loading'>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
        <span className='loading__item'></span>
    </div>
);

export default Loading;
