import { GET_USER_REQUEST, PREFERENCE_URL_PART, getFetchRequest, postFetchRequest } from "../store/requests";
import { userService } from "./userService";
import {errorCallback, makeUrl} from "../util";

const preparePreferenceItem = (preference) => {
    return {
        include: preference.include,
        preferenceType: preference.preferenceType,
        preferenceItemCodes: preference.preferenceItemCodes,
        when: preference.when,
        preferenceFrequency: preference.preferenceFrequency,
        frequencyPeriod: preference.frequencyPeriod,
        phrase: preference.content,
        locale: preference.locale,
    };
};

export const initialPreferencesService = {
    preferencesList: [],
    selectedItems: [],
    observersList: [],
    loadListOfPossibleInitialPreferences(callback) {
        try {
            getFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART, '?welcome=true&count=12']), (response) => {
                this.preferencesList = response;
                callback();
                this.notifyChangingInitialPreferencesObservers();
            }, errorCallback);
            
        } catch(err) {
            console.log(err);
        }
    },
    getInitialPreferences() {
        return this.preferencesList;
    },
    registerChangeInitialPreferencesObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterChangeInitialPreferencesObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyChangingInitialPreferencesObservers() {
        this.observersList.forEach(observer => observer());
    },
    getActivePreferencesList() {
        return this.selectedItems;
    },
    addPreferenceInList(preferenceId) {
        this.selectedItems.push(this.preferencesList.find(it => it.id === preferenceId));
        this.notifyChangingInitialPreferencesObservers();
    },
    deletePreferenceFromList(preferenceId) {
        this.selectedItems = this.selectedItems.filter(it => it.id !== preferenceId);
        this.notifyChangingInitialPreferencesObservers();
    },
    saveInitialPreferencesList(callback) {
        for(let i = 0; i < this.selectedItems.length; i++) {
            postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, PREFERENCE_URL_PART]), 
                preparePreferenceItem(this.selectedItems[i]), () => i === this.selectedItems.length - 1 && callback(), errorCallback, true);
        }
    },
};
