import React, { useEffect, useState } from 'react';
import ContainerTitle from './ContainerTitle';
import { FilterIcon } from '../../appearence/icons/filter_icon';
import CreateNewPreferenceButton from './CreateNewPreferenceButton';
import PreferenceListViewModeSwitcher from './PreferenceListViewModeSwitcher';
import { POPUP_KEY_PREFERENCES_FILTER } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import FiltersActiveList from '../filterComponents/FiltersActiveList';
import { preferencesStorage } from '../../services/preferencesStorage';
import OpenFilterButtonIcon from '../sharedComponents/OpenFilterButtonIcon';

const PreferencesListContainerHeader = () => {
    const [searchParamsLength, setSearchParamsLength] = useState(0);

    const changeFilter = () => {
        setSearchParamsLength(preferencesStorage.getCurrentFilters().length);
    };

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(changeFilter);
        return () => {
            preferencesStorage.unRegisterFiltersChangingObserver(changeFilter);
        };
    }, []);

    return (
        <header className="preference__header">
            <div className="preference__header-block">
                <ContainerTitle />
                <CreateNewPreferenceButton />
            </div>
            <div className="preference__header-icons">
                {searchParamsLength > 0 ? <FiltersActiveList itemStorage={preferencesStorage} /> : null}
                <span onClick={() => popupOpenService.callToOpen(POPUP_KEY_PREFERENCES_FILTER)} className="preference__filter-count">
                    <FilterIcon nameOfClass="preference__block-icon"/>
                    <OpenFilterButtonIcon itemsStorageService={preferencesStorage} />
                </span>
                <PreferenceListViewModeSwitcher />
            </div>
        </header>
    );
};

export default PreferencesListContainerHeader;
