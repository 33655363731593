import React from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import { i18n } from '../../i18n';
import { BookIcon } from '../../appearence/icons/book_icon';

const findPreferenceTitle = (preferenceId) => {
    if(preferencesStorage.getPreferenceById(preferenceId).preferenceType !== undefined) {
        return i18n.get(`preference.scheme.preferenceType.${preferencesStorage.getPreferenceById(preferenceId).preferenceType }`);
    } else {
        return '';
    }
};

const PreferencesListItemType = ({ preferenceId }) => {
    return (
        <div className="block__text preference__field">
            <BookIcon nameOfClass="preference__field-icon" />
            <label className="preference__field-label">
                <span className="block__content preference__field-title">{i18n.get('app.page.preference.addTitles.preferenceType')}</span>
                <span className='preference__field-content'>{findPreferenceTitle(preferenceId)}</span>
            </label>
        </div>
    );
};

export default PreferencesListItemType;
