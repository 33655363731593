import React, { useState, useCallback, useEffect, useRef } from 'react';
import { i18n } from '../../i18n';
import { DishIcon } from '../../appearence/icons/dish_icon';
import PreferenceItemCreateWhatActiveList from './PreferenceItemCreateWhatActiveList';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import PreferenceItemCreateWhatOptionList from './PreferenceItemCreateWhatOptionList';
import { MOUSE_DOWN_EVENT } from '../../constant';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import Loading from '../Loading';

const checkFieldIsFilled = (isLoading) => {
    return preferenceSaveService.getPreference().preferenceItemCodes && 
        preferenceSaveService.getPreference().preferenceItemCodes.length && isLoading?.table === false;
};

const PreferenceItemCreateWhatField = ({ isLoading, setIsLoading }) => {
    const wrapperRef = useRef(null);
    const [optionListIsOpen, setOptionListIsOpen] = useState(false);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOptionListIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    const openListWithVariants = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(true);
    };

    const closeOptionsList = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(prev => !prev);
    };

    return (
        <div className={`block__text preference__create-block ${checkFieldIsFilled(isLoading)  ? 'preference__create-block_active' : ''}`}>
            <DishIcon nameOfClass={`preference__create-icon ${checkFieldIsFilled(isLoading) ? 'preference__create-icon_active' : ''}`} />
            {isLoading.table ?
                <Loading />
                :
                <label className="preference__create-label" onClick={openListWithVariants} ref={wrapperRef}>
                    <span className="block__content preference__label-title">{i18n.get('app.page.preference.addTitles.preferenceItemCode')}</span>
                    <div className={`block__content select__choice select__choice_full-width ${checkFieldIsFilled(isLoading) ? 'select__choice_active' : ''} `}>
                        <PreferenceItemCreateWhatActiveList />
                        {optionListIsOpen ? 
                            <PreferenceItemCreateWhatOptionList setIsLoading={setIsLoading} />     
                            :
                            null}
                        <ChevronIcon handleClick={closeOptionsList}
                            nameOfClass={`select__choice-icon ${optionListIsOpen ? 'select__choice-icon_active' : ''} select__choice-icon_full-width`} />
                    </div>
                </label>}
        </div>
    );
};

export default PreferenceItemCreateWhatField;
