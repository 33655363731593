import React, { useState } from "react";
import MainMenu from "./MainMenu";
import Header from "./Header";
import { TABLET_WIDTH } from "../constant";
import useWindowDimensions from "../hooks/useWindowDimension";

const WithWrapper = (Component, setAccessToken) => {
    const WrappedComponent = (props) => {
        const [menuIsOpen, setMenuIsOpen] = useState(false);
        const { width } = useWindowDimensions();
        return (
            <>
                {width <= TABLET_WIDTH ? <Header setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} /> : null}
                <main className="main">
                    {(width <= TABLET_WIDTH && menuIsOpen) || width > TABLET_WIDTH ?
                        <MainMenu setAccessToken={setAccessToken} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
                        :
                        null}
                    <Component {...props} />
                </main>
            </>
        );
    };
    return WrappedComponent;
};

export default WithWrapper;
