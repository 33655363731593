import React, { useContext } from 'react';
import { ThemeContext } from '../providers/ThemeProvider';
import { MoonIcon } from '../appearence/icons/moon_icon';
import { SunIcon } from '../appearence/icons/sun_icon';

const ThemeSwitcher = () => {
    const { theme, setTheme } = useContext(ThemeContext);
    return (
        <div key={theme} className={`theme__container ${theme === 'dark' ? 'theme__container_active' : ''}`} 
            onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
            <p className={`theme__round ${theme === 'dark' ? 'theme__round_active' : ''}`}>
                {theme === 'dark' ?
                    <MoonIcon nameOfClass="theme__icon" /> 
                    :
                    <SunIcon nameOfClass="theme__icon" />}
            </p>
        </div>
    );
};

export default ThemeSwitcher;
