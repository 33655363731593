import React, { useState, useEffect, useRef } from 'react';
import { i18n } from '../../i18n';
import SelectHandler from '../sharedComponents/SelectHandler';
import { BookIcon } from '../../appearence/icons/book_icon';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import Loading from '../Loading';

const createListOfOptions = () => {
    return [
        {
            id: 'product',
            name: i18n.get('preference.scheme.preferenceType.product'),
        },
        {
            id: 'recipe',
            name: i18n.get('preference.scheme.preferenceType.recipe'),
        },
        {
            id: 'cereal-mix',
            name: i18n.get('preference.scheme.preferenceType.cereal-mix'),
        },
    ];
};

const findPreferenceTitle = (isLoading, fieldValue) => {
    if(fieldValue && isLoading?.table === false) {
        return i18n.get(`preference.scheme.preferenceType.${fieldValue}`);
    } else {
        return '';
    }
};

const PreferenceItemCreateTypeField = ({ isLoading }) => {
    const [fieldValue, setFieldValue] = useState(preferenceSaveService.getPreference().preferenceType);
    const ref = useRef();
    const fillPreferenceField = (value) => {
        preferenceSaveService.fillPreferenceType(value);
    };

    const changeItemValue = () => {
        setFieldValue(preferenceSaveService.getPreference().preferenceType);
    }; 

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(changeItemValue);
        changeItemValue();
        return () => preferenceSaveService.unRegisterItemUpdateObserver(changeItemValue);
    }, []);

    return (
        <div className={`block__text preference__create-block ${findPreferenceTitle(isLoading, fieldValue) ? 'preference__create-block_active' : ''}`}>
            <BookIcon nameOfClass={`preference__create-icon ${findPreferenceTitle(isLoading, fieldValue) ? 'preference__create-icon_active' : ''}`}/>
            {isLoading.table ?
                <Loading />
                :
                <label className="preference__create-label" key={fieldValue} ref={ref}>
                    <span className="block__content preference__label-title">{i18n.get('app.page.preference.addTitles.preferenceType')}</span>
                    <SelectHandler 
                        selectedValue={findPreferenceTitle(isLoading, fieldValue)} 
                        optionsList={createListOfOptions()} 
                        sendSelectedValue={fillPreferenceField}
                        parentElement={ref} />
                </label>}
        </div>
    );
};

export default PreferenceItemCreateTypeField;
