import React, { useState, useEffect } from 'react';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import PreferenceItemCreateHowOftenListItem from './PreferenceItemCreateHowOftenListItem';
import { i18n } from '../../i18n';

const PreferenceItemCreateHowOftenList = ({ setIsLoading }) => {
    const [optionsListIsChanged, setOptionsListIsChanged] = useState(false);

    const changePreference = () => {
        setOptionsListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(changePreference);
        return () => preferenceSaveService.unRegisterItemUpdateObserver(changePreference);
    }, []);

    return (
        <ul className='scroll select__choice-dropdown select__choice-dropdown_full-width'>
            {preferenceSaveService.getSearchValue() ? 
                null 
                : 
                <p className="block__text select__choice-info">
                    {i18n.get('app.page.preference.oftenSelect')}:
                </p>}
            {preferenceSaveService.getPossibleHowOftenValues().length ? 
                preferenceSaveService.getPossibleHowOftenValues().map((it, index) => (
                    <React.Fragment key={it.name + index + optionsListIsChanged}>
                        <PreferenceItemCreateHowOftenListItem itemCode={it.id} setIsLoading={setIsLoading} />
                    </React.Fragment>
                ))
                :
                <li className="block__content select__choice-option select__choice-option_first">
                    {i18n.get('app.page.preference.notFound')}
                </li>}
        </ul>
    );
};

export default PreferenceItemCreateHowOftenList;
