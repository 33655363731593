import React, { useState, useEffect } from 'react';
import { initialPreferencesService } from '../../services/initialPreferencesService';
import InitialPreferencesListItem from './InitialPreferencesListItem';

const InitialPreferencesList = () => {
    const [preferencesListLength, setPreferencesListLength] = useState(0);

    const changePreferencesListLength = () => {
        setPreferencesListLength(initialPreferencesService.getInitialPreferences().length);
    };

    useEffect(() => {
        initialPreferencesService.loadListOfPossibleInitialPreferences(changePreferencesListLength);
    }, []);

    return (
        <ul className="preference__initial-list">
            {initialPreferencesService.getInitialPreferences().map(preference => (
                <React.Fragment key={preference.id + preferencesListLength} >
                    <InitialPreferencesListItem preferenceId={preference.id} />
                </React.Fragment>
            ))}
        </ul>
    );
};

export default InitialPreferencesList;
