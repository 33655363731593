import {CHAPCHE_API_URL} from "./constant";
import {GET_CURRENT_USER_REQUEST, getFetchRequest} from "./store/requests";

export const successCallbackEmpty = () => {
};

export const errorCallback = (error) => {
    console.log(error);
    getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), successCallbackEmpty, () => {
        window.location.href = '/login';
    });
};

export const makeUrl = (params) => {
    return `${CHAPCHE_API_URL}` + params.join('');
};
