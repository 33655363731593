import React, { useState } from 'react';
import { initialPreferencesService } from '../../services/initialPreferencesService';

const InitialPreferencesListItem = ({ preferenceId }) => {
    const [preferenceIsSelected, setPreferenceIsSelected] = useState(false);

    const changeSelectedState = () => {
        if(preferenceIsSelected) {
            setPreferenceIsSelected(false);
            initialPreferencesService.deletePreferenceFromList(preferenceId);
        } else {
            setPreferenceIsSelected(true);
            initialPreferencesService.addPreferenceInList(preferenceId);
        }
    };

    return (
        <li className={`block__content preference__initial-item ${preferenceIsSelected ? 'preference__initial-item_active' : ''}`} 
            onClick={changeSelectedState}>
            {initialPreferencesService.getInitialPreferences().find(it => it.id === preferenceId).content}
        </li>
    );
};

export default InitialPreferencesListItem;
