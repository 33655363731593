import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Checkbox from '../sharedComponents/Checkbox';

const PossibleFiltersListItem = ({ filter, itemStorage }) => {
    const [filterValue, setFilterValue] = useState(filter.value);
    const [searchParams] = useSearchParams();

    const changeFilterValue = (value) => {
        setFilterValue(value);
        itemStorage.changeFilterValue(value, filter.code);
        searchParams.set(filter.code, value);
    };

    const changeFilter = () => {
        setFilterValue(itemStorage.getCurrentFilters().findIndex(it => it.code === filter.code) >= 0);
    };

    useEffect(() => {
        itemStorage.registerFiltersChangingObserver(changeFilter);
        changeFilter();
        return () => {
            itemStorage.unRegisterFiltersChangingObserver(changeFilter);
        };
    }, []);

    return (
        <li className='preference__filter-checkbox'>
            <Checkbox checked={filterValue} changeCheckboxValue={changeFilterValue} />
            <label className='block__content'>{filter.title}</label>
        </li>
    );
};

export default PossibleFiltersListItem;
