import React, { useEffect, useState, useRef } from 'react';
import { ArrowIcon } from '../appearence/icons/arrow_icon';
import { i18n } from '../i18n';
import { MOBILE_WIDTH, ONE_DAY, WEEK_DAYS_COUNT } from '../constant';
import { getDate } from './helper';

const WEEK_DAYS_SEPARATOR = 8;
const VISIBLE_DAYS_COUNT = 10;
const VISIBLE_DAYS_COUNT_LEFT = 4;
const VISIBLE_WEEKS_DAYS_COUNT = 49;
const ONE_DAY_COUNT = 1;
const ONE_MONTH_IN_DAYS = 28;
const ZERO_COUNT = 0;

const SCROLL_LEFT_MOBILE_VALUE = 70;
const BASE_SCROLL_STEP = 100;
const MARGIN_BETWEEN_DAYS = 10;

const DATE_TIME_NOW = Date.now();

const localizedDaysOfWeek = new Map([
    [0, "su"],
    [1, "mo"],
    [2, "tu"],
    [3, "we"],
    [4, "th"],
    [5, "fr"],
    [6, "sa"],
]);

const localizeDayOfWeek = (dayNumber) => {
    const dayName = i18n.get(`days.scheme.daysOfWeek.${localizedDaysOfWeek.get(dayNumber)}`);
    return dayName || "";
};

const formatDate = (dateItem, week = null) => {
    const date = new Date(dateItem);
    const nextDate = new Date(dateItem + (ONE_DAY * WEEK_DAYS_COUNT));
    return (
        <>
            <span className="day_text">{date.getDate()}{week && <span>-{nextDate.getDate()}</span>}</span>
            <span className="day_text">{localizeDayOfWeek(date.getDay())}{week && <span>-{localizeDayOfWeek(nextDate.getDay())}</span>}</span>
        </>
    );
};

const needToChangeMonth = (visibleElem, step) => {
    if (step > 0) {
        return  new Date(+visibleElem.dataset.day).getDate() >= ONE_DAY_COUNT && new Date(+visibleElem.dataset.day).getDate();
    } else {
        return  new Date(+visibleElem.dataset.day).getDate() <= ONE_MONTH_IN_DAYS && new Date(+visibleElem.dataset.day).getDate();
    }
};

const findVisibleElem = (item) => {
    let visibleElem = null;
    let sortedArray = [...item.children];
    sortedArray = sortedArray.sort((a, b) => {
        if (a.getBoundingClientRect().left > b.getBoundingClientRect().left) return 1;
        if (a.getBoundingClientRect().left < b.getBoundingClientRect().left) return -1;
    });
    for (let i = 0; i < sortedArray.length; i++) {
        if (visibleElem === null) {
            if (sortedArray[i].getBoundingClientRect().left > 0) {
                visibleElem = sortedArray[i];
            }
        }
    }
    return visibleElem;
};

const findActiveDay = (selectedDate, date) => {
    if(selectedDate.length <= WEEK_DAYS_COUNT) {
        return selectedDate.includes(getDate(date));
    } else {
        return selectedDate.includes(getDate(date)) ||
            ((date < new Date(selectedDate[0]).getTime()) && (new Date(selectedDate[0]).getTime() < date + ONE_DAY * 7));
    }
};

const findDifferenceValue = (startDate, endDate) => Math.floor((new Date(startDate).getTime() - new Date(endDate).getTime()) / ONE_DAY) + 4;

const Days = ({ selectedDate, changeSelectedDate, width, setCurrentMonth }) => {
    const [visibleDays, setVisibleDays] = useState([
        DATE_TIME_NOW - 4 * ONE_DAY,
        DATE_TIME_NOW - 3 * ONE_DAY,
        DATE_TIME_NOW - 2 * ONE_DAY,
        DATE_TIME_NOW - 1 * ONE_DAY,
        DATE_TIME_NOW,
        DATE_TIME_NOW + 1 * ONE_DAY,
        DATE_TIME_NOW + 2 * ONE_DAY,
        DATE_TIME_NOW + 3 * ONE_DAY,
        DATE_TIME_NOW + 4 * ONE_DAY,
        DATE_TIME_NOW + 5 * ONE_DAY,
        DATE_TIME_NOW + 6 * ONE_DAY,
        DATE_TIME_NOW + 7 * ONE_DAY,
        DATE_TIME_NOW + 8 * ONE_DAY,
        DATE_TIME_NOW + 9 * ONE_DAY,
        DATE_TIME_NOW + 10 * ONE_DAY,
        DATE_TIME_NOW + 11 * ONE_DAY,
        DATE_TIME_NOW + 12 * ONE_DAY,
    ]);
    const mouseDownInterval = useRef();
    const calendarItem = useRef();
    const [currentScrollStep, setCurrentScrollStep] = useState(0);

    const clickHandler = (item) => {
        const preparedData = new Date(item);
        preparedData.setHours(5, 0, 0);
        const dataInMs = preparedData.getTime();
        changeSelectedDate(dataInMs, true);
    };

    useEffect(() => {
        mouseDownInterval.current = -1;
        calendarItem.current = document.querySelector('.day__line');
        calendarItem.current.scrollLeft = 280;
    }, []);

    useEffect(() => {
        let lastElemCount = (visibleDays.slice(-1) - DATE_TIME_NOW) / ONE_DAY + ONE_DAY_COUNT;
        if (selectedDate.length > WEEK_DAYS_COUNT && lastElemCount < VISIBLE_WEEKS_DAYS_COUNT) {
            for (let i = lastElemCount; i < VISIBLE_WEEKS_DAYS_COUNT; i++) {
                newCalendarArray.push(DATE_TIME_NOW + i * ONE_DAY);
            }
            setVisibleDays([...visibleDays, ...newCalendarArray]);
        } else if (new Date(selectedDate[0]).getTime() < visibleDays[0]) {
            let firstElemCount = (DATE_TIME_NOW - visibleDays[0]) / ONE_DAY + ONE_DAY_COUNT;
            for (let i = firstElemCount; i < firstElemCount + findDifferenceValue(getDate(visibleDays[0]), selectedDate[0]); i++) {
                visibleDays.unshift(DATE_TIME_NOW - i * ONE_DAY);
                visibleDays.pop();
            }
            setVisibleDays([...visibleDays]);
        }
    }, [selectedDate]);

    let newCalendarArray = [];

    const scrollHandler = (e, isMobile = false) => {
        if((currentScrollStep === 1 || isMobile) && calendarItem.current.scrollLeft === (calendarItem.current.scrollWidth - calendarItem.current.offsetWidth)) {
            let lastElemCount = (visibleDays.slice(-1) - DATE_TIME_NOW) / ONE_DAY + ONE_DAY_COUNT;
            for (let i = lastElemCount; i < visibleDays.length + VISIBLE_DAYS_COUNT; i++) {
                newCalendarArray.push(DATE_TIME_NOW + i * ONE_DAY);
            }
            setVisibleDays([...visibleDays, ...newCalendarArray]);
        } else if((currentScrollStep === -1 && calendarItem.current.scrollLeft <= 0) 
                || (isMobile && calendarItem.current.scrollLeft <= SCROLL_LEFT_MOBILE_VALUE)) {
            let firstElemCount = (DATE_TIME_NOW - visibleDays[0]) / ONE_DAY + ONE_DAY_COUNT;
            for (let i = firstElemCount; i < firstElemCount + VISIBLE_DAYS_COUNT_LEFT; i++) {
                visibleDays.unshift(DATE_TIME_NOW - i * ONE_DAY);
                visibleDays.pop();
            }
            let dayWidth = calendarItem.current.children[0].getBoundingClientRect().width;
            let scrollValue = (dayWidth + MARGIN_BETWEEN_DAYS) * (VISIBLE_DAYS_COUNT_LEFT);
            setTimeout(() => {
                setVisibleDays([...visibleDays]);
                calendarItem.current.scrollTo(scrollValue, 0);
            }, 0);
        }
    };

    const handleScroll = (e) => {
        if (e.deltaY > ZERO_COUNT) calendarItem.current.scrollLeft += BASE_SCROLL_STEP;
        else calendarItem.current.scrollLeft -= BASE_SCROLL_STEP;
    };

    function mousedown(e, step) {
        calendarItem.current = document.querySelector('.day__line');
        setCurrentScrollStep(step);
        if (mouseDownInterval.current === -1) {
            mouseDownInterval.current = setInterval(() => whilemousedown(step));
        }
    }

    function whilemousedown(step) {
        calendarItem.current.scrollLeft += step * 2;
        let visibleElem = findVisibleElem(calendarItem.current);
        if (visibleElem) {
            needToChangeMonth(visibleElem, step) && respondToVisibility(visibleElem, +visibleElem.dataset.day, callBackFunction);
        }
    }

    function respondToVisibility(element, value, callback) {
        const options = {
            root: document.documentElement,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                callback(entry.intersectionRatio > ZERO_COUNT, value);
            });
        }, options);

        observer.observe(element);
    }

    const callBackFunction = (entry, value) => {
        if (entry) {
            setCurrentMonth(new Date(value).getMonth());
        }
    };

    function mouseup() {
        clearInterval(mouseDownInterval.current);
        mouseDownInterval.current = -1;
    }

    return (
        <>
            {width > MOBILE_WIDTH ?
                <ArrowIcon nameOfClass="day__scroll" handleMouseDown={mousedown} handleMouseUp={mouseup} value={-1} handleClick={null} />
                :
                null}
            <ul className="day__line" id="scroller" key={selectedDate.length + 'selected'}
                onScroll={scrollHandler} onWheel={handleScroll} onTouchStart={(e) => scrollHandler(e, true)}>
                {visibleDays.map((it, index) => {
                    return (
                        (selectedDate.length > WEEK_DAYS_COUNT && index % WEEK_DAYS_SEPARATOR === ZERO_COUNT) || index === ZERO_COUNT || selectedDate.length <= WEEK_DAYS_COUNT ?
                            <li data-day={it} key={it + index} onClick={() => clickHandler(it)} onTouchStart={(e) => respondToVisibility(e.target, it, callBackFunction)}
                                onMouseEnter={(e) => respondToVisibility(e.target, it, callBackFunction)}
                                className={`day ${findActiveDay(selectedDate, it) ? 'day_active' : ''}`}>
                                {selectedDate.length > WEEK_DAYS_COUNT ? formatDate(it, true) : formatDate(it)}
                            </li>
                            :
                            null
                    );
                })}
            </ul>
            {width > MOBILE_WIDTH ?
                <ArrowIcon nameOfClass="day__scroll day__scroll_right" handleMouseDown={mousedown} handleMouseUp={mouseup} value={ONE_DAY_COUNT} handleClick={null} />
                :
                null}
        </>
    );
};

export default Days;
