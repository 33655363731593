import React from "react";

export const SunIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.104492" width="20" height="20" rx="10" fill="white" />
        <g clipPath="url(#clip0_9083_8891)">
            <path d="M10 13.6045C10.9283 13.6045 11.8185 13.2357 12.4749 12.5794C13.1313 11.923 13.5 11.0328 13.5 10.1045C13.5 9.17623 13.1313 8.286 12.4749 7.62962C11.8185 6.97324 10.9283 6.60449 10 6.60449C9.07174 6.60449 8.1815 6.97324 7.52513 7.62962C6.86875 8.286 6.5 9.17623 6.5 10.1045C6.5 11.0328 6.86875 11.923 7.52513 12.5794C8.1815 13.2357 9.07174 13.6045 10 13.6045ZM10 3.10449C10.116 3.10449 10.2273 3.15059 10.3094 3.23263C10.3914 3.31468 10.4375 3.42596 10.4375 3.54199V5.29199C10.4375 5.40802 10.3914 5.5193 10.3094 5.60135C10.2273 5.6834 10.116 5.72949 10 5.72949C9.88397 5.72949 9.77269 5.6834 9.69064 5.60135C9.60859 5.5193 9.5625 5.40802 9.5625 5.29199V3.54199C9.5625 3.42596 9.60859 3.31468 9.69064 3.23263C9.77269 3.15059 9.88397 3.10449 10 3.10449ZM10 14.4795C10.116 14.4795 10.2273 14.5256 10.3094 14.6076C10.3914 14.6897 10.4375 14.801 10.4375 14.917V16.667C10.4375 16.783 10.3914 16.8943 10.3094 16.9764C10.2273 17.0584 
                10.116 17.1045 10 17.1045C9.88397 17.1045 9.77269 17.0584 9.69064 16.9764C9.60859 16.8943 9.5625 16.783 9.5625 16.667V14.917C9.5625 14.801 9.60859 14.6897 9.69064 14.6076C9.77269 14.5256 9.88397 14.4795 10 14.4795ZM17 10.1045C17 10.2205 16.9539 10.3318 16.8719 10.4139C16.7898 10.4959 16.6785 10.542 16.5625 10.542H14.8125C14.6965 10.542 14.5852 10.4959 14.5031 10.4139C14.4211 10.3318 14.375 10.2205 14.375 10.1045C14.375 9.98846 14.4211 9.87718 14.5031 9.79513C14.5852 9.71309 14.6965 9.66699 14.8125 9.66699H16.5625C16.6785 9.66699 16.7898 9.71309 16.8719 9.79513C16.9539 9.87718 17 9.98846 17 10.1045ZM5.625 10.1045C5.625 10.2205 5.57891 10.3318 5.49686 10.4139C5.41481 10.4959 5.30353 10.542 5.1875 10.542H3.4375C3.32147 10.542 3.21019 10.4959 3.12814 10.4139C3.04609 10.3318 3 10.2205 3 10.1045C3 9.98846 3.04609 9.87718 3.12814 9.79513C3.21019 9.71309 3.32147 9.66699 3.4375 9.66699H5.1875C5.30353 9.66699 5.41481 9.71309 
                5.49686 9.79513C5.57891 9.87718 5.625 9.98846 5.625 10.1045ZM14.9499 5.15462C15.0319 5.23666 15.078 5.34792 15.078 5.46393C15.078 5.57994 15.0319 5.6912 14.9499 5.77324L13.7126 7.01137C13.6719 7.05199 13.6237 7.0842 13.5705 7.10616C13.5174 7.12812 13.4605 7.1394 13.403 7.13936C13.2869 7.13928 13.1756 7.09308 13.0936 7.01093C13.0529 6.97025 13.0207 6.92197 12.9988 6.86885C12.9768 6.81572 12.9655 6.75879 12.9656 6.70131C12.9657 6.58521 13.0118 6.4739 13.094 6.39187L14.3313 5.15462C14.4133 5.0726 14.5246 5.02652 14.6406 5.02652C14.7566 5.02652 14.8678 5.0726 14.9499 5.15462ZM6.906 13.1985C6.98802 13.2805 7.03409 13.3918 7.03409 13.5078C7.03409 13.6238 6.98802 13.7351 6.906 13.8171L5.66875 15.0544C5.58624 15.1341 5.47572 15.1782 5.36101 15.1772C5.2463 15.1762 5.13657 15.1302 5.05545 15.049C4.97434 14.9679 4.92833 14.8582 4.92733 14.7435C4.92633 14.6288 4.97043 14.5183 5.05012 14.4357L6.28737 13.1985C6.36942 13.1165 6.48068 
                13.0704 6.59669 13.0704C6.7127 13.0704 6.82396 13.1165 6.906 13.1985ZM14.9499 15.0544C14.8678 15.1364 14.7566 15.1825 14.6406 15.1825C14.5246 15.1825 14.4133 15.1364 14.3313 15.0544L13.094 13.8171C13.0143 13.7346 12.9702 13.6241 12.9712 13.5094C12.9722 13.3947 13.0182 13.2849 13.0993 13.2038C13.1804 13.1227 13.2902 13.0767 13.4049 13.0757C13.5196 13.0747 13.6301 13.1188 13.7126 13.1985L14.9499 14.4357C15.0319 14.5178 15.078 14.629 15.078 14.7451C15.078 14.8611 15.0319 14.9723 14.9499 15.0544ZM6.906 7.01137C6.82396 7.09339 6.7127 7.13946 6.59669 7.13946C6.48068 7.13946 6.36942 7.09339 6.28737 7.01137L5.05012 5.77324C5.00834 5.73288 4.97501 5.68461 4.95208 5.63123C4.92915 5.57785 4.91708 5.52045 4.91658 5.46236C4.91607 5.40426 4.92714 5.34665 4.94914 5.29289C4.97114 5.23912 5.00362 5.19027 5.0447 5.14919C5.08578 5.10812 5.13463 5.07563 5.1884 5.05363C5.24216 5.03163 5.29977 5.02057 5.35786 5.02107C5.41595 5.02157 5.47336 
                5.03364 5.52674 5.05657C5.58012 5.0795 5.62839 5.11283 5.66875 5.15462L6.906 6.39187C6.94674 6.43251 6.97907 6.48079 7.00112 6.53394C7.02318 6.58709 7.03453 6.64407 7.03453 6.70162C7.03453 6.75916 7.02318 6.81614 7.00112 6.8693C6.97907 6.92245 6.94674 6.97073 6.906 7.01137Z" fill="#24312D" />
        </g>
        <defs>
            <clipPath id="clip0_9083_8891">
                <rect width="14" height="14" fill="white" transform="translate(3 3.10449)" />
            </clipPath>
        </defs>
    </svg>
);
