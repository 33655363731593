import React, { useState, useEffect } from 'react';
import { useCalendar } from './useCalendar';
import { ChevronIcon } from '../appearence/icons/chevron_icon';
import { getDate } from './helper';
import { ONE_DAY } from '../constant';
import { i18n } from '../i18n';
const ONE_HOUR_IN_MS = 3600000;

const checkDateIsEqual = (date1, date2) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

const checkIsToday = (date) => {
    const today = new Date();
    return checkDateIsEqual(today, date);
};

export const createDate = (params) => {
    const locale = i18n.APP_LOCALE;
    const d = new Date(params?.date) ?? new Date();
    const dayNumber = d.getDate();
    const day = d.toLocaleDateString(locale, { weekday: 'long' });
    const dayNumberInWeek = d.getDay() + 1;
    const dayShort = d.toLocaleDateString(locale, { weekday: 'short' });
    const year = d.getFullYear();
    const yearShort = d.toLocaleDateString(locale, { year: '2-digit' });
    const month = d.toLocaleDateString(locale, { month: 'long' });
    const monthShort = d.toLocaleDateString(locale, { month: 'short' });
    const monthNumber = d.getMonth() + 1;
    const monthIndex = d.getMonth();
    const timestamp = d.getTime();
    const week = getWeekNumber(d);

    return {
        date: d,
        dayNumber,
        day,
        dayNumberInWeek,
        dayShort,
        year,
        yearShort,
        month,
        monthShort,
        monthNumber,
        monthIndex,
        timestamp,
        week,
    };
};

export const createMonth = (params) => {
    const date = params?.date ?? new Date();
    const locale = i18n.APP_LOCALE;

    const d = createDate({ date, locale });
    const { month: monthName, year, monthNumber, monthIndex } = d;

    const getDay = (dayNumber) =>
        createDate({ date: new Date(year, monthIndex, dayNumber), locale });

    const createMonthDays = () => {
        const days = [];

        for (let i = 0; i <= getMonthNumberOfDays(monthIndex, year) - 1; i += 1) {
            days[i] = getDay(i + 1);
        }

        return days;
    };

    return {
        getDay,
        monthName,
        monthIndex,
        monthNumber,
        year,
        createMonthDays,
    };
};

export const createYear = (params) => {
    const locale = i18n.APP_LOCALE;

    const monthCount = 12;
    const today = createDate();

    const year = params?.year ?? today.year;
    const monthNumber = params?.monthNumber ?? today.monthNumber;

    const month = createMonth({ date: new Date(year, monthNumber - 1), locale });

    const getMonthDays = (monthIndex) =>
        createMonth({ date: new Date(year, monthIndex), locale }).createMonthDays();

    const createYearMonthes = () => {
        const monthes = [];

        for (let i = 0; i <= monthCount - 1; i += 1) {
            monthes[i] = getMonthDays(i);
        }

        return monthes;
    };

    return {
        createYearMonthes,
        month,
        year,
    };
};

export const getMonthNumberOfDays = (
    monthIndex,
    yearNumber = new Date().getFullYear(),
) => new Date(yearNumber, monthIndex + 1, 0).getDate();

export const getMonthesNames = () => {
    const monthesNames = [];

    for (let i = 12; i > 0; i--) {
        monthesNames.push({
            month: 'month',
            monthShort: 'monthShort',
            monthIndex: 'monthIndex',
            date: 'date',
        });
    }

    const d = new Date();

    monthesNames.forEach((_, i) => {
        const { month, monthIndex, monthShort, date } = createDate({
            date: new Date(d.getFullYear(), d.getMonth() + i, 1),
        });

        monthesNames[monthIndex] = { month, monthIndex, monthShort, date };
    });

    return monthesNames;
};


export const getWeekDaysNames = (firstWeekDay = 4) => {
    const weekDaysNames = [];

    for (let i = 7; i > 0; i--) {
        weekDaysNames.push({
            day: ['day'],
            dayShort: ['dayShort'],
        });
    }

    const date = new Date();

    weekDaysNames.forEach((_, i) => {
        const { day, dayNumberInWeek, dayShort } = createDate({
            date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + i),
        });

        weekDaysNames[dayNumberInWeek - 1] = { day, dayShort };
    });

    return [...weekDaysNames.slice(firstWeekDay - 1), ...weekDaysNames.slice(0, firstWeekDay - 1)];
};

export const getWeekNumber = (date) => {
    const firstDayOfTheYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfTheYear.getTime()) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfTheYear.getDay() + 1) / 7);
};

const findDifferentBetweenStartAndEnd = (startDate, endDate) => Math.floor((endDate.timestamp + 6 * ONE_HOUR_IN_MS - new Date(startDate).getTime()) / ONE_DAY);

const Calendar = ({
    selectedDate,
    setSelectedDate,
    firstWeekDayNumber = 2,
}) => {
    const { functions, state } = useCalendar({
        selectedDate,
        firstWeekDayNumber,
    });
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            const differenceBetweenStartAndEnd = findDifferentBetweenStartAndEnd(selectedDate[selectedDate.length - 1], state.selectedDay);
            if (differenceBetweenStartAndEnd > 1) {
                [...Array(differenceBetweenStartAndEnd)]
                    .forEach((item, index) => setSelectedDate(new Date(state.selectedDay.date).getTime() + 6 * ONE_HOUR_IN_MS - index * ONE_DAY));
            }
            setSelectedDate(new Date(state.selectedDay.date).getTime() + 6 * ONE_HOUR_IN_MS);
            setTimeout(() => {
                document.getElementsByClassName("day_active")[0]?.scrollIntoView({ block: "end", inline: "start" });
            });
        }
    }, [state.selectedDay]);

    return (
        <>
            <div className='calendar__header'>
                <div aria-hidden onClick={() => functions.onClickArrow('left')}>
                    <ChevronIcon nameOfClass='calendar__header-arrow_left' />
                </div>
                <h2 className="block__content calendar__title">
                    {state.monthesNames[state.selectedMonth.monthIndex].month} {state.selectedYear}
                </h2>
                <div aria-hidden onClick={() => functions.onClickArrow('right')}>
                    <ChevronIcon nameOfClass='calendar__header-arrow_right' />
                </div>
            </div>
            <div className='calendar__body'>
                <div className='block__text calendar__days'>
                    {state.calendarDays.map((day) => {
                        const isToday = checkIsToday(day.date);
                        const isSelectedDay = selectedDate.includes(getDate(day.timestamp + ONE_DAY));
                        const isAdditionalDay = day.monthIndex !== state.selectedMonth.monthIndex;
                        return (
                            <div
                                key={`${day.dayNumber}-${day.monthIndex}`}
                                aria-hidden
                                onClick={() => {
                                    functions.setSelectedDay(day);
                                }}
                                className={[
                                    'calendar__day',
                                    isToday ? 'calendar__today' : '',
                                    isSelectedDay ? 'calendar__selected' : '',
                                    isAdditionalDay ? 'calendar__additional' : '',
                                ].join(' ')}
                            >
                                {day.dayNumber}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Calendar;
