import React from 'react';
import { ClockIcon } from '../../appearence/icons/clock_icon';
import { preferencesStorage } from '../../services/preferencesStorage';
import { i18n } from '../../i18n';

const findActivityPeriodsValue = (preferenceId) => {
    const activityPeriods = preferencesStorage.getPreferenceById(preferenceId).activityPeriods;
    if( activityPeriods && activityPeriods.length) {
        activityPeriods.sort((a,b) => new Date(b.dateStop) - new Date(a.dateStop));
        return activityPeriods[0].dateStop.split('T')[0];
    } else {
        return '';
    }
};

const PreferencesListItemActivityTime = ({ preferenceId }) => {
    return (
        <p className="block__content preference__list-time">
            <ClockIcon nameOfClass="preference__list-clock" />
            <span className="preference__list-date">
                {preferencesStorage.getPreferenceById(preferenceId).isPermanent === true ? 
                    i18n.get('app.page.preference.always')
                    :
                    i18n.get('app.page.preference.until')} 
                {findActivityPeriodsValue(preferenceId)}
            </span>
        </p>
    );
};

export default PreferencesListItemActivityTime;
