import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PreferencesListContainerHeader from './PreferencesListContainerHeader';
import PreferencesList from './PreferencesList';
import { preferencesStorage } from '../../services/preferencesStorage';
import DeletePreferencePopup from './DeletePreferencePopup';
import { preferenceSaveService } from '../../services/preferenceSaveService';

const PreferenceListContainer = () => {
    const [searchParams] = useSearchParams();
    const updatePreferencesList = () => {
        preferencesStorage.loadItems(() => {});
        preferenceSaveService.loadPreference(() => preferencesStorage.getInitialFilters(searchParams));
    };

    useEffect(() => {
        updatePreferencesList();
    }, []);
    return (
        <>
            <article className="preference__article preference__article-add">
                <PreferencesListContainerHeader />
                <PreferencesList />
            </article>
            <DeletePreferencePopup notifyPreferencesList={updatePreferencesList} />
        </>
    );
};

export default PreferenceListContainer;
