import React from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import { i18n } from '../../i18n';
import { CalendarIcon } from '../../appearence/icons/calendar_icon';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { createListOfOptions } from './PreferenceItemCreateWhenField';

const findPreferenceTitle = (preferenceId) => {
    let result = '';
    if(preferencesStorage.getPreferenceById(preferenceId).when) {
        result += createListOfOptions().find(it => it.id === preferencesStorage.getPreferenceById(preferenceId).when).name;
        result += ', ';
    }
    if(preferencesStorage.getPreferenceById(preferenceId).howOften) {
        result += preferenceSaveService.getPossibleHowOftenValues(true)
            .find(it => it.id === preferencesStorage.getPreferenceById(preferenceId).howOften.id).name;
    }
    return result;
};

const PreferencesListItemWhenAndHowOften = ({ preferenceId }) => {
    return (
        <div className="block__text preference__field">
            <CalendarIcon nameOfClass="preference__field-icon" />
            <label className="preference__field-label">
                <span className="block__content preference__field-title">{i18n.get('app.page.preference.addTitles.when').slice(0, -1)}, 
                    {' ' + i18n.get('app.page.preference.addTitles.howOften')}</span>
                <span className='preference__field-content'>{findPreferenceTitle(preferenceId)}</span>
            </label>
        </div>
    );
};

export default PreferencesListItemWhenAndHowOften;
