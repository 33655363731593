import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/logo_animation.json';

const AnimationLogo = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    
    return (
        <div className="onboarding__animation">
            <Lottie 
                options={defaultOptions}
                height={205}
                width={500}
                speed={0.7}
            />
        </div>
    );
};

export default AnimationLogo;
