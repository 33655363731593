import React from 'react';

const SelectListItem = ({ selectAnItem, listItem }) => (
    <li key={listItem.id + listItem.name}
        className="block__content select__choice-option"
        onClick={(e) => selectAnItem(e, listItem)}>
        {listItem.name}
    </li>
);

export default SelectListItem;
