import React from 'react';
import { i18n } from '../../i18n';
import Switch from '../sharedComponents/Switch';

const PreferenceDueDateHandler = ({ preferenceId }) => {
    return (
        <label className="preference__label preference__label_inline">
            <Switch value={false} changeIsVitaminValue={() => {}}/>
            <span className="preference__label-content">{i18n.get('app.page.preference.preferenceActivityPeriod')}</span>
        </label>
    );
};

export default PreferenceDueDateHandler;
