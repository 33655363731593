import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import HintItem from '../sharedComponents/HintItem';
import { XIcon } from '../../appearence/icons/x_icon';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { checkIsOverflowed } from '../../hooks/useOverflowed';

const FiltersActiveListItem = ({ filter, itemStorage }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [searchParams] = useSearchParams();
    const ref = useRef();
    const width = useWindowDimensions();

    useEffect(() => {
        setIsOverflowed(checkIsOverflowed(ref.current));
    }, [width]);


    const deleteFilterItem = () => {
        itemStorage.deleteFilterItem(filter.code);
        searchParams.delete(filter.code);
    };

    return (
        <li key={filter.code} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
            className="product__forms-item"
            onClick={deleteFilterItem}>
            <span className="block__bullet product__forms-text" ref={ref}>{filter.title}</span>
            <XIcon nameOfClass="product__forms-item-icon" />
            {isOverflowed && isHovered ? <HintItem message={filter.title} /> : null}
        </li>
    );
};

export default FiltersActiveListItem;
