import React, { useState, useEffect } from 'react';
import { ListIcon } from '../../appearence/icons/list_icon';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_TAB_NAME_LIST, PREFERENCE_PHRASE_TAB, PREFERENCE_TAB } from '../../constant';
import { PHRASE_URL_PART } from '../../store/requests';
import { UnionIcon } from '../../appearence/icons/union_icon';

const PreferenceListViewModeSwitcher = () => {
    const [currentViewMode, setCurrentViewMode] = useState(PREFERENCE_PHRASE_TAB);
    const {tab} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentViewMode(tab === PREFERENCE_PHRASE_TAB ? PREFERENCE_PHRASE_TAB : PREFERENCE_TAB);
    }, [tab]);

    const switchViewMode = () => {
        navigate(`/${PREFERENCE_TAB}${tab === PREFERENCE_PHRASE_TAB ? `/${PREFERENCE_TAB}/` : PHRASE_URL_PART}${PAGE_TAB_NAME_LIST}`);
    };

    return (
        <span onClick={switchViewMode}>
            {currentViewMode === PREFERENCE_PHRASE_TAB ?
                <UnionIcon nameOfClass="preference__block-icon"/>
                :
                <ListIcon nameOfClass="preference__block-icon"/>}
        </span>
    );
};

export default PreferenceListViewModeSwitcher;
