import React from "react";

export const CalendarIcon = ({ nameOfClass, handleClick, value }) => (
    <svg className={nameOfClass} onClick={(e) =>{
        e.stopPropagation();
        handleClick(e, value);
    }} viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.71429 0C6.50446 0 7.14286 0.614453 7.14286 1.375V2.75H12.8571V1.375C12.8571 0.614453 13.4955 0 14.2857 0C15.0759 0 15.7143 0.614453 15.7143 1.375V2.75H17.8571C19.0402 2.75 20 3.67383 20 4.8125V6.875H0V4.8125C0 3.67383 0.959821 2.75 2.14286 2.75H4.28571V1.375C4.28571 0.614453 4.92411 0 5.71429 0ZM0 8.25H20V19.9375C20 21.0762 19.0402 22 17.8571 22H2.14286C0.959821 22 0 21.0762 0 19.9375V8.25ZM2.85714 11.6875V13.0625C2.85714 13.4406 3.17857 13.75 3.57143 13.75H5C5.39286 13.75 5.71429 13.4406 5.71429 13.0625V11.6875C5.71429 11.3094 5.39286 11 5 11H3.57143C3.17857 11 2.85714 11.3094 2.85714 11.6875ZM8.57143 11.6875V13.0625C8.57143 13.4406 8.89286 13.75 9.28571 13.75H10.7143C11.1071 13.75 11.4286 13.4406 11.4286 13.0625V11.6875C11.4286 11.3094 11.1071 11 10.7143 11H9.28571C8.89286 11 8.57143 11.3094 8.57143 11.6875ZM15 11C14.6071 11 14.2857 11.3094 14.2857 11.6875V13.0625C14.2857 13.4406 14.6071 13.75 15 13.75H16.4286C16.8214 13.75 17.1429 13.4406 17.1429 13.0625V11.6875C17.1429 11.3094 16.8214 11 16.4286 11H15ZM2.85714 17.1875V18.5625C2.85714 18.9406 3.17857 19.25 3.57143 19.25H5C5.39286 19.25 5.71429 18.9406 5.71429 18.5625V17.1875C5.71429 16.8094 5.39286 16.5 5 16.5H3.57143C3.17857 16.5 2.85714 16.8094 2.85714 17.1875ZM9.28571 16.5C8.89286 16.5 8.57143 16.8094 8.57143 17.1875V18.5625C8.57143 18.9406 8.89286 19.25 9.28571 19.25H10.7143C11.1071 19.25 11.4286 18.9406 11.4286 18.5625V17.1875C11.4286 16.8094 11.1071 16.5 10.7143 16.5H9.28571ZM14.2857 17.1875V18.5625C14.2857 18.9406 14.6071 19.25 15 19.25H16.4286C16.8214 19.25 17.1429 18.9406 17.1429 18.5625V17.1875C17.1429 16.8094 16.8214 16.5 16.4286 16.5H15C14.6071 16.5 14.2857 16.8094 14.2857 17.1875Z"/>
    </svg>
);
