import { PREFERENCE_TYPE_CEREAL_MIX } from "../constant";
import { i18n } from "../i18n";
import { GET_USER_REQUEST, getFetchRequest, postFetchRequest } from "../store/requests";
import { preferenceSaveService } from "./preferenceSaveService";
import { userService } from "./userService";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";

const createStringOfNames = (listOfIngredientParts) => {
    const result = [];
    listOfIngredientParts.forEach(it => result.push(i18n.get(`product.item.code.${it.productCode}`)));
    return result.join(', ');
};

const createStringOfCodes = (listOfIngredientParts) => {
    const result = [];
    listOfIngredientParts.forEach(it => result.push(it.productCode));
    return result.join(',');
};

const isEqual = (a, b) => {
    if (a.size !== b.size) {
        return false;
    }
    a.forEach((item) => {
        if (!b.has(item)) {
            return false;
        }
    });
    return true;
};

const filterNotEqualValues = (item, cerealMixesArray) => {
    let result = true;
    cerealMixesArray.forEach(cerealMix => {
        if(isEqual(new Set(item.ingredientParts), new Set(cerealMix.ingredientParts))) {
            result = item.isOwned;
        }
    });
    return result;
};

const createCerealMixes = (response) => {
    const result = [];
    response.filter(it => filterNotEqualValues(it, response)).forEach(it => it.ingredientType === PREFERENCE_TYPE_CEREAL_MIX ? 
        result.push({id: createStringOfCodes(it.ingredientParts), name: createStringOfNames(it.ingredientParts)})
        :
        null);
    return result;
};

const prepareProductCodes = (listOfIngredients) => {
    const result = [];
    listOfIngredients.forEach(it => result.push({productCode: it.id}));
    return result;
};

export const preferenceCerealMixSelectService = {
    observersList: [],
    possibleItems: [],
    selectedItems: [],
    searchValue: '',
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    loadItems() {
        getFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, '/ingredient/']), (response) => {
            this.possibleItems = createCerealMixes(response);
            this.notifyItemsChangingObservers();
        }, errorCallback);
    },
    getPossibleItems() {
        if(this.searchValue) {
            return this.possibleItems.filter(it => it.name.toLowerCase().includes(this.searchValue.toLowerCase()));
        }
        return this.possibleItems;
    },
    getCurrentItems() {
        if(preferenceSaveService.getPreference().preferenceItemCodes && preferenceSaveService.getPreference().preferenceItemCodes.length) {
            this.selectedItems = [];
            preferenceSaveService.getPreference().preferenceItemCodes.forEach(it => 
                this.selectedItems.push({id: it.itemCode, name: i18n.get(`product.item.code.${it.itemCode}`)}));
        }
        return this.selectedItems;
    },
    deleteItem() {
        this.selectedItems = [];
        preferenceSaveService.fillPreferenceItemCerealMix();
        this.notifyItemsChangingObservers();
    },
    addItem(itemId) {
        if(itemId.includes(',')) {
            const cerealMixesArray = itemId.split(',');
            this.selectedItems = [];
            for(let cerealMix of cerealMixesArray) {
                this.selectedItems.push(preferenceSaveService.getProducts().find(it=> it.code === cerealMix));
            }
        } else {
            this.selectedItems.push(preferenceSaveService.getProducts().find(it=> it.id === itemId));
        }
        this.notifyItemsChangingObservers();
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.searchValue = searchValue;
        this.notifyItemsChangingObservers();
    },
    getPopupKey() {
        return '';
    },
    getButtonTitle() {
        return i18n.get("app.page.preference.addCerealMix");
    },
    addNewCerealMix() {
        const preparedIdArray = [];
        const preparedNameArray = [];
        this.selectedItems.forEach(it => {
            preparedIdArray.push(it.id);
            preparedNameArray.push(it.name);
        });
        this.possibleItems.unshift({id: preparedIdArray.join(','), name: preparedNameArray.join(', ')});
        const preparedBody = {
            ingredientType: PREFERENCE_TYPE_CEREAL_MIX,
            ingredientParts: prepareProductCodes(this.selectedItems),
        };
        this.selectedItems = [];
        postFetchRequest(makeUrl([GET_USER_REQUEST, userService.getUser().id, '/ingredient/']), preparedBody, successCallbackEmpty, errorCallback, true);
        this.notifyItemsChangingObservers();
    },
    clearService() {
        this.selectedItems = [];
    },
};
