import { sortByDate } from "../components/helper";
import { FREQUENCY_PERIOD_DAY_AFTER_DAY, FREQUENCY_PERIOD_EVERY_DAY, FREQUENCY_PERIOD_ONCE_IN_WEEK, PREFERENCE_TYPE_RECIPE } from "../constant";
import { i18n } from "../i18n";
import { GET_CURRENT_USER_REQUEST, GET_USER_REQUEST, PREFERENCE_URL_PART, getFetchRequest } from "../store/requests";
import { createPossibleHowOftenValues, createPossibleItemCodeValues, findHowOftenValue, preferenceSaveService } from "./preferenceSaveService";
import {errorCallback, makeUrl} from "../util";

const PREFERENCE_TYPE_PROPERTY_NAME = 'preferenceType.';
const PREFERENCE_FREQUENCY_PROPERTY_NAME = 'frequency';
const LIST_OF_FILTERS = ['when', 'include', 'preferenceType'];

const transformPreferencesList = (response) => {
    const resultArray = [];
    for(let preference of response) {
        resultArray.push({...preference, howOften: findHowOftenValue(preference.preferenceFrequency, preference.frequencyPeriod)});
    }
    return resultArray;
};

const transformForFilters = (productsList) => {
    const result = [];
    productsList.forEach(it => result.push({title: it.name, code: it.id, value: false}));
    return result;
};

const createPossibleFiltersList = (preferenceFilterType) => {
    const result = [
        {
            filterTitle: i18n.get('app.page.preference.addTitles.include'),
            filterName: 'include',
            values: [
                {
                    title: i18n.get('preference.scheme.include.true'),
                    code: 'include.true',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.include.false'),
                    code: 'include.false',
                    value: false,
                },
            ],
        },
        {
            filterTitle: i18n.get('app.page.preference.addTitles.preferenceType'),
            filterName: 'preferenceType',
            values: [
                {
                    title: i18n.get('preference.scheme.preferenceType.product'),
                    code: 'preferenceType.product',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.preferenceType.recipe'),
                    code: 'preferenceType.recipe',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.preferenceType.cereal-mix'),
                    code: 'preferenceType.cereal-mix',
                    value: false,
                },
            ],
        },
        {
            filterTitle: i18n.get('app.page.preference.addTitles.preferenceItemCode'),
            filterName: 'preferenceItemCode',
            values: [],
        },
        {
            filterTitle: i18n.get('app.page.preference.addTitles.when'),
            filterName: 'when',
            values: [
                {
                    title: i18n.get('preference.scheme.when.morning'),
                    code: 'when.morning',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.after-morning'),
                    code: 'when.after-morning',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.afternoon'),
                    code: 'when.afternoon',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.before-evening'),
                    code: 'when.before-evening',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.evening'),
                    code: 'when.evening',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.night'),
                    code: 'when.night',
                    value: false,
                },
                {
                    title: i18n.get('preference.scheme.when.no-matter'),
                    code: 'when.no-matter',
                    value: false,
                },
            ],
        },
        {
            filterTitle: i18n.get('app.page.preference.addTitles.howOften'),
            filterName: 'howOften',
            values: [
                {
                    code: `frequency.${FREQUENCY_PERIOD_EVERY_DAY}`,
                    title: i18n.get('preference.scheme.frequency.everyDay'),
                    value: false,
                },
                {
                    code: `frequency.${FREQUENCY_PERIOD_ONCE_IN_WEEK}`,
                    title: i18n.get('preference.scheme.frequency.onceInWeek'),
                    value: false,
                },
                {
                    code: `frequency.${FREQUENCY_PERIOD_DAY_AFTER_DAY}`,
                    title: i18n.get('preference.scheme.frequency.dayAfterDay'),
                    value: false,
                },
            ],
        },
    ];
    if(preferenceFilterType) {
        result[2].values = transformForFilters(createPossibleItemCodeValues(preferenceSaveService.productsList, preferenceFilterType));
    }
    return result;
};

const findFilterItemValue = (filterCode, preferenceFilterType) => {
    let result = {};
    createPossibleFiltersList(preferenceFilterType).find(it => it.values.forEach(item => item.code === filterCode ? result = item : null));
    return result;
};

const makeFilterGroupsObject = (filtersList) => {
    const result = {};
    filtersList.forEach(it => result[it.code.split('.')[0]] ? result[it.code.split('.')[0]].push(it) : result[it.code.split('.')[0]] = [it]);
    return result;
};

const filterPreferencesItem = (preferencesList, filtersList) => {
    const result = [];
    for(let preference of preferencesList) {
        let isValid = true;
        const filtersObject = makeFilterGroupsObject(filtersList);
        for(let key in filtersObject) {
            if(key === PREFERENCE_FREQUENCY_PROPERTY_NAME) {
                const currentPeriods = createPossibleHowOftenValues().filter(it => filtersObject[key].find(filter => filter.code.split('.')[1] === it.id));
                isValid = isValid && currentPeriods.find(it => +it.frequencyPeriod === +preference.frequencyPeriod && 
                    it.preferenceFrequency === preference.preferenceFrequency);
            } else if(LIST_OF_FILTERS.includes(key)) {
                isValid = isValid && filtersObject[key].find(it => preference[key].toString() === it.code.split('.')[1]);
            } else {
                isValid = isValid && preference.preferenceItemCodes.findIndex(it => filtersObject[key].find(filter => it.itemCode === filter.code)) >= 0;
            }
        }
        isValid ? result.push(preference) : null;
    }
    return [...new Map(result.map(item => [item.id, item])).values()];
};

export const preferencesStorage = {
    preferencesList: [],
    observersList: [],
    filterObserversList: [],
    filtersList: [],
    preferenceFilterType: PREFERENCE_TYPE_RECIPE,
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (user) => {
                getFetchRequest(makeUrl([GET_USER_REQUEST, user.id, PREFERENCE_URL_PART]), (response) => {
                    this.preferencesList = transformPreferencesList(response.sort(sortByDate));
                    callback && callback();
                    this.notifyFiltersChangingObservers();
                }, errorCallback);
            }, errorCallback);
            
        } catch(err) {
            console.log(err);
        }
    },
    getItems(getAllData) {
        if(this.filtersList.length && !getAllData) {
            return filterPreferencesItem(this.preferencesList, this.filtersList);
        }
        return this.preferencesList;
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getPreferenceById(preferenceId) {
        return this.preferencesList.find(it => it.id === preferenceId);
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getPossibleFilters() {
        return createPossibleFiltersList(this.preferenceFilterType);
    },
    changeFilterValue(value, filterCode) {
        if(filterCode.startsWith(PREFERENCE_TYPE_PROPERTY_NAME)) {
            this.preferenceFilterType = filterCode.split('.')[1];
        }
        if(value === true) {
            const currentFilter = findFilterItemValue(filterCode, this.preferenceFilterType);
            currentFilter.value = value;
            this.filtersList.push(currentFilter);
        } else {
            this.filtersList = this.filtersList.filter(it => it.code !== filterCode);
        }
        this.notifyFiltersChangingObservers();
    },
    deleteFilterItem(filterCode, cleanAll) {
        if(cleanAll) {
            this.filtersList = [];
            this.preferenceFilterType = PREFERENCE_TYPE_RECIPE;
        } else {
            this.filtersList = this.filtersList.filter(it => it.code !== filterCode);
        }
        this.notifyFiltersChangingObservers();
    },
    getInitialFilters(searchParams) {
        const filtersList = [];
        this.preferenceFilterType = PREFERENCE_TYPE_RECIPE;
        searchParams.forEach((value, key) => {
            if(key.startsWith(PREFERENCE_TYPE_PROPERTY_NAME)) {
                this.preferenceFilterType = key.split('.')[1];
            }
            const currentFilter = findFilterItemValue(key, this.preferenceFilterType);
            if(currentFilter.code && value === 'true') {
                currentFilter.value = value;
                filtersList.push(currentFilter);
            }
        });
        this.filtersList = filtersList;
        this.notifyFiltersChangingObservers();
    },
    getCurrentFilterPreferenceType() {
        return this.preferenceFilterType;
    },
};
