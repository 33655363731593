import React from 'react';

const CookiesIcon = () => (
    <svg className='plan__cookies' viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.0001 7.625C50.1106 7.62657 39.4821 10.9589 30.5409 17.1747C21.5997 23.3905 14.7737 32.1924 10.9788 42.3993C7.18389 52.6062 6.60174 63.7296 9.31047 74.2768C12.0192 84.8241 17.8892 94.2905 26.1327 101.406C34.3762 108.521 44.5988 112.944 55.4287 114.083C66.2586 115.221 77.1775 113.019 86.7202 107.774C96.2629 102.528 103.973 94.4886 108.815 84.7351C113.658 74.9815 115.401 63.9801 113.811 53.2073C113.717 52.5637 113.461 51.9548 113.065 51.4385C112.67 50.9222 112.149 50.5155 111.552 50.2572C110.956 49.9989 110.303 49.8976 109.656 49.9628C109.009 50.0281 108.389 50.2578 107.856 50.63C105.765 52.0896 103.342 53.0017 100.808 53.283C98.2741 53.5643 95.71 53.2058 93.3503 52.2402C90.9905 51.2746 88.9106 49.7328 87.3006 47.7558C85.6907 45.7788 84.6021 43.4297 84.1344 40.9234C83.9929 40.1654 83.625 39.4682 83.0791 38.9237C82.5332 38.3792 81.8351 38.013 81.0768 37.8734C78.5679 37.4069 76.2163 36.3183 74.2373 34.7073C72.2582 33.0964 70.7151 31.0145 69.7492 28.6526C68.7833 26.2906 68.4256 23.724 68.7088 21.188C68.992 18.6519 69.907 16.2275 71.3701 14.1368C71.7405 13.6036 71.9687 12.9848 72.033 12.3388C72.0972 11.6928 71.9955 11.0411 71.7374 10.4455C71.4793 9.84981 71.0733 9.32993 70.558 8.93509C70.0427 8.54025 69.4352 8.28353 68.7929 8.18925C66.2124 7.81248 63.608 7.6239 61.0001 7.625Z" fill="#DABBAB"/>
        <ellipse cx="39.6492" cy="35.8296" rx="6.1" ry="5.3375" transform="rotate(39 39.6492 35.8296)" fill="#896A5A"/>
        <ellipse cx="61.0007" cy="60.23" rx="6.1" ry="5.3375" transform="rotate(39 61.0007 60.23)" fill="#896A5A"/>
        <circle cx="79.3" cy="18.3" r="3.05" fill="#DABBAB"/>
        <circle cx="97.6008" cy="9.14961" r="3.05" fill="#DABBAB"/>
        <circle cx="102.025" cy="20.525" r="1.525" fill="#DABBAB"/>
        <circle cx="103.55" cy="36.05" r="3.05" fill="#DABBAB"/>
        <circle cx="96.0758" cy="41.1754" r="1.525" fill="#DABBAB"/>
        <ellipse cx="85.3992" cy="75.48" rx="6.1" ry="5.3375" transform="rotate(39 85.3992 75.48)" fill="#896A5A"/>
        <ellipse cx="30.5007" cy="69.3804" rx="6.1" ry="5.3375" transform="rotate(39 30.5007 69.3804)" fill="#896A5A"/>
        <ellipse cx="51.8503" cy="93.7798" rx="6.1" ry="5.3375" transform="rotate(39 51.8503 93.7798)" fill="#896A5A"/>
    </svg>
);

export default CookiesIcon;
