export const LOGOUT_REQUEST = '/api/v1/auth/logout/';
export const LOGIN_REQUEST = '/api/v1/auth/login/';
export const SIGN_UP_REQUEST = '/api/v1/auth/signup/';
export const GET_CODE_REQUEST = '/api/v1/auth/phone/request/';
export const RESET_PASSWORD_REQUEST = '/api/v1/auth/password/reset/';
export const CHANGE_PASSWORD_REQUEST = '/api/v1/auth/password/request/';
export const GET_USER_REQUEST = '/api/v1/user/';
export const GET_CURRENT_USER_REQUEST = '/api/v1/user/current/';
export const GET_MEAL = '/api/v1/meal/';
export const LOCALES_URL = '/api/v1/locale/';
export const PRODUCT_URL = '/api/v1/product/';
export const PREFERENCE_URL = '/api/v1/preference/';
export const PREFERENCE_URL_PART = '/preference/';
export const PHRASE_URL_PART = '/phrase/';
export const SCHEME_URL_PART = '/scheme/';
export const MEAL_URL_PART = '/meal/';

export const getFetchRequest = (url, successCallback, errorCallback, withoutJSON = false) => {
    const headers = {
        "Content-Type": "application/json",
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'GET',
        headers,
    }).then(response => {
        if (!withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchRequest = (url, body, successCallback, errorCallback, withoutJSON = false) => {
    const headers = {
        "Content-Type": "application/json",
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if (body && !withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const deleteFetchRequest = (url, successCallback, errorCallback) => {
    const headers = {
        "Content-Type": "application/json",
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'DELETE',
        headers,
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};
