import React, { useState, useEffect } from 'react';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { i18n } from '../../i18n';
import { PREFERENCE_TYPE_CEREAL_MIX } from '../../constant';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';

export const findPreferenceTitle = () => {
    if(preferenceSaveService.getPreference().preferenceItemCodes && 
        preferenceSaveService.getPreference().preferenceItemCodes.length &&
        preferenceSaveService.getPreference().preferenceType !== PREFERENCE_TYPE_CEREAL_MIX
    ) {
        return preferenceSaveService.getPossibleCodeValues(preferenceSaveService.getPreference().preferenceType)
            .find(it => it.id === preferenceSaveService.getPreference().preferenceItemCodes[0].itemCode).name;
    } else {
        return '';
    }
};

const PreferenceItemCreateWhatActiveList = () => {
    const [selectedItem, setSelectedItem] = useState('');  
    
    const fillPreferenceField = (e) => {
        if(e) {
            preferenceSaveService.updatePreferenceStructureCode(e.target.value);
            setSelectedItem(e.target.value);
        } else {
            setSelectedItem(findPreferenceTitle());
        }
    };

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(fillPreferenceField);
        setSelectedItem(findPreferenceTitle());
        return () => {
            preferenceSaveService.unRegisterItemUpdateObserver(fillPreferenceField);
        };
    }, []);
    return (
        preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX ?
            <div className="product__forms">
                {preferenceCerealMixSelectService.getCurrentItems() && preferenceCerealMixSelectService.getCurrentItems().length ? 
                    <ul className={`product__forms-list ${preferenceCerealMixSelectService.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                        {preferenceCerealMixSelectService.getCurrentItems().map((it, index) => (
                            <React.Fragment key={it.id + 'formHandler' + index}>
                                <SelectWithTokensActiveItem 
                                    selectItem={{itemId: it.id, itemTitle: it.name, include: true}} 
                                    removeCallback={preferenceCerealMixSelectService}
                                />
                            </React.Fragment>
                        ))}
                            
                    </ul>
                    :
                    null}
                {preferenceCerealMixSelectService.getCurrentItems().length === 0 ? 
                    <SelectWithTokensInput selectWithTokensManager={preferenceCerealMixSelectService} />
                    :
                    null}
            </div>
            :
            <input className="block__content select__choice-input preference__select-input" onChange={fillPreferenceField} 
                value={selectedItem || ''} placeholder={i18n.get("app.page.preference.preferenceAsTablePlaceholder")} />
    );
};

export default PreferenceItemCreateWhatActiveList;
