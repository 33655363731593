import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { MenuLogoIcon } from '../appearence/icons/small_logo_icon';
import { HomeIcon } from '../appearence/icons/home_icon';
import { PAGE_TAB_NAME_LIST, PLAN_TAB, PREFERENCE_TAB, PROFILE_TAB } from '../constant';
import { LogoutIcon } from '../appearence/icons/logout_icon';
import { LOGOUT_REQUEST, PHRASE_URL_PART, postFetchRequest } from '../store/requests';
import { MugIcon } from '../appearence/icons/mug_icon';
import { ProfileIcon } from '../appearence/icons/profile_icon';
import { i18n } from '../i18n';
import CommitInfo from './sharedComponents/CommitInfo';
import {errorCallback, makeUrl} from "../util";

const MainMenu = ({ setAccessToken, menuIsOpen, setMenuIsOpen }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [appLocale, setAppLocale] = useState(i18n.APP_LOCALE);

    const handleChangeTab = (tabName) => {
        navigate('/' + tabName);
    };

    const handleLogout = () => {
        postFetchRequest(makeUrl([LOGOUT_REQUEST]), null, () => {
            setAccessToken(false);
            navigate('/login');
        }, errorCallback, true);
    };

    const changeAppLocale = () => {
        setAppLocale(i18n.APP_LOCALE);
    };

    useEffect(() => {
        i18n.registerChangingObserver(changeAppLocale);
        return () => i18n.unRegisterChangingObserver(changeAppLocale);
    }, []);

    return (
        <>
            {menuIsOpen ? <div className="menu__wrapper" onClick={() => setMenuIsOpen(false)}></div> : null}
            <nav className="menu">
                <div className="menu__content">
                    <div className="menu__logo-group">
                        <MenuLogoIcon nameOfClass="menu__logo_small" />
                        <CommitInfo />
                    </div>
                    <ul className="menu__list" key={appLocale}>
                        <li className="menu__item navigation-link" onClick={() => handleChangeTab(PLAN_TAB)}>
                            <Link className="menu__item-link" to={'/' + PLAN_TAB}>
                                <HomeIcon nameOfClass={location.pathname.includes(PLAN_TAB) ? 'menu__icon menu__icon_active' : 'menu__icon'} />
                                <h2 className={`block__button menu__title ${location.pathname.includes(PLAN_TAB) ? 'menu__title_active' : ''}`}>{i18n.get("app.page.plan.title")}</h2>
                            </Link>
                        </li>
                        <li className="menu__item navigation-link" onClick={() => handleChangeTab(`${PREFERENCE_TAB}${PHRASE_URL_PART}${PAGE_TAB_NAME_LIST}`)}>
                            <Link className="menu__item-link" to={`/${PREFERENCE_TAB}${PHRASE_URL_PART}${PAGE_TAB_NAME_LIST}`}>
                                <MugIcon nameOfClass={location.pathname.includes(PREFERENCE_TAB) ? 'menu__icon menu__icon_active' : 'menu__icon'} />
                                <h2 className={`block__button menu__title ${location.pathname.includes(PREFERENCE_TAB) ? 'menu__title_active' : ''}`}>{i18n.get("app.page.preference.title")}</h2>
                            </Link>
                        </li>
                        <li className="menu__item navigation-link" onClick={() => handleChangeTab(PROFILE_TAB)}>
                            <Link className="menu__item-link" to={'/' + PROFILE_TAB}>
                                <ProfileIcon nameOfClass={location.pathname.includes(PROFILE_TAB) ? 'menu__icon menu__icon_active' : 'menu__icon'} />
                                <h2 className={`block__button menu__title ${location.pathname.includes(PROFILE_TAB) ? 'menu__title_active' : ''}`}>{i18n.get("app.page.profile.title")}</h2>
                            </Link>
                        </li>
                    </ul>
                    <ul className="menu__list menu__list_bottom">
                        <li className="menu__item menu__item_bottom navigation-link" onClick={handleLogout}>
                            <Link className="menu__item-link" to={'#'}>
                                <LogoutIcon nameOfClass='menu__icon' />
                                <h2 className='block__button menu__title'>{i18n.get("public.app.page.login.exitCabinet")}</h2>
                            </Link>
                        </li>
                    </ul>


                </div>
            </nav>
        </>
    );
};

export default MainMenu;
