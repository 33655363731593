import React, { useState, useEffect, useRef } from 'react';
import { i18n } from '../../i18n';
import { QuestionIcon } from '../../appearence/icons/question_icon';
import SelectHandler from '../sharedComponents/SelectHandler';
import { checkAllFieldsIsFilled, preferenceSaveService } from '../../services/preferenceSaveService';
import Loading from '../Loading';

const createListOfOptions = () => {
    return [
        {
            name: i18n.get('preference.scheme.include.true'),
            id: true,
        },
        {
            name: i18n.get('preference.scheme.include.false'),
            id: false,
        },
    ];
};

const findPreferenceTitle = (isLoading, fieldValue) => {
    if(fieldValue !== undefined && isLoading?.table === false) {
        return i18n.get(`preference.scheme.include.${fieldValue}`);
    } else {
        return '';
    }
};

const PreferenceItemCreateIncludeField = ({ isLoading, setIsLoading }) => {
    const [fieldValue, setFieldValue] = useState(preferenceSaveService.getPreference().include);
    const ref = useRef();
    const fillPreferenceField = (value) => {
        preferenceSaveService.fillPreferenceItemSelectField('include', value);
        if(checkAllFieldsIsFilled(preferenceSaveService.getPreference())) {
            preferenceSaveService.savePreference(() => {
                setIsLoading({table: false, phrase: true});
                preferenceSaveService.generatePhraseByTable(); 
            });
        }
    };

    const changeItemValue = () => {
        setFieldValue(preferenceSaveService.getPreference().include);
    }; 

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(changeItemValue);
        changeItemValue();
        return () => preferenceSaveService.unRegisterItemUpdateObserver(changeItemValue);
    }, []);

    return (
        <div className={`block__text preference__create-block ${findPreferenceTitle(isLoading, fieldValue) ? 'preference__create-block_active' : ''}`}>
            <QuestionIcon nameOfClass={`preference__create-icon ${findPreferenceTitle(isLoading, fieldValue) ? 'preference__create-icon_active' : ''}`}/>
            {isLoading.table ?
                <Loading />
                :
                <label className="preference__create-label" key={fieldValue + 'internal'} ref={ref}>
                    <span className="block__content preference__label-title">{i18n.get('app.page.preference.addTitles.include')}</span>
                    <SelectHandler 
                        selectedValue={findPreferenceTitle(isLoading, fieldValue)} 
                        optionsList={createListOfOptions()} 
                        sendSelectedValue={fillPreferenceField}
                        parentElement={ref} />
                </label>}
        </div>
    );
};

export default PreferenceItemCreateIncludeField;
