import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_TAB_NAME_NEW, PREFERENCE_TAB } from '../../constant';
import { i18n } from '../../i18n';

const CreateNewPreferenceButton = () => {
    const navigate = useNavigate();

    const navigateToCreatePreferencePage = () => {
        navigate(`/${PREFERENCE_TAB}/item/${PAGE_TAB_NAME_NEW}`);
    };

    return (
        <button className="button button_outline preference__block-button" onClick={navigateToCreatePreferencePage}>
            <span className="button__content">{i18n.get('app.page.preference.createPreference')}</span>
        </button>
    );
};

export default CreateNewPreferenceButton;
