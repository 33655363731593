import { useState, useMemo } from 'react';

import {
    getMonthesNames,
    createMonth,
    getWeekDaysNames,
    getMonthNumberOfDays,
    createDate,
} from './CalendarItem';

const DAYS_IN_WEEK = 7;
const YEAR_INTERVAL_COUNT = 10;
const FIRST_DAY_IN_WEE_NUMBER = 2;
const DAY_NUMBER_IN_WEEK_COUNT = 6;
const ONE_YEAR_IN_MONTHES_COUNT = 11;

const getYearsInterval = (year) => {
    const startYear = Math.floor(year / YEAR_INTERVAL_COUNT) * YEAR_INTERVAL_COUNT;
    return [...Array(YEAR_INTERVAL_COUNT)].map((_, index) => startYear + index);
};

export const useCalendar = ({
    selectedDate,
    firstWeekDayNumber = FIRST_DAY_IN_WEE_NUMBER,
}) => {
    const [mode, setMode] = useState('days');
    const [selectedDay, setSelectedDay] = useState(createDate({ date: selectedDate[0] }));
    const [endDay, setEndDay] = useState();
    const [selectedMonth, setSelectedMonth] = useState(
        createMonth({ date: new Date(selectedDay.year, selectedDay.monthIndex) }),
    );
    const [selectedYear, setSelectedYear] = useState(selectedDay.year);
    const [selectedYearsInterval, setSelectedYearsInterval] = useState(
        getYearsInterval(selectedDay.year),
    );

    const monthesNames = useMemo(() => getMonthesNames(), []);
    const weekDaysNames = useMemo(() => getWeekDaysNames(firstWeekDayNumber), []);

    const days = useMemo(() => selectedMonth.createMonthDays(), [selectedMonth, selectedYear]);

    const calendarDays = useMemo(() => {
        const monthNumberOfDays = getMonthNumberOfDays(selectedMonth.monthIndex, selectedYear);

        const prevMonthDays = createMonth({
            date: new Date(selectedYear, selectedMonth.monthIndex - 1),
        }).createMonthDays();

        const nextMonthDays = createMonth({
            date: new Date(selectedYear, selectedMonth.monthIndex + 1),
        }).createMonthDays();

        const firstDay = days[0];
        const lastDay = days[monthNumberOfDays - 1];

        const shiftIndex = firstWeekDayNumber - 1;
        const numberOfPrevDays =
            firstDay.dayNumberInWeek - 1 - shiftIndex < 0
                ? DAYS_IN_WEEK - (firstWeekDayNumber - firstDay.dayNumberInWeek)
                : firstDay.dayNumberInWeek - 1 - shiftIndex;

        const numberOfNextDays =
            DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex > DAY_NUMBER_IN_WEEK_COUNT
                ? DAYS_IN_WEEK - lastDay.dayNumberInWeek - (DAYS_IN_WEEK - shiftIndex)
                : DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex;

        const totalCalendarDays = days.length + numberOfPrevDays + numberOfNextDays;

        const result = [];

        for (let i = 0; i < numberOfPrevDays; i += 1) {
            const inverted = numberOfPrevDays - i;
            result[i] = prevMonthDays[prevMonthDays.length - inverted];
        }

        for (let i = numberOfPrevDays; i < totalCalendarDays - numberOfNextDays; i += 1) {
            result[i] = days[i - numberOfPrevDays];
        }

        for (let i = totalCalendarDays - numberOfNextDays; i < totalCalendarDays; i += 1) {
            result[i] = nextMonthDays[i - totalCalendarDays + numberOfNextDays];
        }

        return result;
    }, [selectedMonth.year, selectedMonth.monthIndex, selectedYear]);

    const onClickArrow = (direction) => {
        const monthIndex =
            direction === 'left' ? selectedMonth.monthIndex - 1 : selectedMonth.monthIndex + 1;
        if (monthIndex === -1) {
            const year = selectedYear - 1;
            setSelectedYear(year);
            if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
            return setSelectedMonth(createMonth({ date: new Date(selectedYear - 1, ONE_YEAR_IN_MONTHES_COUNT) }));
        }

        if (monthIndex === 12) {
            const year = selectedYear + 1;
            setSelectedYear(year);
            if (!selectedYearsInterval.includes(year)) setSelectedYearsInterval(getYearsInterval(year));
            return setSelectedMonth(createMonth({ date: new Date(year, 0) }));
        }

        setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex) }));
    };

    const setSelectedMonthByIndex = (monthIndex) => {
        setSelectedMonth(createMonth({ date: new Date(selectedYear, monthIndex) }));
    };

    return {
        state: {
            mode,
            calendarDays,
            weekDaysNames,
            monthesNames,
            selectedDay,
            selectedMonth,
            selectedYear,
            selectedYearsInterval,
            endDay,
        },
        functions: {
            onClickArrow,
            setMode,
            setSelectedDay,
            setSelectedMonthByIndex,
            setSelectedYear,
            setSelectedYearsInterval,
            setEndDay,
        },
    };
};
