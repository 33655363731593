const TOMORROW_DATE = new Date();
TOMORROW_DATE.setHours(24, 0, 0, 0);
const TOMORROW_END_DATE = new Date();
TOMORROW_END_DATE.setHours(47, 59, 0, 0);
export const MODAL_SMALL_SIZE = 'small';
export const MODAL_SUPER_SMALL_SIZE = 'xs';

export const MEAL_TIME_SHEDULE = [
    {
        id: 'today',
    },
    {
        id: 'currentDay',
    },
    {
        id: 'week',
    },
];

export const getDate = (date) => new Date(date).toISOString().split('T')[0];

export const getMealName = (startDate) => {
    return getDate(Date.now()) === getDate(startDate) ? MEAL_TIME_SHEDULE[0] : MEAL_TIME_SHEDULE[1];
};

export const sortByDate = (a, b) => {
    return new Date(b.dateCreated) - new Date(a.dateCreated);
};
