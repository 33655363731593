import React, { useState, useEffect } from 'react';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { i18n } from '../../i18n';

export const findPreferenceHowOftenTitle = () => {
    if(preferenceSaveService.getPreference().howOften) {
        return preferenceSaveService.getPossibleHowOftenValues(true).find(it => it.id === preferenceSaveService.getPreference().howOften).name;
    } else {
        return '';
    }
};

const PreferenceItemCreateHowOftenInput = () => {
    const [selectedItem, setSelectedItem] = useState('');    
    
    const changeSearchValue = (e) => {
        if(e) {
            preferenceSaveService.setHowOftenSearchValue(e.target.value);
            setSelectedItem(e.target.value);
        } else {
            setSelectedItem(findPreferenceHowOftenTitle());
        }
    };

    useEffect(() => {
        preferenceSaveService.registerItemUpdateObserver(changeSearchValue);
        setSelectedItem(findPreferenceHowOftenTitle());
        return () => {
            preferenceSaveService.unRegisterItemUpdateObserver(changeSearchValue);
        };
    }, []);

    return (
        <input className="block__content select__choice-input preference__select-input" onChange={changeSearchValue} 
            value={selectedItem || ''} placeholder={i18n.get("app.page.preference.preferenceAsTablePlaceholder")} />
    );
};

export default PreferenceItemCreateHowOftenInput;
