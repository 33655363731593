import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import { initialPreferencesService } from '../../services/initialPreferencesService';

const InitialPreferencesButton = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();
    const saveInitialPreferences = () => {
        if(!isDisabled) {
            initialPreferencesService.saveInitialPreferencesList(() => navigate('/plan'));
        }
    };

    const changeIsDisabled = () => {
        setIsDisabled(initialPreferencesService.getActivePreferencesList().length < 1);
    };

    useEffect(() => {
        initialPreferencesService.registerChangeInitialPreferencesObserver(changeIsDisabled);
        return () => initialPreferencesService.unRegisterChangeInitialPreferencesObserver(changeIsDisabled);
    }, []);

    return (
        <button 
            className={`button preference__initial-button ${isDisabled ? 'button_disabled' : ''}`} 
            disabled={isDisabled} 
            onClick={saveInitialPreferences}
        >
            <span className="block__content button__content">{i18n.get('app.page.onboarding.continue')}</span>
        </button>
    );
};

export default InitialPreferencesButton;
