import React from 'react';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_DELETE_USER_PREFERENCE } from '../../constant';
import { TrashIcon } from '../../appearence/icons/trash_icon';

const PreferencesListItemDeleteButton = ({ preferenceId }) => {
    return (
        <span onClick={() => popupOpenService.callToOpen(POPUP_KEY_DELETE_USER_PREFERENCE, preferenceId)}>
            <TrashIcon nameOfClass="preference__list-icon" />
        </span>
    );
};

export default PreferencesListItemDeleteButton;
