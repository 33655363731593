import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { MODAL_SIZE_LARGE, POPUP_KEY_PREFERENCES_FILTER } from '../../constant';
import Modal from '../sharedComponents/Modal';
import { CloseIcon } from '../../appearence/icons/close_icon';
import { preferencesStorage } from '../../services/preferencesStorage';
import FiltersActiveList from '../filterComponents/FiltersActiveList';
import PossibleFiltersList from '../filterComponents/PossibleFiltersList';
import { i18n } from '../../i18n';

const FiltersModalContent = ({ closeModal }) => {
    return (
        <>
            <span onClick={closeModal}>
                <CloseIcon nameOfClass="preference__filter-icon" handleClick={() => {}}/>
            </span>
            <h2 className="modal__title block__title-h3 preference__filter-title">{i18n.get('app.page.preference.filters')}</h2>
            <FiltersActiveList itemStorage={preferencesStorage} />
            <PossibleFiltersList itemStorage={preferencesStorage} />
        </>
    );
};

const PreferencesListFilterPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleOpenModal = () => {
        setIsOpen(true);
    };    
    
    useEffect(() => {
        preferencesStorage.getInitialFilters(searchParams);
        popupOpenService.registerPopup(POPUP_KEY_PREFERENCES_FILTER, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_PREFERENCES_FILTER, handleOpenModal);
    },[]);

    const closeModal = () => {    
        for (const [key] of Array.from(searchParams)) {
            searchParams.delete(key);
        }
        const filtersList = preferencesStorage.getCurrentFilters();
        filtersList.forEach(filter => {
            if(filter.value === true || filter.value === 'true') {
                searchParams.set(filter.code, filter.value);
            }
        });
        setSearchParams(searchParams);
        preferencesStorage.notifyFiltersChangingObservers();
        setIsOpen(null);
    };

    return (
        isOpen ?
            <Modal content={<FiltersModalContent closeModal={closeModal} />} 
                handleCloseModal={closeModal} size={MODAL_SIZE_LARGE}/>
            :
            null
    );
};

export default PreferencesListFilterPopup;
