import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import { checkAllFieldsIsFilled, preferenceSaveService } from '../../services/preferenceSaveService';
import { i18n } from '../../i18n';
import PreferenceItemCreatePhrase from './PreferenceItemCreatePhrase';
import PreferenceItemCreateTypeField from './PreferenceItemCreateTypeField';
import PreferenceItemCreateWhatField from './PreferenceItemCreateWhatField';
import PreferenceItemCreateWhenField from './PreferenceItemCreateWhenField';
import PreferenceItemCreateHowOftenField from './PreferenceItemCreateHowOftenField';
import PreferenceItemCreateIncludeField from './PreferenceItemCreateIncludeField';
import PreferenceDueDateHandler from './PreferenceDueDateHandler';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';
import { registerObserver, unRegisterObserver } from '../sharedComponents/WebSocketConnectionService';
import { 
    NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED, 
    NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL, 
    NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATED, 
    NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL,
} from '../../constant';

const PreferenceCreateHandler = () => {
    const [preferenceIsReady, setPreferenceIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState({table: false, phrase: false});
    const navigate = useNavigate();

    const changePreferenceReadyState = () => {
        setPreferenceIsReady(prev => !prev);
        if(checkAllFieldsIsFilled(preferenceSaveService.getPreference()) && !preferenceSaveService.getPreference().phrase) {
            setIsLoading({table: false, phrase: true});
            preferenceSaveService.generatePhraseByTable();            
        }
    };

    const getWebSocketMessage = (message) => {
        try {
            const data = JSON.parse(message);
            if(data.body.eventType === NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED || data.body.eventType === NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATED) {
                preferenceSaveService.loadPreference();
                setIsLoading({table: false, phrase: false});
            } else if(data.body.eventType === NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL || data.body.eventType === NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL) {
                setIsLoading({table: false, phrase: false});
            }
        } catch(err) {
            console.log(err);
        }
    };

    useEffect(() => {
        preferenceSaveService.loadPreference();
        preferenceSaveService.registerItemUpdateObserver(changePreferenceReadyState);
        preferenceCerealMixSelectService.loadItems();
        registerObserver(getWebSocketMessage);
        return () => {
            preferenceSaveService.unRegisterItemUpdateObserver(changePreferenceReadyState);
            preferenceSaveService.clearService();
            preferenceCerealMixSelectService.clearService();
            unRegisterObserver(getWebSocketMessage);
        };
    }, []);

    const createNewPreference = () => {
        preferenceSaveService.savePreference();
        navigate(-1);
    };

    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="preference__article preference__article-add">
                <h1 className="block__subtitle preference__create-title">{i18n.get('app.page.preference.newPreference')}</h1>
                <form className="preference__form" onClick={(e) => e.preventDefault()}>
                    <PreferenceItemCreatePhrase isLoading={isLoading} setIsLoading={setIsLoading} />
                    <label className="preference__label" onClick={(e) => e.preventDefault()}>
                        <span className="preference__label-content">{i18n.get('app.page.preference.preferenceAsTable')}</span>
                        <div className="preference__form-block">
                            <PreferenceItemCreateIncludeField isLoading={isLoading} setIsLoading={setIsLoading} />
                            <PreferenceItemCreateWhenField isLoading={isLoading} setIsLoading={setIsLoading} />
                            <PreferenceItemCreateTypeField isLoading={isLoading} setIsLoading={setIsLoading} />
                            <PreferenceItemCreateHowOftenField isLoading={isLoading} setIsLoading={setIsLoading} />
                            <PreferenceItemCreateWhatField isLoading={isLoading} setIsLoading={setIsLoading} />
                        </div>
                        <span className="preference__label-info">{i18n.get('app.page.preference.preferenceAsTableInfo')}</span>
                    </label>
                    <PreferenceDueDateHandler />
                </form>
                <div className='preference__button-group'>
                    <button className='button button_outline preference__button' onClick={() => navigate(-1)}>
                        <span className='button__content'>{i18n.get('app.page.preference.modal.cancel')}</span>
                    </button>
                    <button className={`button preference__button ${checkAllFieldsIsFilled(preferenceSaveService.getPreference()) ? '' : 'button_disabled'}`} 
                        onClick={createNewPreference} key={preferenceIsReady}>
                        <span className='button__content'>{i18n.get('app.page.preference.modal.create')}</span>
                    </button>
                </div>
            </article>
        </>
    );
};

export default PreferenceCreateHandler;
