import React from 'react';
import { checkAllFieldsIsFilled, preferenceSaveService } from '../../services/preferenceSaveService';

const PreferenceItemCreateHowOftenListItem = ({ itemCode, setIsLoading }) => {
    const addValueInSelectedList = () => {
        preferenceSaveService.fillPreferenceHowOftenField(itemCode);
        if(checkAllFieldsIsFilled(preferenceSaveService.getPreference())) {
            preferenceSaveService.savePreference(() => {
                setIsLoading({table: false, phrase: true});
                preferenceSaveService.generatePhraseByTable(); 
            });
        }
    };

    return (
        <li key={itemCode} className="block__content select__choice-option" onClick={addValueInSelectedList}>
            {preferenceSaveService.getPossibleHowOftenValues().find(it => it.id === itemCode).name}
        </li>
    );
};

export default PreferenceItemCreateHowOftenListItem;
