import React from 'react';
import { i18n } from '../../i18n';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import PreferenceItemCreateWhatOptionListItem from './PreferenceItemCreateWhatOptionListItem';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';

const CreateCerealMixBlock = ({ closeCallback }) => {
    const addNewCerealMix = () => {
        preferenceCerealMixSelectService.addNewCerealMix();
        closeCallback(prev => !prev);
    };

    return (
        <>  
            <ul className='scroll select__choice-content'>
                {preferenceSaveService.getPossibleCodeValues().map((it, index) => (
                    <React.Fragment key={it.name + index}>
                        <PreferenceItemCreateWhatOptionListItem itemCode={it.code} isProduct={true} itemName={it.name}/>
                    </React.Fragment>
                ))}
            
            </ul>
            <div className="block__text preference__mix" onClick={() => closeCallback(false)}>
                <span className="preference__mix-button" onClick={() => closeCallback(false)}>{i18n.get("app.page.product.close")}</span>
                <span className="preference__mix-button" onClick={addNewCerealMix}>{i18n.get("app.page.product.add")}</span>
            </div>
        </>
    );
};

export default CreateCerealMixBlock;
