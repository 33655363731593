import React from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import { i18n } from '../../i18n';
import { DishIcon } from '../../appearence/icons/dish_icon';
import { preferenceSaveService } from '../../services/preferenceSaveService';

const findPreferenceTitle = (preferenceId) => {
    if(preferencesStorage.getPreferenceById(preferenceId).preferenceItemCodes && preferencesStorage.getPreferenceById(preferenceId).preferenceItemCodes.length ) {
        let result = [];
        for(let item of preferencesStorage.getPreferenceById(preferenceId).preferenceItemCodes) {
            result.push(preferenceSaveService.getPossibleCodeValues(preferencesStorage.getPreferenceById(preferenceId).preferenceType)
                .find(it => it.code === item.itemCode || it.id === item.itemCode)?.name);
        }
        return result.join(', ');
    } else {
        return '';
    }
};

const PreferencesListItemWhat = ({ preferenceId }) => {
    return (
        <div className="block__text preference__field">
            <DishIcon nameOfClass="preference__field-icon" />
            <label className="preference__field-label">
                <span className="block__content preference__field-title">{i18n.get('app.page.preference.addTitles.preferenceItemCode')}</span>
                <span className='preference__field-content'>{findPreferenceTitle(preferenceId)}</span>
            </label>
        </div>
    );
};

export default PreferencesListItemWhat;
