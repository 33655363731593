import React from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';


const PreferencesListItemPhrase = ({ preferenceId }) => {
    return (
        <p className="block__content preference__field-title preference__field-phrase">{preferencesStorage.getPreferenceById(preferenceId).phrase}</p>
    );
};

export default PreferencesListItemPhrase;
